import axios from "../services/axios";
import { loginConstants, myDataConstants, signupConstants, forgotPasswordConstants, resetPasswordConstants, emailVerificationConstants, resendEmailVerificationConstants,
   changePasswordConstants, clearAuthMessage} from "../constants/auth.constants";


export const loginUser = (formData, redirect) => {
  return (dispatch) => {
    axios.post("/login", formData).then((response) => {
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
          if(response?.data?.status === true){
            localStorage.setItem("token", response.data.data.access_token);
            localStorage.setItem("userData", JSON.stringify(response.data.data.user));
            dispatch({ type: loginConstants.GET_LOGIN_SUCCESS, payload: { data: response.data },});
          }
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        dispatch({type: loginConstants.GET_LOGIN_FAILURE,payload: err.message,});
        console.log(err);
      });
  };
};

export const myDataAction = () => {
  return (dispatch) => {
    axios.post("/me").then((response) => {
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
          if(response?.data?.status === true){
            dispatch({ type: myDataConstants.GET_MYDATA_SUCCESS, payload: { my : response.data },});
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const registerUser = (formData, redirect) => {
  return (dispatch) => {
    axios
      .post("/register", formData)
      .then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: signupConstants.GET_SIGNUP_SUCCESS, payload: { data: response.data },});
          // redirect();
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const forgotPassword = (formData, redirect) => {
  return (dispatch) => {
    axios
      .post("/forgot-password", formData)
      .then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: forgotPasswordConstants.GET_FORGOTPASSWORD_SUCCESS, payload: { data: response.data },});
          // redirect('/login');
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const resetPassword = (formData, redirect) => {
  return (dispatch) => {
    axios
      .post("/reset-password", formData)
      .then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: resetPasswordConstants.GET_RESETPASSWORD_SUCCESS, payload: { data: response.data },});
          // redirect('/login');
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const changePassword = (id, formData, redirect) => {
  return (dispatch) => {
    let url = "/change-password";
    // Append the id to the URL if it exists
    if (id && id !== undefined)
      url += `/${id}`; 

  axios
      .post(url, formData)
      .then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: changePasswordConstants.GET_CHANGEPASSWORD_SUCCESS, payload: { data: response.data },});
          // redirect('/login');
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const emailVerification = (formData, redirect) => {
  return (dispatch) => {
    axios
      .post("/email-verification", formData)
      .then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: emailVerificationConstants.GET_EMAILVERIFICATION_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const resendEmailVerification = (id) => {
  return (dispatch) => {
    axios
      .post(`/resend-email-verification/${id}`)
      .then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: resendEmailVerificationConstants.GET_RESENDEMAILVERIFICATION_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const clearAuthMsg = () => async (dispatch) => {
  try {
      dispatch({ type: clearAuthMessage.CLEAR_AUTH_MESSAGE, payload: [] })
  } catch (err) {
      console.log(`Error while calling login api ${err}`);
  }
}

export const userLogout = () => {
  return () => {
    axios.post("/logout", {
        headers: {
          "x-auth": localStorage.getItem("token"),
        },
      })
      .then((response) => {
        if (response?.data.status) {
          localStorage.removeItem("token");
          localStorage.removeItem("userData");
          window.location.href = "/login";
        }
      });
  };
};
