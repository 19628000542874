import axios from "../services/axios";
import { userSequenceListConstants, sequenceStatusConstants, viewUserSequenceConstants, createSequenceConstants, updateSequenceConstants, clearSequenceMessage, deleteSequenceConstants, deleteImageConstants, imageStatusConstants  } from "../constants/sequence.constants";

// Sequence Actions form here
export const getUserSequenceList = (page) => {
    return (dispatch) => {
      axios.get(`/sequence/list?page=${page}`).then((response) => {
          if (response.data.hasOwnProperty("errors")) {
            alert(response.data.message);
          } else {
            dispatch({ type: userSequenceListConstants.GET_USERSEQUENCELIST_SUCCESS, payload: { data: response.data },});
            // redirect();
          }
        })
        .catch((err) => {
          dispatch({ type: 'HIDE_LOADER' });
          console.log(err);
        });
    };
};
  
export const sequenceStatus = (formData) => {
    return (dispatch) => {
      axios.post(`/sequence/status`,formData).then((response) => {
          if (response.data.hasOwnProperty("errors")) {
            alert(response.data.message);
          } else {
            dispatch({ type: sequenceStatusConstants.GET_SEQUENCESTATUS_SUCCESS, payload: { response: response.data },});
            // redirect();
          }
        })
        .catch((err) => {
          dispatch({ type: 'HIDE_LOADER' });
          console.log(err);
        });
    };
};

export const imageStatus = (formData) => {
  return (dispatch) => {
    axios.post(`/sequence/image/status`,formData).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: imageStatusConstants.GET_IMAGESTATUS_SUCCESS, payload: { response: response.data },});
          // redirect();
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const viewUserSequence = (id) => {
    return (dispatch) => {
      axios.get(`/sequence/list/${id}`).then((response) => {
          if (response.data.hasOwnProperty("errors")) {
            alert(response.data.message);
          } else {
            dispatch({ type: viewUserSequenceConstants.GET_VIEWUSERSEQUENCE_SUCCESS, payload: { sequenceData : response.data },});
            // redirect();
          }
        })
        .catch((err) => {
          dispatch({ type: 'HIDE_LOADER' });
          console.log(err);
        });
    };
};
  
export const createSequence = (formData) => {
    return (dispatch) => {
      axios.post("/sequence/store", formData).then((response) => {
          if (response.data.hasOwnProperty("errors")) {
            alert(response.data.message);
          } else {
            dispatch({ type: createSequenceConstants.GET_CREATESEQUENCE_SUCCESS, payload: { data: response.data },});
            // redirect();
          }
        })
        .catch((err) => {
          dispatch({ type: 'HIDE_LOADER' });
          console.log(err);
        });
    };
};

export const updateSequence = (formData) => {
  return (dispatch) => {
    axios.put("/sequence/update", formData).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: updateSequenceConstants.GET_UPDATESEQUENCE_SUCCESS, payload: { data: response.data },});
          // redirect();
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const deleteSequence = (formData) => {
  return (dispatch) => {
    // Assuming formData is an object with key-value pairs
    const queryString = Object.keys(formData).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(formData[key])}`).join('&');
    axios.delete(`/sequence/destroy?${queryString}`).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: deleteSequenceConstants.GET_DELETESEQUENCE_SUCCESS, payload: { deleteResponse: response.data },});
          // redirect();
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const deleteImage = (formData) => {
  return (dispatch) => {
    // Assuming formData is an object with key-value pairs
    const queryString = Object.keys(formData).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(formData[key])}`).join('&');
    axios.delete(`/sequence/image/destroy?${queryString}`).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: deleteImageConstants.GET_DELETEIMAGE_SUCCESS, payload: { deleteResponse: response.data },});
          // redirect();
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const clearSequenceMsg = () => async (dispatch) => {
    try {
        dispatch({ type: clearSequenceMessage.CLEAR_SEQUENCE_MESSAGE, payload: [] })
    } catch (err) {
        console.log(`Error while calling login api ${err}`);
    }
}