import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { downloadImageAction, paymentRetriev } from "../../../actions/homeAction";
import defaultImg from '../../../assets/images/defalut.jpg'
import { toast } from "react-toastify";
import { FaCheck, FaDownload } from "react-icons/fa";
import { PiImageLight } from "react-icons/pi";


const PaymentRetriev = () =>{
    
    const navigate = useNavigate();
    const location = useLocation();
    const search = location.search;
    const params = new URLSearchParams(search);
    const session_id = params.get('session_id');
    const dispatch = useDispatch();
    const [imageList, setImageList] = useState([]);
    
    // useEffect for call Api's 
    useEffect(() => {
        if(session_id){
            dispatch({ type: 'SHOW_LOADER' }); // Set loader to true
            dispatch(paymentRetriev({ session_id : session_id }));
        }
        const handleBeforeUnload = (event) => {
            event.preventDefault();
        };
      
        const handleKeyDown = (event) => {
            event.preventDefault();
        };
        // Attach event listeners when the component mounts
        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('keydown', handleKeyDown);
      
        // Remove event listeners when the component unmounts
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('keydown', handleKeyDown);
        };
    },[dispatch, session_id]) 

    // Selects data related to the loader action from the Redux store state.
    const isLoading = useSelector(state => state.loader.isLoading);
    // Selects data related to the paymentRetriev action from the Redux store state.
    const { data } = useSelector((state) => state.paymentRetriev.paymentRetriev)
    useEffect(() => {
        if (data && data?.status) {
            dispatch({ type: 'HIDE_LOADER' });
            toast.success(data?.message[0],{theme: "dark",});
            setImageList(data?.list);
            // navigate('/home');
        }
    }, [data, dispatch, navigate]);

    const [downloadingImages, setDownloadingImages] = useState("");

    const downloadImagesSequentially = async () => {
        for (let i = 0; i < imageList.length; i++) {
            const data = imageList[i];
            if (data.status === 0) {
                setImageList(prevList =>
                    prevList.map(image =>
                        image.id === data.id ? { ...image, status: 5 } : image
                    )
                );
                await handleImageDownload(data.id, data.checkout_id);
            }
        }
    };


    const handleImageDownload = async (id, checkout_id) => {
        if (imageList.find(image => image.id === id)?.status === 0) {
            setImageList(prevList =>
                prevList.map(image =>
                    image.id === id ? { ...image, status: 5 } : image
                )
            );
            setDownloadingImages((prev) => [...prev, id]);
            await delay(2000);
            if (!id || !checkout_id) {
                toast.error('Something went wrong', { theme: "dark", });
                return false;
            } else {
                try {
                    dispatch(downloadImageAction({
                        session_id: checkout_id,
                        download_id: id
                    }));
                } catch (error) {
                    dispatch({ type: 'HIDE_LOADER' });
                    toast.error('Something went wrong', { theme: "dark", });
                    console.error("Error in payment:", error);
                }
            }
        }
    };

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const { downloadImageData, download_id } = useSelector((state) => state.downloadImage.downloadImage)
    useEffect(() => {
        const downloadImages = async () => {
            if (downloadImageData && downloadImageData?.status) {
                const response = await fetch(downloadImageData.data.link);
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = downloadImageData.data.name;
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
                setDownloadingImages((prev) => prev.filter((imageId) => imageId !== download_id));
                setImageList(prevList =>
                    prevList.map(image =>
                        image.id === download_id ? { ...image, status: 1 } : image
                    )
                );
            }
        };
        
        downloadImages();
    }, [downloadImageData, dispatch, download_id]);

    return(
        <>  
            {/* Conditional rendering for the loader */}
            {isLoading && <div className="loader-overlay">
                <div className="loader" style={{textAlign: "center" ,marginTop : "10px"}}>
                    <Spinner animation="border" role="status"></Spinner>
                    <p>Please Wait your images are loading...</p>
                </div>
            </div>}
            <main className="set_gallery_bg">
                <section className="search_section">
                    <div className="container">
                        <div className="find_data form_gallery_data">
                            {imageList && imageList?.length > 0 &&
                            <> 
                                <div className="oneditor_outer justify-content-between d-flex">
                                    <div className="onEditor d-flex mb-4">
                                        <h3 className="gallery_innerTitle" style={{ fontSize: "18px", color: "red"}}>
                                            Please don't go back or change the page until the image is downloaded.
                                        </h3>
                                    </div>
                                    <Link className="m-lg-4">
                                        <button type="button" onClick={() => downloadImagesSequentially()} className="btn btn-purple btn-sm text-white"><FaDownload/>&nbsp; Download All</button>
                                    </Link>
                                </div>
                            </>
                            }
                            <div className="image_wrapField ">
                                {/* <h6 className="image_galleryTiitle mb_40" style={{ fontFamily: "fantasy" }}> {galleryDetails ? ( <>{galleryDetails?.area}, {galleryDetails?.spot}, {galleryDetails?.region} Picture Colloction </>) : ''}</h6> */}
                                <div className="item_log_pic">
                                    <ul className="item_searchvalue">
                                    {(imageList?.length > 0) ? (
                                        imageList?.map((data, index) => (
                                            <li key={index} className="item_flexInner">
                                                <div className="desk_card_outer">
                                                    <img src={(data?.watermark) ? data?.watermark : defaultImg} alt="" className="card_images_admin img-fluid"/>
                                                    <div className="img_desk_card">
                                                        {/* <p>New York picture collection</p> */}
                                                        <div className="public_items d-flex">
                                                            <div className="public_trash d-flex float-left">
                                                                {downloadingImages === data.id || data.status === 5 ? (
                                                                    <div className="btn_edit">
                                                                        <Spinner animation="border" role="status"></Spinner>
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        {data.status === 0 ? (
                                                                            <Link title="Download" onClick={() => handleImageDownload(data.id,data.checkout_id)} className="btn_edit fab_color">
                                                                                <FaDownload size={20} />
                                                                            </Link>
                                                                        ) : (
                                                                            <Link className="btn_edit">
                                                                                <FaCheck size={20} />
                                                                            </Link>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))
                                    ): (
                                        (!isLoading &&
                                            <div className="d-flex no_record">
                                                <PiImageLight size={100}/>
                                                <span align={'center'} style={{fontWeight:'700',padding:'1em'}}> 
                                                    No Images Found To Download. 
                                                </span>
                                            </div>
                                        )
                                    )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}
export default PaymentRetriev;