import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { clearAuthMsg, emailVerification } from "../../../actions/authAction";

const EmailVerification = () => {

    const { token, username } = useParams()
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(emailVerification( {
            token    : token,
            username : username
        }));
    }, [token,username,dispatch]);
  
    // Selects data related to the verification action from the Redux store state.
    const { data } = useSelector((state) => state.verification.verification)
    useEffect(() => {
        if (data && data.status) {
            toast.success(data?.message[0],{theme: "dark",});
            dispatch(clearAuthMsg());
            navigate("/login");
        }
    }, [data, navigate, dispatch]);

  return <div></div>;
};

export default EmailVerification;
