import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearAuthMsg, registerUser } from "../../../actions/authAction";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import backgroundlogin from '../../../assets/images/sign_up.jpg';
import Container from 'react-bootstrap/Container';
import { Form, Button, FormGroup, FormCheck } from 'react-bootstrap';
import logo from '../../../assets/images/FMSPLogo2trans.png';
import { FaEye, FaEyeSlash, FaRegUser} from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import { MdLockOutline } from "react-icons/md";
import { TbArrowBack } from "react-icons/tb";
import { Spinner } from "react-bootstrap";

const SignUp = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const [error, setError] = useState(false);
    const [check, setCheck] = useState(false);
    const [showPassword, setShowPassword] = useState("");
    const [showConfirmPassword, setShowConfirmPassword] = useState("");
    const [userDetails, setUserDetails] = useState({
        username: "", email: "", password: "", confirm_password: ""
    });
    
    useEffect(() => {
        dispatch({ type: 'CLEAR_SEARCHSEQUENCE_MESSAGE' });
    },[dispatch])

    useEffect(() => {
        document.querySelectorAll('[data-background]').forEach(element => {
            const backgroundImage = element.getAttribute('data-background');
            if (backgroundImage) {
                element.style.backgroundImage = `url(${backgroundImage})`;
            }
        });
        document.title = 'Sign Up';
    }, []);

    // Selects data related to the loader action from the Redux store state.
    const isLoading = useSelector(state => state.loader.isLoading);
    
    const handleSignupFormDetails = (e) => {
        setUserDetails({
            ...userDetails,
            [e.target.name]:e.target.value 
        })
    }

    // hide or show password
    const togglePasswordVisibility = (type) => {
        if(type === 'pass')
            setShowPassword(!showPassword);
        else if(type === 'conPass')
            setShowConfirmPassword(!showConfirmPassword);
    };

    const signupFormSubmit = async (e) => {
        e.preventDefault();
        if(!userDetails.username || !userDetails.email || !userDetails.password || !userDetails.confirm_password || !check){
            dispatch({ type: 'HIDE_LOADER' });
            setError(true);
            return false;
        }else{
            try {
                dispatch({ type: 'SHOW_LOADER' });
                await dispatch(registerUser({
                    username    : userDetails?.username,
                    email       : userDetails?.email,
                    password    : userDetails?.password,
                    password_confirmation : userDetails?.confirm_password,
                    is_agree    : check
                }))
            } catch (error) {
                dispatch({ type: 'HIDE_LOADER' });
                console.error("Error while signup:", error);
            }
        }
    }

    // Selects data related to the signup action from the Redux store state.
    const { data } = useSelector((state) => state.signup.signup)
    useEffect(() => {
        if (data && data.status) {
            dispatch({ type: 'HIDE_LOADER' });
            toast.success(data?.message[0],{theme: "dark",});
            navigate("/login");
            dispatch(clearAuthMsg());
        }else{
            dispatch({ type: 'HIDE_LOADER' });
            toast.error(data?.message[0],{theme: "dark",});
        }
    }, [data, navigate, dispatch,props]);

    return (
        <>
            <section className='bg_center' data-background={backgroundlogin}>
                <Container>
                    <div className=" set_center">
                        <div className="center_login">
                            <div className='btnBackHome'>
                                <Link className='backHome' to="/home"><TbArrowBack /></Link>
                            </div>
                            <div className="logoImg">
                                <img src={logo} alt="" />
                            </div>
                            <div className="cret_details">
                                <h4>Create an Account</h4>
                                <Form onSubmit={signupFormSubmit} onKeyDown={(e) => { if (e.key === 'Enter') e.preventDefault()}}>
                                    <FormGroup className="form_fields">
                                        <span className='form_icon'>
                                            <FaRegUser size={20}/>
                                        </span>
                                        <Form.Control 
                                            value={userDetails.username}
                                            onChange={handleSignupFormDetails}
                                            type="text"
                                            id="u-username"
                                            name="username"
                                            maxLength={200} 
                                            placeholder='Enter user name' 
                                        />
                                        {error && !userDetails?.username && (
                                            <span className="requiredInput">
                                                Required*
                                            </span>
                                        )}
                                    </FormGroup>
                                    <FormGroup className="form_fields">
                                        <span className='form_icon'>
                                            <AiOutlineMail size={20}/>
                                        </span>
                                        <Form.Control 
                                            value={userDetails.email}
                                            onChange={handleSignupFormDetails}
                                            type="email"
                                            id="u-email"
                                            name="email"
                                            maxLength={100} 
                                            placeholder='Enter your email' 
                                        />
                                        {error && !userDetails.email && (
                                            <span className="requiredInput" >
                                                Required*
                                            </span>
                                        )}
                                    </FormGroup>
                                    <FormGroup className="form_fields mb-0">
                                        <span className='form_icon'>
                                            <MdLockOutline size={20}/>
                                        </span>
                                        <Form.Control 
                                            type={showPassword ? 'text' : 'password'}
                                            value={userDetails.password}
                                            onChange={handleSignupFormDetails}
                                            id="u-password"
                                            name="password"
                                            minLength={8}
                                            placeholder='Enter your password' 
                                        />
                                        {error && !userDetails.password && (
                                            <span className="requiredInput" >
                                                Required*
                                            </span>
                                        )}
                                        <span className='onclickEye' onClick={() => {togglePasswordVisibility('pass')}}>
                                            {showPassword ? (
                                                <FaEye size={20}/>
                                            ) : (
                                                <FaEyeSlash size={20}/>
                                            )}
                                        </span>
                                    </FormGroup>

                                    <FormGroup className="form_fields mb-0">
                                        <span className='form_icon'>
                                            <MdLockOutline size={20}/>
                                        </span>
                                        <Form.Control 
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            value={userDetails.confirm_password}
                                            onChange={handleSignupFormDetails}
                                            id="u-confirm_password"
                                            name="confirm_password"
                                            minLength={8} 
                                            placeholder='Confirm your password' 
                                        />
                                        {error && !userDetails.confirm_password && (
                                            <span className="requiredInput" >
                                                Required*
                                            </span>
                                        )}
                                        <span className='onclickEye' onClick={() => {togglePasswordVisibility('conPass')}}>
                                            {showConfirmPassword ? (
                                                <FaEye size={20}/>
                                            ) : (
                                                <FaEyeSlash size={20}/>
                                            )}
                                        </span>
                                    </FormGroup>

                                    {/* <div className="reset_Field d-flex justify-content-end">
                                        <FormGroup className='check_formField'>
                                            <FormCheck type="checkbox" id="exampleCheck1" label="Remember me" />
                                        </FormGroup>
                                        <Link to="/forgot-password">Forgot  password?</Link>
                                    </div> */}

                                        
                                    <FormGroup className='check_formField check_terms mt-3'>
                                        <FormCheck 
                                            id="exampleCheck122" 
                                            type="checkbox" 
                                            value={check}
                                            onChange={(e) => setCheck(e.target.checked)}
                                        />
                                        <p style={{marginLeft : "10px"}}>By signing up, I agree to the find my surfpic<Link to="/terms-and-conditions"> Terms and Conditions, Privacy Statement.</Link></p>
                                        {error && !check && (
                                            <span className="requiredInput">
                                                Required*
                                            </span>
                                        )}
                                    </FormGroup>
                                    {(isLoading) ? (
                                        <>
                                            <Button className="btn_submit btn_signLogin" disabled>
                                                <Spinner animation="border" role="status"> </Spinner>
                                            </Button>
                                        </>
                                    ) : (
                                        <Button type="submit" className="btn_submit btn_signLogin">Sign Up</Button>
                                    )}
                                </Form>
                                <div className="form_go mt_40">
                                    <div className="actionSignup mb_24">
                                        <p>Alerady Have An Account? <Link to="/login">Sign In</Link></p>
                                    </div>
                                    {/* <div className="externalLogin">
                                        <p>Or continue with</p>
                                        <div className="externalLogin_inner">
                                            <Link className='goBtn'><FaFacebookF /></Link>
                                            <Link className='goBtn'><FaApple /></Link>
                                        </div>
                                    </div> */}
                                </div>
                            </div>

                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
}

export default SignUp;