import axios from "../services/axios";
import { searchSequenceConstants, clearHomeMessage, viewGalleryConstants, sequenceCheckoutConstants, imageCheckoutConstants, contactUsConstants, paymentRetrievConstants, 
        downloadImageConstants, imagesRetrievConstants, randomSequenceConstants } from "../constants/home.constants";

export const contactUsAction = (formData) => {
  return (dispatch) => {
    axios.post("/contact-us",formData).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: contactUsConstants.GET_CONTACTUS_SUCCESS, payload: { data : response.data },});
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const searchSequence = (page, formData) => {
    return (dispatch) => {
      axios.post(`/sequence/view?page=${page}`,formData).then((response) => {
          if (response.data.hasOwnProperty("errors")) {
            alert(response.data.message);
          } else {
            dispatch({ type: searchSequenceConstants.GET_SEARCHSEQUENCE_SUCCESS, payload: { searchData : response.data },});
            // redirect();
          }
        })
        .catch((err) => {
          dispatch({ type: 'HIDE_LOADER' });
          console.log(err);
        });
    };
};

export const randomSequenceAction = (formData) => {
  return (dispatch) => {
    axios.post('/sequence/desire',formData).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: randomSequenceConstants.GET_RANDOMSEQUENCE_SUCCESS, payload: { data : response.data },});
          // redirect();
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const viewGallery = (formData) => {
  return (dispatch) => {
    axios.post('/sequence/image/view',formData).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: viewGalleryConstants.GET_VIEWGALLERY_SUCCESS, payload: { galleryData : response.data },});
          // redirect();
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};


export const sequenceCheckout = (formData) => {
  return (dispatch) => {
    axios.post('/payment/checkout',formData).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: sequenceCheckoutConstants.GET_SEQUENCECHECKOUT_SUCCESS, payload: { checkoutData : response.data },});
          // redirect();
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const imageCheckout = (formData) => {
  return (dispatch) => {
    axios.post('/payment/checkout',formData).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: imageCheckoutConstants.GET_IMAGECHECKOUT_SUCCESS, payload: { checkoutData : response.data },});
          // redirect();
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const paymentRetriev = (formData) => {
  return (dispatch) => {
    axios.post('/payment/retrieve',formData).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: paymentRetrievConstants.GET_PAYMENTRETRIEV_SUCCESS, payload: { data : response.data },});
          // redirect();
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const imagesRetriev = (formData) => {
  return (dispatch) => {
    axios.post('/payment/download',formData).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: imagesRetrievConstants.GET_IMAGESRETRIEV_SUCCESS, payload: { data : response.data },});
          // redirect();
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const downloadImageAction = (formData) => {
  return (dispatch) => {
    axios.post('/payment/original',formData).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: downloadImageConstants.GET_DOWNLOADIMAGE_SUCCESS, payload: { downloadImageData: response.data, download_id: formData.download_id }, });
          // redirect();
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const clearHomeMsg = () => async (dispatch) => {
  try {
      dispatch({ type: clearHomeMessage.CLEAR_HOME_MESSAGE, payload: [] })
  } catch (err) {
      console.log(`Error while calling login api ${err}`);
  }
}
