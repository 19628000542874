import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { clearAuthMsg, myDataAction, userLogout } from "../../actions/authAction";
import logo from './../../assets/images/FMSPLogo2trans.png';
import instaLogo from '../../assets/images/insta-logo.jpg';
// import { IoMdSearch } from "react-icons/io";
import { IoMdLogOut } from "react-icons/io";
import { useEffect } from "react";
import { GiCancel } from "react-icons/gi";
import Modal from "react-modal";
import ChangePassword from "../auth/password/changePassword";
import { Dropdown } from "react-bootstrap";

const Header = () => {

    const dispatch = useDispatch();
    const [myData, setMyData] = useState("");
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const passwordProps =  {"from" : "passwordProps"}
    // const [isSticky, setIsSticky] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null);

    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
          right: "auto",
          bottom: "auto",
          padding: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
        },
    };

    // function for logout
    const handleLogout = () => {
        dispatch(userLogout());
    }

    useEffect(() =>{
        dispatch(myDataAction())
        const handleScroll = () => {
        const scrollDown = window.scrollY;

        if (scrollDown < 135) {
            // setIsSticky(false);
        } else {
            // setIsSticky(true);
        }
        };
            window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    },[dispatch])
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);
    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsMenuOpen(false);
        }
    };
    const handleTabClick = () => {
        setIsMenuOpen(false);
    };
    // Selects data related to the myData action from the Redux store state.
    const {my} = useSelector((state) => state.myData.myData);
    useEffect(() => {
        if (my && my.status) {
            setMyData(my?.data);
        }
    }, [my, dispatch]);


    // function for open or close modal
    const modalFunnction =(type)=>{
        if(type === 'open') setModalIsOpen(true); else setModalIsOpen(false);
        dispatch(clearAuthMsg());
    }

    // create password return from props
    const handleReturnedPasswordValue = () => {
        setModalIsOpen(false); // Close the popup
    };

    return(
        <>
            <header className="header_elements">
                <div className="header__sticky">
                {/* <div className={`header__sticky ${isSticky ? 'header__sticky-sticky-menu' : ''}`}> */}
                    <div className="container-fluid containerHeader">
                        <div className="header_bar d-flex">
                            <div className="menu_layer d-flex">
                                <div className="logo_elements d-flex">
                                    <div className="logo_main">
                                        <Link to="/home" className="logo_text"><img src={logo} alt=""/></Link>
                                    </div>
                                    {/* <div className="search_items">
                                        <div className="search_items_inner">
                                            <input type="text" placeholder="Search your image"/>
                                            <span className="searchGlass">
                                                <IoMdSearch size={20} />
                                            </span>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="search_items align-items-center d-flex justify-content-center mb-3">
                                    <div className="search_items_inner">
                                        <span className="searchGlass">
                                            <Link to="https://instagram.com/findmysurfpic" target="_blank" className="logo_text align-items-center">
                                                <img src={instaLogo} alt=""/>
                                                @findmysurfpic
                                            </Link>
                                        </span>
                                    </div>
                                </div>
                                <div className="menu_layer_menu d-flex">
                                    <div ref={menuRef} className={`menu_items ${isMenuOpen ? 'menu_items_release' : ''}`}>
                                        {(() => {
                                            if (myData?.role === 1) {
                                                return(
                                                    <ul className="d-flex">
                                                        <li className="dropdown_elements">
                                                            <Dropdown>
                                                                <Dropdown.Toggle ariant="success" id="dropdown-basic" className="list_dropMenu" >
                                                                    Feature's
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Link to="/admin/set-default-price" className="dropdown-item" onClick={handleTabClick}>
                                                                        Default Price
                                                                    </Link>
                                                                    <Link to="/admin/contact-list" className="dropdown-item" onClick={handleTabClick}>
                                                                        Contact List
                                                                    </Link>
                                                                    <Link to="/admin/payment/payouts" className="dropdown-item" onClick={handleTabClick}>
                                                                        Payment Payouts
                                                                    </Link>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </li>
                                                        <li className="dropdown_elements">
                                                            <Dropdown>
                                                                <Dropdown.Toggle ariant="success" id="dropdown-basic" className="list_dropMenu" >
                                                                    Location
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Link to="/admin/continents" className="dropdown-item" onClick={handleTabClick}>
                                                                        Continents
                                                                    </Link>
                                                                    <Link to="/admin/regions" className="dropdown-item" onClick={handleTabClick}>
                                                                        Regions
                                                                    </Link>
                                                                    <Link to="/admin/areas" className="dropdown-item" onClick={handleTabClick}>
                                                                        Areas
                                                                    </Link>
                                                                    <Link to="/admin/spots" className="dropdown-item" onClick={handleTabClick}>
                                                                        Spots
                                                                    </Link>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </li>
                                                        <li><Link to="/admin/users" onClick={handleTabClick}>User's</Link></li>
                                                        <li><Link to="/admin/sequences" onClick={handleTabClick}>Image's</Link></li>
                                                        <li><Link onClick={() => modalFunnction('open')} >Change Password</Link></li>
                                                        {/* <li><Link to="/about-us">About</Link></li> */}
                                                        <li><Link to="https://instagram.com/findmysurfpic" target="_blank" className="instaLogo">
                                                            <img src={instaLogo} alt=""/>
                                                            @findmysurfpic
                                                        </Link></li>
                                                    </ul>
                                                );
                                            } else if(myData?.role === 2){
                                                return(
                                                    <ul className="d-flex">
                                                        <li><Link to="/user/works" onClick={handleTabClick}>How it works</Link></li>
                                                        <li className="dropdown_elements">
                                                            <Dropdown>
                                                                <Dropdown.Toggle ariant="success" id="dropdown-basic" className="list_dropMenu">
                                                                    Upload Image
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Link to="/user/sequences" className="dropdown-item" onClick={handleTabClick}>
                                                                        Your Image's
                                                                    </Link>
                                                                    <Link to="/user/sequence/create" className="dropdown-item" onClick={handleTabClick}>
                                                                        Upload Image's
                                                                    </Link>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </li>
                                                        <li className="dropdown_elements">
                                                            <Dropdown>
                                                                <Dropdown.Toggle ariant="success" id="dropdown-basic" className="list_dropMenu" >
                                                                    Profile
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Link to="/user/profile" className="dropdown-item" onClick={handleTabClick}>
                                                                        My Profile
                                                                    </Link>
                                                                    <Link onClick={() => modalFunnction('open')} className="dropdown-item">
                                                                        Change Password
                                                                    </Link>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </li>
                                                        <li className="dropdown_elements">
                                                            <Link to="/user/payouts" onClick={handleTabClick}>Finances</Link>
                                                        </li>
                                                        <li><Link to="https://instagram.com/findmysurfpic" target="_blank" className="instaLogo">
                                                            <img src={instaLogo} alt=""/>
                                                            @findmysurfpic
                                                        </Link></li>
                                                    </ul>
                                                );
                                            }
                                        })()}
                                    </div>
                                    <div className="cart_elements">
                                        <Link onClick={handleLogout} title="logout"><span className="cart_icon"><IoMdLogOut size={25} /></span><span className="logText"> Logout</span></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="search_elements d-flex">
                                <div className="mobile-nav" onClick={toggleMenu}>
                                    <div className="bar1"></div>
                                    <div className="bar2"></div>
                                    <div className="bar3"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {/* Modal to change password */}
            <Modal isOpen={modalIsOpen === true} onRequestClose={modalFunnction} style={customStyles} >
                <span className="close-modal float-end pe-4 pt-4 text-danger" style={{cursor:'pointer'}} onClick={modalFunnction}>< GiCancel size={20}/></span>
                <ChangePassword {...passwordProps} onReturn={handleReturnedPasswordValue} />
            </Modal>
        </>
    );
}

export default Header;