import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { clearAdminMsg } from "../../actions/adminAction";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { getDefaultPrice, setDefaultPrice } from "../../actions/adminAction";

const SetDefaultPrice = () => {

    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [ error, setError] = useState(false);
    const [ sequencePrice, setSequencePrice] = useState("");
    const [ sequenceCommission, setSequenceCommission] = useState("");
    const [ imagePrice, setImagePrice] = useState("");
    const [ imageCommission, setImageCommission] = useState("");

    useEffect(() => {
        dispatch({ type: 'SHOW_LOADER' }); // Set loader to true
        dispatch(getDefaultPrice())
    }, [dispatch,id]);

    // Selects data related to the loader action from the Redux store state.
    const isLoading = useSelector(state => state.loader.isLoading);
    // Selects data related to the viewSequence action from the Redux store state.
    const { priceData } = useSelector((state) => state.getDefaultPrice.getDefaultPrice)
    useEffect(() => {
        dispatch({ type: 'HIDE_LOADER' });
        if(priceData && priceData?.data){
            setSequencePrice(priceData?.data?.list?.sequence_price);
            setSequenceCommission(priceData?.data?.list?.commission_sequence);
            setImagePrice(priceData?.data?.list?.image_price);
            setImageCommission(priceData?.data?.list?.commission_image);
        }
    },[priceData,dispatch])

    // function to set default price
    const handlePrice = async (e) => {
        e.preventDefault();
        // if(!sequencePrice || !sequenceCommission || !imagePrice || !imageCommission){
        if (!imagePrice || !imageCommission) {
            setError(true);
            return false;
        }else{
            dispatch({ type: 'SHOW_LOADER' }); // Set loader to true
            try {
                await dispatch(setDefaultPrice({
                    // sequence_price : sequencePrice,
                    // commission_sequence : sequenceCommission,
                    image_price : imagePrice,
                    commission_image : imageCommission,
                }))
            } catch (error) {
                dispatch({ type: 'HIDE_LOADER' });
                console.error("Error set price:", error);
            }
        }
    }

    // Selects data related to the updateSequence action from the Redux store state.
    const { data } = useSelector((state) => state.setDefaultPrice.setDefaultPrice)
    useEffect(() => {
        if (data && data.status) {
            dispatch({ type: 'HIDE_LOADER' });
            toast.success(data?.message[0],{theme: "dark",});
            dispatch(clearAdminMsg())
            navigate(-1);
        }
    }, [data, navigate, dispatch]);
    
    return (
        <>
            {isLoading && <div className="loader-overlay"><Spinner animation="border" role="status"> </Spinner></div>}
            <main className="set_gallery_bg">
                <section className="profile_elements pt_60 pb_60">
                    <div className="container">
                        <form onSubmit={handlePrice} onKeyDown={(e) => { if (e.key === 'Enter') e.preventDefault()}}>
                            <div className="upload_elements profile_card">
                                <h4 className="prof_title mt-4">SET DEFAULT PRICE</h4>
                                <div className="upload_image mt_20">
                                    <div className="form_elements field_drops">
                                        {false && (<div className="row">
                                            <div className="col-md-6">
                                                <fieldset className="form_fields_drops">
                                                    <label htmlFor="">Sequence Price<span className="text-danger">*</span></label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">$</span>
                                                        </div>
                                                        <input
                                                            type="text" id="name" 
                                                            name="name"
                                                            value={sequencePrice || ''}
                                                            onChange={(e) => { 
                                                                const regex = /^\d{0,5}(\.\d{0,2})?$/;
                                                                if (regex.test(e.target.value.replace(/[^\d.]/g, ''))) {
                                                                    setSequencePrice(e.target.value.replace(/[^\d.]/g, ''));
                                                                }
                                                            }}
                                                            placeholder="Enter Sequence Price"
                                                        />
                                                    </div>
                                                    {error && !sequencePrice && (
                                                        <span className="requiredInput" >
                                                            Required*
                                                        </span>
                                                    )}
                                                </fieldset>
                                            </div>
                                            <div className="col-md-6">
                                                <fieldset className="form_fields_drops">
                                                    <label htmlFor="">Sequence Commission<span className="text-danger">*</span></label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">$</span>
                                                        </div>
                                                        <input
                                                            type="text" id="name" 
                                                            name="name"
                                                            value={sequenceCommission || ''}
                                                            onChange={(e) => { 
                                                                const regex = /^\d{0,5}(\.\d{0,2})?$/;
                                                                if (regex.test(e.target.value.replace(/[^\d.]/g, ''))) {
                                                                    setSequenceCommission(e.target.value.replace(/[^\d.]/g, ''));
                                                                }
                                                            }}
                                                            placeholder="Enter Sequence Commission"
                                                        />
                                                    </div>
                                                    {error && !sequenceCommission && (
                                                        <span className="requiredInput" >
                                                            Required*
                                                        </span>
                                                    )}
                                                </fieldset>
                                            </div>
                                        </div>)}

                                        <div className="row">
                                            <div className="col-md-6">
                                                <fieldset className="form_fields_drops">
                                                    <label htmlFor="">Image Price<span className="text-danger">*</span></label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">$</span>
                                                        </div>
                                                        <input
                                                            type="text" id="name" 
                                                            name="name"
                                                            value={imagePrice || ''}
                                                            onChange={(e) => { 
                                                                const regex = /^\d{0,5}(\.\d{0,2})?$/;
                                                                if (regex.test(e.target.value.replace(/[^\d.]/g, ''))) {
                                                                    setImagePrice(e.target.value.replace(/[^\d.]/g, ''));
                                                                }
                                                            }}
                                                            placeholder="Enter Image Price"
                                                        />
                                                    </div>
                                                    {error && !imagePrice && (
                                                        <span className="requiredInput" >
                                                            Required*
                                                        </span>
                                                    )}
                                                </fieldset>
                                            </div>
                                            <div className="col-md-6">
                                                <fieldset className="form_fields_drops">
                                                    <label htmlFor="">Image Commission<span className="text-danger">*</span></label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">$</span>
                                                        </div>
                                                        <input
                                                            type="text" id="name" 
                                                            name="name"
                                                            value={imageCommission || ''}
                                                            onChange={(e) => { 
                                                                const regex = /^\d{0,5}(\.\d{0,2})?$/;
                                                                if (regex.test(e.target.value.replace(/[^\d.]/g, ''))) {
                                                                    setImageCommission(e.target.value.replace(/[^\d.]/g, ''));
                                                                }
                                                            }}
                                                            placeholder="Enter Image Commission"
                                                        />
                                                    </div>
                                                    {error && !imageCommission && (
                                                        <span className="requiredInput" >
                                                            Required*
                                                        </span>
                                                    )}
                                                </fieldset>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="upload_btn d-flex justify-content-center mt-4">
                                    <button type="submit" className="loadMore_action">Update</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </main>
        </>
    );
}

export default SetDefaultPrice;


