import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import configureStore from './store/store';
import Modal from 'react-modal';
// import { myDataAction } from './actions/authAction';

const Store = configureStore();

//to handle page reloads
if (localStorage.getItem("token")) {
  // Store.dispatch(myDataAction())
}
// Set the app element for react-modal
Modal.setAppElement('#root');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={Store}>
      <App />
  </Provider>
);

reportWebVitals();