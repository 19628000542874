import React, { useState, useEffect } from "react";
import backgroundlogin from '../../../assets/images/4.jpg';
import Container from 'react-bootstrap/Container';
import { Form, Button, FormGroup, FormCheck } from 'react-bootstrap';
import logo from '../../../assets/images/FMSPLogo2trans.png';
import { FaEye, FaEyeSlash} from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import { MdLockOutline } from "react-icons/md";
import { TbArrowBack } from "react-icons/tb";
import { loginUser } from "../../../actions/authAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";


const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [rememberMe, setRememberMe] = useState(false);
    const [showPassword, setShowPassword] = useState("");
    const [error, setError] = useState(false);

    useEffect(() => {
        dispatch({ type: 'CLEAR_SEARCHSEQUENCE_MESSAGE' });
    },[dispatch])
    
    useEffect(() => {
        document.querySelectorAll('[data-background]').forEach(element => {
            const backgroundImage = element.getAttribute('data-background');
            if (backgroundImage) {
                element.style.backgroundImage = `url(${backgroundImage})`;
            }
        });
        document.title = 'Sign In';
        // set local storage for remember me
        const storedRememberMe = localStorage.getItem('remember_me');
        if (storedRememberMe) {
            const parsedRememberMe = JSON.parse(storedRememberMe);
            setUsername(parsedRememberMe.username);
            setPassword(parsedRememberMe.password);
            setRememberMe(true);
        }
    }, []);

    // Selects data related to the loader action from the Redux store state.
    const isLoading = useSelector(state => state.loader.isLoading);

    const handleLogin = async (e) => {
        e.preventDefault();
        if (rememberMe)// If rememberMe is checked, store the user's credentials in local storage
            localStorage.setItem('remember_me', JSON.stringify({ username, password }));
        else
            localStorage.removeItem('remember_me'); // If rememberMe is not checked, clear the stored credentials

        if (!username || !password) {
            dispatch({ type: 'HIDE_LOADER' });
            setError(true);
            return false;
        }
        dispatch({ type: 'SHOW_LOADER' });
        dispatch(loginUser({ username: username, password: password }));
    };
   
    // hide or show password
    const togglePasswordVisibility = (type) => {
        setShowPassword(!showPassword);
    };

    const authuser = useSelector((state) => state.userLogin);
    useEffect(() => {
        const registerDeviceToken = async () => {
            if (authuser && authuser?.authuser && authuser?.authuser?.data && authuser?.authuser?.data?.status === true) {
                toast.success(authuser?.authuser?.data?.message[0],{theme: "dark",});
                dispatch({ type: 'HIDE_LOADER' });
                if (authuser?.authuser?.data?.data.user.role === 1){
                    navigate('/admin/sequences');
                }else{
                    navigate('/user/sequences');
                }
            }
        };
        registerDeviceToken();
    }, [authuser,navigate,dispatch]);

    return (
        <>
            <section className='bg_center' data-background={backgroundlogin}>
                <Container>
                    <div className=" set_center">
                        <div className="center_login">
                            <div className='btnBackHome'>
                                <Link className='backHome' to="/home"><TbArrowBack /></Link>
                            </div>
                            <div className="logoImg">
                                <img src={logo} alt="" />
                            </div>
                            <div className="cret_details">
                                <h4>Sign In</h4>
                                <Form onSubmit={handleLogin}>
                                    <FormGroup className="form_fields">
                                        <span className='form_icon'>
                                            <AiOutlineMail size={20}/>
                                        </span>
                                        <Form.Control 
                                            type="text"
                                            value={username}
                                            placeholder="Enter Username"
                                            onChange={(e) => setUsername(e.target.value)}
                                            id="u-name"
                                            name="username"
                                        />
                                        {error && !username && (
                                            <span className="requiredInput">
                                                Required*
                                            </span>
                                        )}
                                    </FormGroup>
                                    <FormGroup className="form_fields mb-0">
                                        <span className='form_icon'>
                                            <MdLockOutline size={20}/>
                                        </span>
                                        <Form.Control  type={showPassword ? 'text' : 'password'}
                                            value={password}
                                            placeholder="Enter Password"
                                            onChange={(e) => setPassword(e.target.value)}
                                            id="u-password"
                                            className="form-control"
                                            name="password" 
                                        />
                                        {error && !password && (
                                            <span className="requiredInput">
                                                Required*
                                            </span>
                                        )}
                                        <span className='onclickEye' onClick={() => {togglePasswordVisibility('new')}}>
                                            {showPassword ? (
                                                <FaEye size={20}/>
                                            ) : (
                                                <FaEyeSlash size={20}/>
                                            )}
                                        </span>
                                    </FormGroup>
                                    <div className="reset_Field d-flex justify-content-between mb-2">
                                        <FormGroup className='check_formField'>
                                            <FormCheck type="checkbox" 
                                                checked={rememberMe}
                                                onChange={(e) => setRememberMe(e.target.checked)} id="exampleCheck1" label="Remember me" />
                                        </FormGroup>
                                        <Link to="/forgot-password">Forgot  password?</Link>
                                    </div>

                                    {(isLoading) ? (
                                        <Button className="btn_submit btn_signLogin" disabled>
                                            <Spinner animation="border" role="status"> </Spinner>
                                        </Button>
                                    ) : (
                                        <Button type="submit" className="btn_submit btn_signLogin">Sign In</Button>
                                    )}
                                </Form>
                                <div className="form_go mt_40">
                                    <div className="actionSignup mb_24">
                                        <p>Don't have an account? <Link to="/signup">Create one</Link></p>
                                    </div>
                                    {/* <div className="externalLogin">
                                        <p>Or continue with</p>
                                        <div className="externalLogin_inner">
                                            <Link className='goBtn'><FaFacebookF /></Link>
                                            <Link className='goBtn'><FaApple /></Link>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default Login;
