import { userSequenceListConstants,  sequenceStatusConstants, viewUserSequenceConstants, createSequenceConstants, updateSequenceConstants, clearSequenceMessage, deleteSequenceConstants, deleteImageConstants, imageStatusConstants} from "../constants/sequence.constants";

// User/Sequence Reducer from here  
export const userSequenceListReducer = (state = { userSequenceList : [] }, action) => {
    switch (action.type) {
        case viewUserSequenceConstants.GET_VIEWUSERSEQUENCE_SUCCESS:
            return { userSequenceList: [] };
        case userSequenceListConstants.GET_USERSEQUENCELIST_SUCCESS:
            return { userSequenceList: action.payload };
        case userSequenceListConstants.GET_USERSEQUENCELIST_FAILURE:
            return { error: action.payload };
        case clearSequenceMessage.CLEAR_SEQUENCE_MESSAGE:
            return { userSequenceList: [], error: "" };
        default:
            return state;
    }
};

export const sequenceStatusReducer = (state = { sequenceStatus : [] }, action) => {
    switch (action.type) {
        case sequenceStatusConstants.GET_SEQUENCESTATUS_SUCCESS:
            return { sequenceStatus: action.payload };
        case sequenceStatusConstants.GET_SEQUENCESTATUS_FAILURE:
            return { error: action.payload };
        case clearSequenceMessage.CLEAR_SEQUENCE_MESSAGE:
            return { sequenceStatus: [], error: "" }
        default:
            return state;
    }
};

export const imageStatusReducer = (state = { imageStatus : [] }, action) => {
    switch (action.type) {
        case imageStatusConstants.GET_IMAGESTATUS_SUCCESS:
            return { imageStatus: action.payload };
        case imageStatusConstants.GET_IMAGESTATUS_FAILURE:
            return { error: action.payload };
        case clearSequenceMessage.CLEAR_SEQUENCE_MESSAGE:
            return { imageStatus: [], error: "" }
        default:
            return state;
    }
};

export const viewUserSequenceReducer = (state = { viewUserSequence : [] }, action) => {
    switch (action.type) {
        case userSequenceListConstants.GET_USERSEQUENCELIST_SUCCESS:
            return { viewUserSequence: [] };
        case viewUserSequenceConstants.GET_VIEWUSERSEQUENCE_SUCCESS:
            return { viewUserSequence: action.payload };
        case viewUserSequenceConstants.GET_VIEWUSERSEQUENCE_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
};

export const createSequenceReducer = (state = { createSequence : [] }, action) => {
    switch (action.type) {
        case createSequenceConstants.GET_CREATESEQUENCE_SUCCESS:
            return { createSequence: action.payload };
        case createSequenceConstants.GET_CREATESEQUENCE_FAILURE:
            return { error: action.payload };
        case clearSequenceMessage.CLEAR_SEQUENCE_MESSAGE:
            return { createSequence: [], error: "" }
        default:
            return state;
    }
};

export const updateSequenceReducer = (state = { updateSequence : [] }, action) => {
    switch (action.type) {
        case updateSequenceConstants.GET_UPDATESEQUENCE_SUCCESS:
            return { updateSequence: action.payload };
        case updateSequenceConstants.GET_UPDATESEQUENCE_FAILURE:
            return { error: action.payload };
        case clearSequenceMessage.CLEAR_SEQUENCE_MESSAGE:
            return { updateSequence: [], error: "" }
        default:
            return state;
    }
};

export const deleteSequenceReducer = (state = { deleteSequence : [] }, action) => {
    switch (action.type) {
        case deleteSequenceConstants.GET_DELETESEQUENCE_SUCCESS:
            return { deleteSequence: action.payload };
        case deleteSequenceConstants.GET_DELETESEQUENCE_FAILURE:
            return { error: action.payload };
        case clearSequenceMessage.CLEAR_SEQUENCE_MESSAGE:
            return { deleteSequence: [], error: "" }
        default:
            return state;
    }
};

export const deleteImageReducer = (state = { deleteImage : [] }, action) => {
    switch (action.type) {
        case deleteImageConstants.GET_DELETEIMAGE_SUCCESS:
            return { deleteImage: action.payload };
        case deleteImageConstants.GET_DELETEIMAGE_FAILURE:
            return { error: action.payload };
        case clearSequenceMessage.CLEAR_SEQUENCE_MESSAGE:
            return { deleteImage: [], error: "" }
        default:
            return state;
    }
};