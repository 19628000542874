import { createStore, combineReducers, applyMiddleware } from "redux";
import {thunk} from "redux-thunk";
import { loginReducer, registerReducer, forgotPasswordReducer, resetPasswordReducer, emailVerificationReducer, resendEmailVerificationReducer, changePasswordReducer, myDataReducer } from "../reducers/authReducer";
import { viewProfileReducer, profileUpdateReducer, getUserPayoutsReducer, payoutDetailsReducer } from "../reducers/userReducer";
import { userSequenceListReducer, sequenceStatusReducer, viewUserSequenceReducer, createSequenceReducer, updateSequenceReducer, deleteSequenceReducer, deleteImageReducer, imageStatusReducer } from "../reducers/sequenceReducer";
import { userListReducer, viewUserProfileReducer, userProfileUpdateReducer, sequenceListReducer, sequenceApproveReducer, viewSequenceReducer, updateSequenceByAdminReducer, 
          sequenceByUserReducer, setDefaultPriceReducer, getDefaultPriceReducer, continentListReducer, createContinentReducer, deleteContinentReducer, continentStatusReducer, editContinentReducer, updateContinentReducer, createRegionReducer, regionListReducer, regionStatusReducer, editRegionReducer, updateRegionReducer, deleteRegionReducer, areaListReducer, createAreaReducer, areaStatusReducer, editAreaReducer, updateAreaReducer, deleteAreaReducer, spotListReducer, createSpotReducer, spotStatusReducer, editSpotReducer, updateSpotReducer, deleteSpotReducer, contactListReducer, contactStatusReducer, allPaymentsPayoutReducer } from "../reducers/adminReducer";
import { getAllLocationReducer, getAreaSpotsReducer, getContinentRegionsReducer, getRegionAreasReducer, loaderReducer } from "../reducers/commonReducer";
import { contactUsReducer, downloadImageReducer, imageCheckoutReducer, imagesRetrievReducer, paymentRetrievReducer, randomSequenceReducer, searchSequenceReducer, sequenceCheckoutReducer, viewGalleryReducer } from "../reducers/homeReducer";

const configureStore = () => {
  const store = createStore(
    combineReducers({
      //Auth Reducer
      userLogin       : loginReducer,
      myData          : myDataReducer,
      signup          : registerReducer,
      forgotPassword  : forgotPasswordReducer,
      resetPassword   : resetPasswordReducer,
      verification    : emailVerificationReducer,
      resendVerification : resendEmailVerificationReducer,
      changePassword  : changePasswordReducer,

      // For Continent Location
      continentList   : continentListReducer,
      createContinent : createContinentReducer,
      continentStatus : continentStatusReducer,
      editContinent   : editContinentReducer,
      updateContinent : updateContinentReducer,
      deleteContinent : deleteContinentReducer,

      // For Region Location
      regionList   : regionListReducer,
      createRegion : createRegionReducer,
      regionStatus : regionStatusReducer,
      editRegion   : editRegionReducer,
      updateRegion : updateRegionReducer,
      deleteRegion : deleteRegionReducer,

      // For Area Location
      areaList   : areaListReducer,
      createArea : createAreaReducer,
      areaStatus : areaStatusReducer,
      editArea   : editAreaReducer,
      updateArea : updateAreaReducer,
      deleteArea : deleteAreaReducer,

      // For Spot Location
      spotList   : spotListReducer,
      createSpot : createSpotReducer,
      spotStatus : spotStatusReducer,
      editSpot   : editSpotReducer,
      updateSpot : updateSpotReducer,
      deleteSpot : deleteSpotReducer,


      //common Reducer
      loader              : loaderReducer,
      getAllLocation      : getAllLocationReducer,
      getContinentRegions : getContinentRegionsReducer,
      getRegionAreas      : getRegionAreasReducer,
      getAreaSpots        : getAreaSpotsReducer,
    
      //Admin Reducer
      userList          : userListReducer,
      contactList       : contactListReducer,
      contactStatus     : contactStatusReducer,
      viewUserProfile   : viewUserProfileReducer,
      userProfileUpdate : userProfileUpdateReducer,
      updateSequenceByAdmin : updateSequenceByAdminReducer,
      getDefaultPrice   : getDefaultPriceReducer,
      setDefaultPrice   : setDefaultPriceReducer,


      //Sequences
      sequenceByUser  : sequenceByUserReducer,
      sequenceList    : sequenceListReducer,
      viewSequence    : viewSequenceReducer,
      sequenceApprove : sequenceApproveReducer,


      //User Reducer
      viewProfile     : viewProfileReducer,
      profileUpdate   : profileUpdateReducer,
      userPayouts     : getUserPayoutsReducer,
      allPaymentsPayout : allPaymentsPayoutReducer,
      payoutDetail    : payoutDetailsReducer,

      //Sequences Reducer
      userSequenceList: userSequenceListReducer,
      sequenceStatus  : sequenceStatusReducer,
      imageStatus     : imageStatusReducer,
      viewUserSequence: viewUserSequenceReducer,
      createSequence  : createSequenceReducer,
      updateSequence  : updateSequenceReducer,
      deleteSequence  : deleteSequenceReducer,
      deleteImage     : deleteImageReducer,

      //Home Reducer
      contactUs       : contactUsReducer,
      searchSequence  : searchSequenceReducer,
      randomSequence  : randomSequenceReducer,
      viewGallery     : viewGalleryReducer,
      sequenceCheckout: sequenceCheckoutReducer,
      imageCheckout   : imageCheckoutReducer,
      paymentRetriev  : paymentRetrievReducer,
      imagesRetriev   : imagesRetrievReducer,
      downloadImage   : downloadImageReducer,
      
    }),
    applyMiddleware(thunk)
  );
  return store;
};

export default configureStore;
