import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaChevronLeft, FaCartPlus, FaEye} from "react-icons/fa";
import { Spinner } from "react-bootstrap";
import defaultImg from './../../assets/images/defalut.jpg'
import { viewGallery } from "../../actions/homeAction";
import { PiImageLight } from "react-icons/pi";
// import { previewImgage } from "../common/utils";
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

const Gallery = () =>{
    
    const { key } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const [galleryDetails, setGalleryDetails] = useState({});

    // useEffect for call Api's 
    useEffect(() => {
        dispatch({ type: 'SHOW_LOADER' }); // Set loader to true
        dispatch(viewGallery({ sequence_key : key }));
    },[dispatch, key])

    // Selects data related to the loader action from the Redux store state.
    const isLoading = useSelector(state => state.loader.isLoading);
    // Selects data related to the viewSequence action from the Redux store state.
    const { galleryData } = useSelector((state) => state.viewGallery.viewGallery)
    useEffect(() => {
        if (galleryData && galleryData?.status) {
            dispatch({ type: 'HIDE_LOADER' });
            setGalleryDetails(galleryData?.data?.list);
        }
    }, [galleryData, dispatch]);

    return(
        <>  
            {/* Conditional rendering for the loader */}
            {isLoading && <div className="loader-overlay"><Spinner animation="border" role="status"> </Spinner></div>}
            <main className="set_gallery_bg">
                <section className="search_section">
                    <div className="container">
                        <div className="find_data form_gallery_data">
                            <span className="goBackbtn" title="go back" onClick={() => navigate(-1)}><FaChevronLeft /></span>
                            <div className="oneditor_outer d-flex">
                                <div className="onEditor d-flex">
                                    {/* <div className="form_profile">
                                        <img src={(galleryDetails?.user?.picture) ? (galleryDetails?.user?.picture) : 'defaultProfile'} className="form_profile_icon" alt="" />
                                    </div> */}
                                    <h3 className="gallery_innerTitle" style={{ fontFamily: "system-ui"}}>
                                        {(galleryDetails?.name) ? (galleryDetails?.name.charAt(0).toUpperCase() + galleryDetails?.name.slice(1))+','  : ''} Gallery
                                    </h3>
                                </div>
                            </div>
                            <div className="image_wrapField ">
                                <h6 className="image_galleryTiitle mb_40" style={{ fontFamily: "fantasy" }}> {galleryDetails ? ( <>{galleryDetails?.area}, {galleryDetails?.spot}, {galleryDetails?.region} Picture Colloction </>) : ''}</h6>
                                <div className="item_log_pic">
                                    <ul className="item_searchvalue">
                                    <PhotoProvider>
                                    {(galleryDetails?.images?.length > 0) ? (
                                        galleryDetails?.images?.map((data, index) => (
                                            <li key={index} className="item_flexInner">
                                                <div className="desk_card_outer">
                                                    <PhotoView key={index} src={(data.watermark) ? data.watermark : defaultImg}>
                                                        <img src={(data?.watermark) ? data?.watermark : defaultImg} alt="" className="card_images_admin img-fluid"/>
                                                    </PhotoView>
                                                    <div className="img_desk_card">
                                                        {/* <p>New York picture collection</p> */}
                                                        <div className="public_items d-flex">
                                                            <div className="public_trash d-flex float-left">
                                                                <Link to={`/image-payment-checkout/${galleryDetails?.sequence_key}/${data?.id}`} title="Payment" className="btn_edit fab_color">
                                                                    <FaCartPlus  size={20}/>
                                                                </Link>
                                                                <PhotoView key={index} src={(data.watermark) ? data.watermark : defaultImg}>
                                                                    <Link title="View" className="btn_edit" 
                                                                        // onClick={() => previewImgage(data?.watermark)}
                                                                    >
                                                                        <FaEye size={20}/>
                                                                    </Link>
                                                                </PhotoView>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))
                                    ): (
                                        <div className="d-flex no_record">
                                            <PiImageLight size={100}/>
                                            <span align={'center'} style={{fontWeight:'700',padding:'1em'}}> 
                                                No Record Found. 
                                            </span>
                                        </div>
                                    )}
                                    </PhotoProvider>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}
export default Gallery;