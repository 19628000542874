import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import defaultImg from '../../../assets/images/defalut.jpg'
import { sequenceCheckout, viewGallery } from "../../../actions/homeAction";
import moment from "moment";
import { toast } from "react-toastify";
import discover from '../../../assets/images/discover.svg'
import masterCard from '../../../assets/images/mastercard.png'
import visa from '../../../assets/images/visa.png'
import amex from '../../../assets/images/amex.png'
import RandomSequence from "../rendomSequence";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
// import ReactShowMoreText from "react-show-more-text";
SwiperCore.use([Navigation, Pagination]);


const SequencePaymentCheckout = () =>{
    
    const { key } = useParams();
    const dispatch = useDispatch();

    const [paymentDetails, setPaymentDetails] = useState({});

    // useEffect for call Api's 
    useEffect(() => {
        dispatch({ type: 'SHOW_LOADER' }); // Set loader to true
        dispatch(viewGallery({ sequence_key : key }));
    },[dispatch, key])

    // Selects data related to the loader action from the Redux store state.
    const isLoading = useSelector(state => state.loader.isLoading);
    // Selects data related to the viewSequence action from the Redux store state.
    const { galleryData } = useSelector((state) => state.viewGallery.viewGallery)
    useEffect(() => {
        if (galleryData && galleryData?.status) {
            dispatch({ type: 'HIDE_LOADER' });
            setPaymentDetails(galleryData?.data?.list);
        }
    }, [galleryData, dispatch]);

    // function to checkout payment
    const handlePayment = async (seq_id) => {
        try {
            await dispatch(sequenceCheckout({
                sequence_id : seq_id
            }));
        } catch (error) {
            dispatch({ type: 'HIDE_LOADER' });
            toast.error('Something went wrong',{theme: "dark",});
            console.error("Error in payment:", error);
        }
    }

    // Selects data related to the sequenceCheckout action from the Redux store state.
    const { checkoutData } = useSelector((state) => state.sequenceCheckout.sequenceCheckout)
    useEffect(() => {
        if (checkoutData && checkoutData?.status) {
            dispatch({ type: 'HIDE_LOADER' });
            window.location.href = checkoutData?.data?.url;
        }
    }, [checkoutData, dispatch]);

    return(
        <>  
            {/* Conditional rendering for the loader */}
            {isLoading && <div className="loader-overlay"><Spinner animation="border" role="status"> </Spinner></div>}
            <main className="set_gallery_bg">
                <section className="search_section_slider pt-5">
                    <div className="container">
                        <div className="find_data">
                            {/* <h3>Find The Desire Location Pictures</h3>
                            <h5 className="titel_slider mb-0">{(paymentDetails?.name) ? (paymentDetails?.name.charAt(0).toUpperCase() + paymentDetails?.name.slice(1))  : 'N/A'}</h5> */}
                        </div>
                    </div>
                </section>
                
                <section className="checkOut_Layer sec_sliderGallery">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                {/* <h5 className="titel_slider">{(paymentDetails?.name) ? (paymentDetails?.name.charAt(0).toUpperCase() + paymentDetails?.name.slice(1))  : 'N/A'}</h5> */}
                                <div className="slider_history">
                                    <Swiper className="swiper mySwiper"
                                        spaceBetween={50}
                                        slidesPerView={1}
                                        navigation
                                        pagination={{ clickable: true }}
                                        // scrollbar={{ draggable: true }}
                                        // onSlideChange={() => console.log('slide change')}
                                        // onSwiper={(swiper) => console.log(swiper)}
                                    >
                                        {(paymentDetails?.images?.length > 0) && 
                                            paymentDetails.images.map((data, index) => (
                                                <div key={index} className="swiper-slide">
                                                    <SwiperSlide>
                                                        <div className="swiper-wrapper">
                                                            <div className="swiper-slide">
                                                                <img src={data?.watermark ? data.watermark : defaultImg} alt="" className="image_slider" />
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                </div>
                                            ))
                                        }
                                    </Swiper>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card_checkout">
                                    <div className="card_checkDetails">
                                        <ul>
                                            <li>
                                                <div className="d-flex justify-content-between checkOut_list">
                                                    <span className="checkout_name">Published:</span>
                                                    <span className="checkout_subName">{(paymentDetails?.date) ? moment(paymentDetails?.date).format('DD MMM, YYYY') : 'N/A'}</span>
                                                </div>
                                            </li>
                                            {/* <li>
                                                <div className="d-flex justify-content-between checkOut_list">
                                                    <span className="checkout_name">Gallery:</span>
                                                    <span className="checkout_subName colUnder">
                                                        <Link to="">{(paymentDetails?.name) ? (paymentDetails?.name.charAt(0).toUpperCase() + paymentDetails?.name.slice(1))  : 'N/A'}</Link>
                                                    </span>
                                                </div>
                                            </li> */}
                                            <li>
                                                <div className="d-flex justify-content-between checkOut_list">
                                                    <span className="checkout_name">Continent:</span>
                                                    <span className="checkout_subName">{(paymentDetails?.continent) ? (paymentDetails?.continent.charAt(0).toUpperCase() + paymentDetails?.continent.slice(1))  : 'N/A'}</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex justify-content-between checkOut_list">
                                                    <span className="checkout_name">Region:</span>
                                                    <span className="checkout_subName">{(paymentDetails?.region) ? (paymentDetails?.region.charAt(0).toUpperCase() + paymentDetails?.region.slice(1))  : 'N/A'}</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex justify-content-between checkOut_list">
                                                    <span className="checkout_name">Area:</span>
                                                    <span className="checkout_subName">{(paymentDetails?.area) ? (paymentDetails?.area.charAt(0).toUpperCase() + paymentDetails?.area.slice(1))  : 'N/A'}</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex justify-content-between checkOut_list">
                                                    <span className="checkout_name">Spot:</span>
                                                    <span className="checkout_subName">{(paymentDetails?.spot) ? (paymentDetails?.spot.charAt(0).toUpperCase() + paymentDetails?.spot.slice(1))  : 'N/A'}</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex justify-content-between checkOut_list">
                                                    <span className="checkout_name">Price:</span>
                                                    <span className="checkout_subName">{(paymentDetails?.sequence_price) ? `$ `+(paymentDetails?.sequence_price)  : 'N/A'}</span>
                                                </div>
                                            </li>
                                        </ul>

                                    </div>
                                    <div className="addInformation">
                                        {/* <h6>ADDITIONAL INFORMATION</h6>
                                        <ReactShowMoreText
                                          lines={5}
                                          more="Read more"
                                          less="Read less"
                                          className="content-css"
                                          anchorClass="show-more-less-clickable"
                                          expanded={false}
                                          truncatedEndingComponent={"... "}
                                        >
                                            <p>{(paymentDetails?.detail) ? paymentDetails?.detail : 'N/A'}</p>
                                        </ReactShowMoreText> */}
                                    </div>
                                    <Link onClick={() => handlePayment(paymentDetails?.id)} className="btn btn_default btn_find set_fullValue">Checkout</Link>
                                    <div className="payment_logo">
                                        <div className="pay_logo"> 
                                        <img src={visa} alt="paymentLogo" className="img-fluid" />
                                        </div>
                                        <div className="pay_logo"> 
                                        <img src={masterCard} alt="paymentLogo" className="img-fluid" />
                                        </div>
                                        <div className="pay_logo"> 
                                        <img src={amex} alt="paymentLogo" className="img-fluid" />
                                        </div>
                                        <div className="pay_logo"> 
                                        <img src={discover} alt="paymentLogo" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <RandomSequence/>
            </main>
        </>
    );
}
export default SequencePaymentCheckout;