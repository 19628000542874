import { viewProfileConstants, profileUpdateConstants, clearUserMessage, getUserPayoutsConstants, payoutDetailsLinkConstants } from "../constants/user.constants";


/************************** Reducer For Authentication *******************************/
export const viewProfileReducer = (state = { viewProfile : [] }, action) => {
    switch (action.type) {
        case viewProfileConstants.GET_VIEWPROFILE_SUCCESS:
            return { viewProfile: action.payload };
        case viewProfileConstants.GET_VIEWPROFILE_FAILURE:
            return { error: action.payload };
        case clearUserMessage.CLEAR_USER_MESSAGE:
            return { viewProfile: [], error: "" }
        default:
            return state;
    }
};

export const profileUpdateReducer = (state = { profileUpdate: [] }, action) => {
    switch (action.type) {
        case profileUpdateConstants.GET_PROFILEUPDATE_SUCCESS:
            return { profileUpdate: action.payload };
        case profileUpdateConstants.GET_PROFILEUPDATE_FAILURE:
            return { error: action.payload };
        case clearUserMessage.CLEAR_USER_MESSAGE:
            return { profileUpdate: [], error: "" }
        default:
            return state;
    }
};

export const getUserPayoutsReducer = (state = { userPayouts: [] }, action) => {
    switch (action.type) {
        case getUserPayoutsConstants.GET_USERPAYOUTS_SUCCESS:
            return { userPayouts: action.payload };
        case getUserPayoutsConstants.GET_USERPAYOUTS_FAILURE:
            return { error: action.payload };
        case clearUserMessage.CLEAR_USER_MESSAGE:
            return { userPayouts: [], error: "" }
        default:
            return state;
    }
};

export const payoutDetailsReducer = (state = { payoutDetail: [] }, action) => {
    switch (action.type) {
        case payoutDetailsLinkConstants.GET_PAYOUTDETAILS_SUCCESS:
            return { payoutDetail: action.payload };
        case payoutDetailsLinkConstants.GET_PAYOUTDETAILS_FAILURE:
            return { error: action.payload };
        case clearUserMessage.CLEAR_USER_MESSAGE:
            return { payoutDetail: [], error: "" }
        default:
            return state;
    }
};
