import React, { useEffect } from 'react'
import Header from './header';
import Footer from './footer';
import { Outlet } from 'react-router-dom';

const Section = () => {

    useEffect(() => {
        document.querySelectorAll('[data-background]').forEach(element => {
            const backgroundImage = element.getAttribute('data-background');
            if (backgroundImage) {
                element.style.backgroundImage = `url(${backgroundImage})`;
            }
        });
    }, []);

    return (
        <>
            <Header />
                <Outlet/>
            <Footer />
        </>
    )
}

export default Section;