import React from "react";
import { Navigate, Outlet } from "react-router-dom";


export const AdminPrivateRoute = () => {
    const token = localStorage.getItem('token');
    const auth = JSON.parse(localStorage.getItem('userData'));
    if (auth && token) {
        return auth && auth.role === 1 ? <Outlet /> : <Navigate to="/user/sequences" replace />;
    } else {
        return <Navigate to="/home" replace />;
    }
};

export const UserPrivateRoute = () => {
    const token = localStorage.getItem('token');
    const auth = JSON.parse(localStorage.getItem('userData'));
    if (auth && token) {
        return auth && auth.role === 2 ? <> <Outlet /> </> : <> <Navigate to="/admin/sequences" replace /></>;
    } else {
        return <Navigate to="/home" replace />;
    }
};

export const AuthRoute = () => {
    const token = localStorage.getItem('token');
    const auth = JSON.parse(localStorage.getItem('userData'));
    if (!auth && !token) {
        return !auth && !token ? <Outlet /> : <Navigate to="/home" replace />;
    } else {
        return auth && auth.role === 1 ? <Navigate to="/admin/sequences" replace /> : <Navigate to="/user/sequences" replace />;
    }
};

