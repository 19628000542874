import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import DataTable from 'datatables.net-dt';
import moment from "moment";
import { FaCheckCircle, FaPlus } from "react-icons/fa";
import { MdOutlineWatchLater, MdCancel } from "react-icons/md";
import { getUserPayouts, payoutDetailsLink } from "../../../actions/userAction";

const UserPayouts = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [payoutList, setPayoutList] = useState([]);

    useEffect(() => {
        dispatch({ type: 'SHOW_LOADER' });
        dispatch(getUserPayouts());
    }, [dispatch])

    // Selects data related to the loader action from the Redux store state.
    const isLoading = useSelector(state => state.loader.isLoading);
    // Selects data related to the userPayouts action from the Redux store state.
    const { data } = useSelector((state) => state.userPayouts.userPayouts)
    useEffect(() => {
        if (data && data.status) {
            dispatch({ type: 'HIDE_LOADER' });
            setPayoutList(data?.data?.list?.data);
        }
    }, [data, navigate, dispatch]);

    // impelement datatable from jquery
    useEffect(() => {
        if(payoutList && payoutList.length > 0){
            new DataTable('#myTable', {
                responsive: true,
                bDestroy: true
            });
        }
    }, [payoutList])

    // function to generate 
    const handlePayoutDetails = async () => {
        try {
            dispatch({ type: 'SHOW_LOADER' });
            await dispatch(payoutDetailsLink())
        } catch (error) {
            dispatch({ type: 'HIDE_LOADER' });
            console.error("Error generate payout details link:", error);
        }
    }

    // Selects data related to the payoutDetail action from the Redux store state.
    const { payout } = useSelector((state) => state.payoutDetail.payoutDetail)
    useEffect(() => {
        if (payout && payout.status) {
            dispatch({ type: 'HIDE_LOADER' });
            if(payout?.data?.url){
                window.location.href = payout?.data?.url;
            }
        }
    }, [payout, navigate, dispatch]);

    return (
        <>
            {/* Conditional rendering for the loader */}
            {isLoading && <div className="loader-overlay"><Spinner animation="border" role="status"> </Spinner></div>}
            <main className="set_gallery_bg">
                <section className="profile_elements pt_60 pb_60">
                    <div className="container">
                        <div className="profile_card" style={{ maxWidth: "none" }}>
                            <div className="d-flex justify-content-between profile_card_payout">
                                <h4 className="prof_title w-25">FINANCES</h4>
                                <div className="w-75 alert_update d-flex profile_card_payout">
                                    <div className="alert_custom alert alert-warning" >Please update your payout details here to ensure timely credit. Your earnings will be sent to the account linked in your payout details.</div>
                                    <Link  onClick={() => handlePayoutDetails()}>
                                        <button type="button" className="btn btn-purple btn-sm text-white"><FaPlus/>&nbsp;Financial account</button>
                                    </Link>
                                </div>
                            </div>
                            <div className="table_userDetails">
                                <table id="myTable" className="table table-striped " style={{ width: "100%" }}>
                                    {(payoutList?.length > 0) ? (
                                        <>
                                        <thead>
                                            <tr>
                                                <th>Commission</th>
                                                <th>Payment Date</th>
                                                <th>Received Date</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {payoutList?.map((data,index) => (
                                                <tr key={index}>
                                                    <td> {(data?.comission) ? '$'+(data?.comission) : '-'} </td>
                                                    <td> {(data?.created_at) ? moment(data?.created_at).format('DD-MMM-YYYY') : '-'}  </td>
                                                    <td> {(data?.date) ? moment(data?.date).format('DD-MMM-YYYY') : '-'}  </td>
                                                    <td>
                                                        {data?.status === 1 && ( <span className="text-success align-items-center d-flex gap-1"><FaCheckCircle/>Success</span> )}
                                                        {data?.status === 2 && ( <span className="text-danger align-items-center d-flex gap-1"><MdCancel/>Failed</span> )}
                                                        {(data?.status === 0 || data?.status === 3) && ( <span className="text-warning align-items-center d-flex gap-1"><MdOutlineWatchLater/>Pending</span> )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                        </>
                                    ): (
                                        <tbody>
                                            <tr>
                                                <td colSpan={4} align={'center'} >No payouts yet!</td>
                                            </tr>
                                        </tbody>
                                    )}
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}

export default UserPayouts;