/******************* Constants For Home ******************/
export const contactUsConstants = {
    GET_CONTACTUS_SUCCESS: 'GET_CONTACTUS_SUCCESS',
    GET_CONTACTUS_FAILURE: 'GET_CONTACTUS_FAILURE'
}

export const searchSequenceConstants = {
    GET_SEARCHSEQUENCE_SUCCESS: 'GET_SEARCHSEQUENCE_SUCCESS',
    GET_SEARCHSEQUENCE_FAILURE: 'GET_SEARCHSEQUENCE_FAILURE'
}

export const randomSequenceConstants = {
    GET_RANDOMSEQUENCE_SUCCESS: 'GET_RANDOMSEQUENCE_SUCCESS',
    GET_RANDOMSEQUENCE_FAILURE: 'GET_RANDOMSEQUENCE_FAILURE'
}

export const viewGalleryConstants = {
    GET_VIEWGALLERY_SUCCESS: 'GET_VIEWGALLERY_SUCCESS',
    GET_VIEWGALLERY_FAILURE: 'GET_VIEWGALLERY_FAILURE'
}

export const sequenceCheckoutConstants = {
    GET_SEQUENCECHECKOUT_SUCCESS: 'GET_SEQUENCECHECKOUT_SUCCESS',
    GET_SEQUENCECHECKOUT_FAILURE: 'GET_SEQUENCECHECKOUT_FAILURE'
}

export const imageCheckoutConstants = {
    GET_IMAGECHECKOUT_SUCCESS: 'GET_IMAGECHECKOUT_SUCCESS',
    GET_IMAGECHECKOUT_FAILURE: 'GET_IMAGECHECKOUT_FAILURE'
}

export const paymentRetrievConstants = {
    GET_PAYMENTRETRIEV_SUCCESS: 'GET_PAYMENTRETRIEV_SUCCESS',
    GET_PAYMENTRETRIEV_FAILURE: 'GET_PAYMENTRETRIEV_FAILURE'
}

export const imagesRetrievConstants = {
    GET_IMAGESRETRIEV_SUCCESS: 'GET_IMAGESRETRIEV_SUCCESS',
    GET_IMAGESRETRIEV_FAILURE: 'GET_IMAGESRETRIEV_FAILURE'
}

export const downloadImageConstants = {
    GET_DOWNLOADIMAGE_SUCCESS: 'GET_DOWNLOADIMAGE_SUCCESS',
    GET_DOWNLOADIMAGE_FAILURE: 'GET_DOWNLOADIMAGE_FAILURE'
}

export const clearHomeMessage = {
    CLEAR_HOME_MESSAGE: 'CLEAR_HOME_MESSAGE',
}

export const clearSearchSequence = {
    CLEAR_SEARCHSEQUENCE_MESSAGE: 'CLEAR_SEARCHSEQUENCE_MESSAGE',
}