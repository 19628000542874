import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import logo from '../../../assets/images/FMSPLogo2trans.png';
import instaLogo from '../../../assets/images/insta-logo.jpg';


const MainHeader = () => {
    
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    // const [isSticky, setIsSticky] = useState(false);
    const menuRef = useRef(null);

    useEffect(() =>{
        const handleScroll = () => {
        const scrollDown = window.scrollY;

        if (scrollDown < 200) {
            // setIsSticky(false);
        } else {
            // setIsSticky(true);
        }
        };
            window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    },[])
    
    useEffect(() => {
        // Function to handle clicks outside the menu
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        };
        // Function to handle clicks on any tab
        const handleTabClick = () => {
            setIsMenuOpen(false);
        };
        // Add event listeners for clicks outside the menu and on tabs
        document.addEventListener('click', handleClickOutside, true);
        document.addEventListener('click', handleTabClick, true);
        // Cleanup event listeners on component unmount
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('click', handleTabClick, true);
        };
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    return(
        <>
            <header className="header_elements">
                <div className="header__sticky">
                {/* <div className={`header__sticky ${isSticky ? 'header__sticky-sticky-menu' : ''}`}> */}
                    <div className="container-fluid containerHeader">
                        <div className="header_bar d-flex">
                            <div className="menu_layer d-flex">
                                <div className="logo_elements d-flex">
                                    <div className="logo_main">
                                        <Link to="/home" className="logo_text"><img src={logo} alt=""/></Link>
                                    </div>
                                    
                                </div>
                                <div className="search_items align-items-center d-flex justify-content-center mb-3">
                                    <div className="search_items_inner">
                                        <span className="searchGlass">
                                            <Link to="https://instagram.com/findmysurfpic" target="_blank" className="logo_text align-items-center">
                                                <img src={instaLogo} alt=""/>
                                                @findmysurfpic
                                            </Link>
                                        </span>
                                    </div>
                                </div>
                                <div className="menu_layer_menu d-flex">
                                    <div ref={menuRef} className={`menu_items ${isMenuOpen ? 'menu_items_release' : ''}`}> 
                                        <ul className="d-flex">
                                            {/* <li className="dropdown_elements">
                                                <Link href="#">Features</Link>
                                                <div className="dropdown_items">
                                                    <div className="btn_down">
                                                        <i className="fa-solid fa-chevron-down"></i>
                                                    </div>
                                                    <div className="dropdown_items_inner">
                                                        <ul>
                                                            <li>Item 1</li>
                                                            <li>Item 1</li>
                                                            <li>Item 1</li>
                                                            <li>Item 1</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li> */}
                                            <li><Link to="/home">Home</Link></li>
                                            <li><Link to="/about-us">About</Link></li>
                                            <li><Link to="/works">How it Works</Link></li>
                                            <li><Link to="/login" >Sign In</Link></li>
                                            <li><Link to="/signup">Sign Up</Link></li>
                                            <li><Link to="https://instagram.com/findmysurfpic" target="_blank" className="instaLogo">
                                                <img src={instaLogo} alt=""/>
                                                @findmysurfpic
                                            </Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="search_elements d-flex">
                                <div className="mobile-nav" onClick={toggleMenu}>
                                    <div className="bar1"></div>
                                    <div className="bar2"></div>
                                    <div className="bar3"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default MainHeader;