import React, { useEffect, useState } from "react";
import img3 from '../../../assets/images/3.jpg';
import img2 from '../../../assets/images/2.jpg';
import { useDispatch } from "react-redux";
import axios from "axios";

const HowIsWorks = () => {

    const dispatch = useDispatch();
    const [price, setPrice] = useState("");
    useEffect(() => {
        // Define the async function to fetch data
        const fetchData = async () => {
            try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL+'api/price');
                if (response && response?.data && response?.data?.status) {
                    setPrice(response?.data?.data?.list);
                }
            } catch (error) {
                console.log(error);
            }
        };
        // Call the fetchData function
        fetchData();
        dispatch({ type: 'CLEAR_SEARCHSEQUENCE_MESSAGE' });
    },[dispatch])

    return(
        <>
            <main className="set_gallery_bg">
                <section className="sec_way search_section">
                    <div className="container">
                        <h3 className="text-center pb_50">How it works!</h3>
                        <div className="row set_howWork">
                            <div className="col-md-6">
                                <div className="image_surf">
                                    <img src={img3} alt="" className="img-fluid"/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="sub_details">
                                    <h6>How it works - <span style={{color: "#10209e"}}> Photographer</span></h6>
                                        <ol>
                                            <li>1. Create a profile to set up an account. (Username cannot be a full email address)</li>
                                            <li>2. Upload your image by choosing Continent, Region, Area, Spot, and Date.</li>
                                            <li>3. Add your financial account details to setup your Stripe Account(one time setup)</li>
                                            <li>4. Each photograph sold will earn you {(price?.commission_image) ? '$'+price?.commission_image : '$10' }. This will be processed by Stripe and deposited to your account.</li>
                                            <li>5. Each image purchased, Find My Surf Pic will make a donation to saving our ocean's!</li>
                                            <li>6. These photographs are for personal use only by the purchaser.</li>
                                            <li>7. If you do not see your surf spot/location, please contact us and we will add it!</li>
                                        </ol>
                                    
                                    <h6>How it works - <span style={{color: "#10209e"}}> Purchaser</span></h6>
                                        <ol>
                                            <li>1. Search for your picture by Continent, Region, Area, Spot, and Date.</li>
                                            <li>2. Select images to purchase and add to cart.</li>
                                            <li>3. Check out and pay by credit card.</li>
                                            <li>4. Download high resolution image to your device immediately or follow the link that will be emailed to you.  The image can only be downloaded once. (check your spam folder if the not seen in your inbox)</li>
                                            <li>5. Each image purchased, Find My Surf Pic will make a donation to saving our ocean's!</li>
                                            <li>6. These photographs are for personal use only.</li>
                                            <li>7. If you do not see your surf spot/location, please contact us and we will add it!</li>
                                        </ol>

                                        <p>The license for the purchased photo(s) are for PERSONAL use only. Personal use means it can be used in a non commercial 
                                            way for use on personal social media accounts, personal websites, and/or making image prints for personal use only.  
                                            The photographs purchased cannot be resold in any manner or receive any form of compensation.  
                                            The photographs cannot be resold, relicensed, sublicensed or used for any advertising.   
                                            The photograph ownership and rights now and in the future remain exclusively with the content provider.  
                                            Find My Photo LLC nor the photographer will be liable for any third party claims or incidental, 
                                            consequential or other damages arising out of the license or buyer’s use of the photograph. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="sec_waves">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <h4 className="waves_title">Bringing Surfer's and Photographer's Together to Share Those Epic Moments!</h4>
                            </div>
                            <div className="waves_bgImg">
                                <img src={img2} alt=""/>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}

export default HowIsWorks;