import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { clearAdminMsg, viewSequence } from "../../../actions/adminAction";
import { FaTrashAlt, FaChevronLeft, FaPencilAlt, FaEye} from "react-icons/fa";
import { Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import defaultImg from './../../../assets/images/defalut.jpg'
import { deleteImage } from "../../../actions/sequenceAction";
import { toast } from "react-toastify";
// import { previewImgage } from "../../common/utils";
import { PiImageLight } from "react-icons/pi";
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

const ViewSequence = () =>{
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id }   = useParams();

    const [sequenceDetails, setSequenceDetails] = useState({});
    
    // useEffect for call Api's 
    useEffect(() => {
        dispatch({ type: 'SHOW_LOADER' }); // Set loader to true
        dispatch(viewSequence(id));
    },[dispatch, id])

    // Selects data related to the loader action from the Redux store state.
    const isLoading = useSelector(state => state.loader.isLoading);
    // Selects data related to the viewSequence action from the Redux store state.
    const { sequenceData } = useSelector((state) => state.viewSequence.viewSequence)
    useEffect(() => {
        if (sequenceData && sequenceData?.status) {
            dispatch({ type: 'HIDE_LOADER' });
            setSequenceDetails(sequenceData?.data?.list);
        }
    }, [sequenceData, dispatch]);

    // // Confirm the status with a confirmation dialog
    // const handelStatus = (imageId, status) =>{
    //     Swal.fire({
    //         title: "Are you sure?",
    //         text: status === 0 ? "You want to inactive this image" : "You want active this image",
    //         icon: "warning",
    //         showCancelButton: true,
    //         confirmButtonColor: "#3085d6",
    //         cancelButtonColor: "#d33",
    //         confirmButtonText: status === 0 ? "Inactive!" : "Active!",
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             dispatch({ type: 'SHOW_LOADER' }); // Set loader to true
    //             dispatch(imageStatus({
    //                 image_id : imageId,
    //                 status : status
    //             }));
    //         }
    //     });
    // }

    // Confirm the deletion with a confirmation dialog
    const handelImageDelete = (imageId) =>{
        Swal.fire({
            title: "Are you sure?",
            text: "You want to 'DELETE' this image",
            icon: "error",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: 'Confirm',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch({ type: 'SHOW_LOADER' });
                dispatch(deleteImage({
                    id : imageId,
                }));
            }
        });
    }

    // Selects data related to the viewUserSequence action from the Redux store state.
    const { deleteResponse } = useSelector((state) => state.deleteImage.deleteImage)
    useEffect(()=>{
        if(deleteResponse && deleteResponse?.status){
            dispatch({ type: 'HIDE_LOADER' });
            toast.success(deleteResponse?.message[0],{theme: "dark",});
            dispatch(clearAdminMsg());
            dispatch(viewSequence(id));
        }
    },[deleteResponse,dispatch,id])

    // Selects data related to the imageStatus action from the Redux store state.
    const  { response }  = useSelector((state) => state.imageStatus.imageStatus)
    useEffect(() => {
        if (response && response?.status) {
            toast.success(response?.message[0],{theme: "dark",});
            dispatch(clearAdminMsg());
            dispatch(viewSequence(id));
        }
    }, [response, dispatch, id]);

    return(
        <>  
            {/* Conditional rendering for the loader */}
            {isLoading && <div className="loader-overlay"><Spinner animation="border" role="status"> </Spinner></div>}
            <main className="set_gallery_bg">
                <section className="search_section">
                    <div className="container">
                        <div className="find_data form_gallery_data">
                            <span className="goBackbtn" title="go back" onClick={() => navigate(-1)}><FaChevronLeft /></span>
                            <div className="oneditor_outer d-flex">
                                <div className="onEditor d-flex">
                                    <div className="form_profile">
                                        <img src={(sequenceDetails?.user?.picture) ? (sequenceDetails?.user?.picture) : 'defaultProfile'} className="form_profile_icon" alt="" />
                                    </div>
                                    <h3 className="gallery_innerTitle" style={{ fontFamily: "system-ui"}}>
                                        {(sequenceDetails?.user) ? (sequenceDetails?.user?.full_name.charAt(0).toUpperCase() + sequenceDetails?.user?.full_name.slice(1))+','  : ''} Gallery
                                    </h3>
                                </div>
                                <Link to={`/admin/user/update/${sequenceDetails?.user?.id}`} title="Edit User" className="btn_edit editor_btn" style={{ backgroundColor : '#ffd232' }}>
                                    <FaPencilAlt />
                                </Link>
                            </div>
                            <div className="image_wrapField ">
                                <h6 className="image_galleryTiitle mb_40" style={{ fontFamily: "fantasy" }}> {sequenceDetails ? ( <>{sequenceDetails?.area}, {sequenceDetails?.spot}, {sequenceDetails?.region} Picture Colloction </>) : ''}</h6>
                                <div className="item_log_pic">
                                    <ul className="item_searchvalue">
                                    <PhotoProvider>
                                    {(sequenceDetails?.images?.length > 0) ? (
                                        sequenceDetails?.images?.map((data, index) => (
                                            <li key={index} className="item_flexInner">
                                                <div className={`desk_card_outer ${data?.deleted_at ? 'del_user' : ''}`}>
                                                    {(data?.deleted_at) ? <p className="del_usertitle">Deleted by user</p> : ''}
                                                    {/* <PhotoView key={index} src={(data.watermark) ? data.watermark : defaultImg}> */}
                                                        <img src={(data?.watermark) ? data?.watermark : defaultImg} alt="" className="card_images_admin img-fluid"/>
                                                    {/* </PhotoView> */}
                                                    <div className="img_desk_card">
                                                        {/* <p>New York picture collection</p> */}
                                                        <div className="public_items d-flex">
                                                            {/* <div className="cart_eyeElements">
                                                                <Link to="" className="btn_edit_icons" onClick={() => handelStatus(data.id, data.status === 0 ? 1 : 0)}>
                                                                    <div className="btn_edit_block d-flex">
                                                                        {(data.status === 1)  ?
                                                                            <>
                                                                                <div className="icon_outer">
                                                                                    <FaLockOpen size={18} />
                                                                                </div>
                                                                                <span className="">Inactive</span>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <div className="icon_outer">
                                                                                    <FaLock size={18}/>
                                                                                </div>
                                                                                <span className="">Ative</span>
                                                                            </>
                                                                        }
                                                                        <div className="">
                                                                            <FaChevronLeft/>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div> */}
                                                            <div className="public_trash d-flex float-left">
                                                                <PhotoView key={index} src={(data.watermark) ? data.watermark : defaultImg}>
                                                                    <Link title="View" className="btn_edit editor_btn" 
                                                                        // onClick={() => previewImgage(data?.watermark)}
                                                                    >
                                                                        <FaEye size={20}/>
                                                                    </Link>
                                                                </PhotoView>

                                                                <Link onClick={()=>handelImageDelete(data.id)} title="Delete" className="btn_edit trash_btn">
                                                                    <FaTrashAlt />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))
                                    ): (
                                        <div className="d-flex no_record">
                                            <PiImageLight size={100}/>
                                            <span align={'center'} style={{fontWeight:'700',padding:'1em'}}> 
                                                No Record Found. 
                                            </span>
                                        </div>
                                    )}
                                    </PhotoProvider>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}
export default ViewSequence;