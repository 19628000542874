import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Footer = () =>{

    const [myData, setMyData] = useState(null);
    // Selects data related to the myData action from the Redux store state.
    const { my } = useSelector((state) => state.myData.myData);
    const currentYear = new Date().getFullYear();
    useEffect(() => {
        if (my && my.status)
            setMyData(my?.data);
    }, [my]);
    return (
        <>
            <footer className="footer_elements">
                <ul className="d-flex">
                    {(myData?.role === 1) ?
                    <>
                        <li><Link to="/admin/about-us">About</Link></li>
                        <li><Link to="/admin/contact-us">Contact Us</Link></li>
                    </>
                    :
                    <>
                        <li><Link to="/user/about-us">About</Link></li>
                        <li><Link to="/user/contact-us">Contact Us</Link></li>
                    </>
                    }
                </ul>

                <p className="copright_note">© Copyright {currentYear} - Find My Photo LLC</p>
            </footer>
        </>
    );
}

export default Footer;