import React, { useEffect } from 'react'
import MainHeader from './mainHeader';
import MainFooter from './mainFooter';
import { Outlet } from 'react-router-dom';

const MainSection = () => {

    useEffect(() => {
        document.querySelectorAll('[data-background]').forEach(element => {
            const backgroundImage = element.getAttribute('data-background');
            if (backgroundImage) {
                element.style.backgroundImage = `url(${backgroundImage})`;
            }
        });
    }, []);

    return (
        <>
            <MainHeader />
                <Outlet/>
            <MainFooter />
        </>
    )
}

export default MainSection;