/******************* Constants For User ******************/
export const userDashboardConstants = {
    GET_USERDASHBOARD_SUCCESS: 'GET_USERDASHBOARD_SUCCESS',
    GET_USERDASHBOARD_FAILURE: 'GET_USERDASHBOARD_FAILURE'
}

export const viewProfileConstants = {
    GET_VIEWPROFILE_SUCCESS: 'GET_VIEWPROFILE_SUCCESS',
    GET_VIEWPROFILE_FAILURE: 'GET_VIEWPROFILE_FAILURE'
}

export const profileUpdateConstants = {
    GET_PROFILEUPDATE_SUCCESS: 'GET_PROFILEUPDATE_SUCCESS',
    GET_PROFILEUPDATE_FAILURE: 'GET_PROFILEUPDATE_FAILURE'
}

export const getUserPayoutsConstants = {
    GET_USERPAYOUTS_SUCCESS: 'GET_USERPAYOUTS_SUCCESS',
    GET_USERPAYOUTS_FAILURE: 'GET_USERPAYOUTS_FAILURE'
}

export const payoutDetailsLinkConstants = {
    GET_PAYOUTDETAILS_SUCCESS: 'GET_PAYOUTDETAILS_SUCCESS',
    GET_PAYOUTDETAILS_FAILURE: 'GET_PAYOUTDETAILS_FAILURE'
}

export const clearUserMessage = {
    CLEAR_USER_MESSAGE: 'CLEAR_USER_MESSAGE'
}