import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearAdminMsg, getSequenceByUser, getSequenceList, updateSequenceApprove } from "../../../actions/adminAction";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import defaultImg from './../../../assets/images/defalut.jpg'
import { FaEdit, FaEye, FaChevronDown, FaTrashAlt, FaLock, FaLockOpen, FaChevronLeft} from "react-icons/fa";
import { deleteSequence } from "../../../actions/sequenceAction";
import { Spinner } from "react-bootstrap";
import { PiImageLight } from "react-icons/pi";

const SequenceByUser = () => {

    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [sequenceList, setSequenceList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const currentPageRef = useRef(currentPage);

    useEffect(() => {
        dispatch({ type: 'SHOW_LOADER' });
        dispatch(getSequenceByUser(id,currentPage));
        // Update the ref whenever currentPage changes
        currentPageRef.current = currentPage;
    }, [dispatch, id, currentPage])

    // Selects data related to the loader action from the Redux store state.
    const isLoading = useSelector(state => state.loader.isLoading);
    // Selects data related to the sequenceList action from the Redux store state.
    const { data } = useSelector((state) => state.sequenceByUser.sequenceByUser)
    useEffect(() => {
        if (data && data.status) {
            dispatch({ type: 'HIDE_LOADER' });
            setSequenceList(prevSequenceList => {
                const updatedList = currentPageRef.current === 1 ? data?.data?.list?.data : [...prevSequenceList, ...data?.data?.list?.data];
                return updatedList;
            });
            setTotal(data?.data?.list?.total);
        }
    }, [data, navigate, dispatch]);

    const handelSequenceApprove = (sequenceId, approve) => {
        Swal.fire({
            title: "Are you sure?",
            text: approve === 1 ? "You want to approve that sequence" : "You want reject that sequence",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: approve === 1 ? "Approve!" : "Reject!",
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch({ type: 'SHOW_LOADER' });
                dispatch(updateSequenceApprove({
                    sequence_id : sequenceId,
                    approve : approve
                }));
            }
        });
    }

    // Confirm the deletion with a confirmation dialog
    const handelSequenceDelete = (sequenceId) =>{
        Swal.fire({
            title: "Are you sure?",
            text: "You want to 'DELETE' this sequence",
            icon: "error",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: 'Confirm',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch({ type: 'SHOW_LOADER' });
                dispatch(deleteSequence({
                    id : sequenceId,
                }));
            }
        });
    }

    // Selects data related to the deleteSequence action from the Redux store state.
    const  { deleteResponse }  = useSelector((state) => state.deleteSequence.deleteSequence)
    useEffect(() => {
        if (deleteResponse && deleteResponse?.status) {
            toast.success(deleteResponse?.message[0],{theme: "dark",});
            dispatch({ type: 'HIDE_LOADER' });
            dispatch(clearAdminMsg());
            dispatch(getSequenceList());
        }
    }, [deleteResponse, navigate, dispatch]);

    // Selects data related to the sequenceApprove action from the Redux store state.
    const response = useSelector((state) => state.sequenceApprove.sequenceApprove)
    useEffect(() => {
        if (response.data && response?.data?.status) {
            toast.success(response?.data?.message[0],{theme: "dark",});
            dispatch(clearAdminMsg());
            dispatch(getSequenceList());
        }
    }, [response, navigate, dispatch]);

    // Handle "Load More" button click
    const handleLoadMore = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    return (
        <>
            {/* Conditional rendering for the loader */}
            {isLoading && <div className="loader-overlay"><Spinner animation="border" role="status"> </Spinner></div>}
            <main className="set_gallery_bg">
                <section className="search_section">
                    <div className="container">
                        <div className="find_data form_gallery_data">
                           
                            {/* <h3>Kristie charley</h3>
                            <div className="oneditor_outer d-flex">
                                <div className="onEditor">
                                    <p> </p>
                                </div>
                                <Link to="" className="btn_edit editor_btn">
                                    <Link to={`/admin/user/update/${id}`} title="Edit User"> <FaPencilAlt /> </Link>
                                </Link>
                            </div> */}

                            <div className="image_wrapField">
                                <span className="goBackbtn" title="go back" onClick={() => navigate(-1)}><FaChevronLeft /></span>
                                <h6 className="image_galleryTiitle mb_24" style={{ fontFamily : 'fantasy'}}>User Image's</h6>
                                <div className="item_log_pic">
                                    <ul className="item_searchvalue">
                                    {(sequenceList?.length > 0) ? (
                                        sequenceList?.map((data, index) => (
                                            <li key={index} className="item_flexInner_card">
                                                <div className="deskDettails_card">
                                                    <img src={(data?.sequence_image) ? data?.sequence_image : defaultImg} alt="" className="card_images_admin img-fluid"/>
                                                    <div className="img_deskInner_card">
                                                        <p>{data?.name ? data.name.charAt(0).toUpperCase() + data.name.slice(1) : ''}</p>
                                                    </div>
                                                    <div className="btn_elements_gallery d-flex">
                                                        <Link to={`/admin/sequence/update/${data.id}`} className="btn_edit editor_btn">
                                                            <FaEdit />
                                                        </Link>
                                                        <Link to={`/admin/sequence/view/${data.id}`} className="btn_edit heart_btn">
                                                            <FaEye size={20} />
                                                        </Link>
                                                        <Link className="btn_edit eye_btn">
                                                            <div className="btn_onhover d-flex" onClick={() => handelSequenceApprove(data.id, data.is_approved === 1 ? 2 : 1)}>
                                                                {(data.is_approved === 1) ?
                                                                    <>
                                                                        <div className="icon_outer">
                                                                            <FaLockOpen size={18} />
                                                                        </div>
                                                                        <span className="title_valueEye">Approve</span>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div className="icon_outer">
                                                                            <FaLock size={18} />
                                                                        </div>
                                                                        <span className="title_valueEye">Reject</span>
                                                                    </>
                                                                }
                                                                <div className="icon_outer_drop">
                                                                    <FaChevronDown />
                                                                </div>
                                                            </div>
                                                        </Link>
                                                        <Link onClick={() => handelSequenceDelete(data.id)} className="btn_edit trash_btn">
                                                            <FaTrashAlt />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </li>
                                        ))
                                    ): (
                                        <div className="d-flex no_record">
                                            <PiImageLight size={100}/>
                                            <span align={'center'} style={{fontWeight:'700',padding:'1em'}}> 
                                                No Record Found. 
                                            </span>
                                        </div>
                                    )}
                                    </ul>
                                    {sequenceList?.length < total && (
                                        <div className="loadMore">
                                            <Link onClick={handleLoadMore} className="loadMore_action">Load More</Link>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}

export default SequenceByUser;