import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { randomSequenceAction } from "../../actions/homeAction";
import { PiImageLight } from "react-icons/pi";
import defaultImg from "./../../assets/images/defalut.jpg";
import moment from "moment";
import { BsCalendar2DateFill } from "react-icons/bs";
import { FaCartPlus, FaEye, FaLocationArrow } from "react-icons/fa";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import { Link } from "react-router-dom";
import { TbBoxMultiple } from "react-icons/tb";
// import { previewImgage } from "../common/utils";
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

const breakpoints = {
    0: {
        slidesPerView: 1,
    },
    400: {
        slidesPerView: 1,
    },
    500: {
        slidesPerView: 2,
    },
    600: {
        slidesPerView: 2,
    },
    700: {
        slidesPerView: 2,
    },
    900: {
        slidesPerView: 3,
    },
    1000: {
        slidesPerView: 3,
    },
    1200: {
        slidesPerView: 4,
    }
};

const RandomSequence = () => {
    const dispatch = useDispatch();

    const [sequenceList , setSequenceList] = useState([]);

    useEffect(() => {
        dispatch({ type: 'SHOW_LOADER' }); // Set loader to true
        dispatch(randomSequenceAction())
    },[dispatch])

    // Selects data related to the randomSequence action from the Redux store state.
    const { data } = useSelector((state) => state.randomSequence.randomSequence)
    useEffect(() => {    
        if (data && data.status) {
            dispatch({ type: 'HIDE_LOADER' });
            setSequenceList(data?.data?.list);
        }
    }, [data,dispatch]);


    return (
        <>
            <section className="Destinations_sec">
                <div className="container">
                    <div className="dest_inner">
                        <h3>Choose the Desired Location</h3>
                        <div className="dest_innerDet d-flex">
                            <PhotoProvider>
                            <Swiper className="swiper mySwiper"
                                spaceBetween={20}
                                slidesPerView={4}
                                navigation
                                loop={true}
                                scrollbar={{ draggable: true }}
                                // onSlideChange={() => console.log('slide change')}
                                // onSwiper={(swiper) => console.log(swiper)}
                                breakpoints={breakpoints}
                            >
                            {(sequenceList?.length > 0) ? (
                                sequenceList?.map((data, index) => (
                                    <SwiperSlide key={index}>
                                        <article className="artical_List">
                                            <div  className="desk_list__img">
                                                {/* <PhotoView key={index} src={(data.sequence_image) ? data.sequence_image : defaultImg}> */}
                                                    <img src={(data.sequence_image) ? data.sequence_image : defaultImg} alt=""/>
                                                {/* </PhotoView> */}
                                                <div className="multiple">
                                                   {(data?.type !== 1) ? <TbBoxMultiple size={25} color="white"/> : ''} 
                                                </div>
                                                <span className="icon_plus set_cardIcon">
                                                    {(data?.type !== 1) ?
                                                        <>
                                                            <Link to={`/sequence-payment-checkout/${data?.sequence_key}`} title="Payment"><FaCartPlus className="fab_color" size={20}/></Link>
                                                            <Link to={`/sequence-gallery/${data?.sequence_key}`} title="View"><FaEye className="fab_color" size={20}/></Link>
                                                        </>
                                                    :
                                                        <>
                                                            <Link to={`/image-payment-checkout/${data?.sequence_key}/${data?.id}`} title="Payment"><FaCartPlus className="fab_color" size={22}/></Link>
                                                            <PhotoView key={index} src={(data.sequence_image) ? data.sequence_image : defaultImg}>
                                                                <Link title="View" 
                                                                    // onClick={() => previewImgage(data?.sequence_image)}
                                                                ><FaEye className="fab_color" size={25}/></Link>
                                                            </PhotoView>
                                                        </>
                                                    }
                                                </span>
                                            </div>
                                            
                                            <div className="desk_list_subline">
                                                {/* <h5>{(data.name) ? data.name : "-"}</h5>
                                                <p className="sub_disc">{(data.detail) ? data.detail : "-"}</p> */}
                                                <div className="card_details">
                                                    <div className="author_pubDate ">
                                                        <span className="fab_color me-2"><BsCalendar2DateFill /></span>
                                                        <span>Published :</span> {(data?.date) ? moment(data?.date).format('MMM DD,YYYY') : '-'}
                                                    </div>
                                                    {/* <div className="author_pubDate ">
                                                        <span>Date:</span>
                                                    </div> */}
                                                    <div className="author_pubDate ">
                                                        <span className="fab_color me-2"><FaLocationArrow /></span>
                                                        {(data.area) ? (data.area)+',' : ''} {(data.spot) ? (data.spot) : ''}
                                                    </div>
                                                    {/* <div className="author_pubDate ">
                                                        {(data.area) ? (data.area)+',' : ''} {(data.spot) ? (data.spot) : ''}
                                                    </div> */}
                                                </div>
                                            </div>
                                        </article>
                                    </SwiperSlide>
                                ))
                            ): (
                                <div className="d-flex no_record">
                                    <PiImageLight size={100}/>
                                    <span align={'center'} style={{fontWeight:'700',padding:'1em'}}> 
                                        No Sequence Found. 
                                    </span>
                                </div>
                            )}
                            </Swiper>
                            </PhotoProvider>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default RandomSequence;