import React from "react";
import { Link } from "react-router-dom";

const MainFooter = () => {
    const currentYear = new Date().getFullYear();
    return (
        <>
            <footer className="footer_elements">
                <ul className="d-flex">
                    <li><Link to="/home">Find pictures</Link></li>
                    <li><Link to="/about-us">About</Link></li>
                    <li><Link to="/contact-us">Contact Us</Link></li>
                </ul>

                <p className="copright_note">© Copyright {currentYear} - Find My Photo LLC</p>
            </footer>
        </>
    );
}

export default MainFooter;