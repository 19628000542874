import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { clearAdminMsg, editRegion, updateRegion } from "../../../../actions/adminAction";
import { toast } from "react-toastify";
import Select from "react-select";
import { getAllLocation } from "../../../../actions/commonAction";
import { Spinner } from "react-bootstrap";

const UpdateRegion = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [error, setError] = useState(false);
    const [regionName, setRegionName] = useState();
    const [selectedContinent, setSelectedContinent] = useState("");
    const [allContinents, setAllContinents] = useState([]);
    useEffect(()=>{
        dispatch({ type: 'SHOW_LOADER' }); // Set loader to true
        dispatch(editRegion({ id : id}))
        dispatch(getAllLocation())
    },[dispatch,id])

    // Selects data related to the loader action from the Redux store state.
    const isLoading = useSelector(state => state.loader.isLoading);
    // Selects data related to the createSequence action from the Redux store state.
    const { allLocation } = useSelector((state) => state.getAllLocation.getAllLocation)
    useEffect(() => {    
        if (allLocation && allLocation.status) {
            dispatch({ type: 'HIDE_LOADER' });
            setAllContinents(allLocation?.data?.continents);
        }
    }, [allLocation,dispatch]);

    // Selects data related to the editRegion action from the Redux store state.
    const  { regionData }  = useSelector((state) => state.editRegion.editRegion)
    useEffect(() => {
        if (regionData && regionData?.status) {
            dispatch({ type: 'HIDE_LOADER' });
            setRegionName(regionData?.data?.list?.name)
            setSelectedContinent(regionData?.data?.list?.continent_id)
        }
    }, [regionData, navigate, dispatch]);

    const handleFormUpdate = async (e) =>{
        e.preventDefault();
        if(!regionName){
            dispatch({ type: 'HIDE_LOADER' });
            setError(true);
            return false;
        }else{
            try {
                dispatch({ type: 'SHOW_LOADER' });
                await dispatch(updateRegion({
                    id   : id,
                    name : regionName,
                    continent_id : selectedContinent
                }))
            } catch (error) {
                dispatch({ type: 'HIDE_LOADER' });
                console.error("Error updating profile:", error);
            }
        }
    }

    // Selects data related to the updateContitent action from the Redux store state.
    const  { data }  = useSelector((state) => state.updateRegion.updateRegion)
    useEffect(() => {
        if (data && data?.status) {
            dispatch({ type: 'HIDE_LOADER' });
            toast.success(data?.message[0],{theme: "dark",});
            dispatch(clearAdminMsg())
            navigate("/admin/regions");
        }
    }, [data, navigate, dispatch]);

    return(
        <>
            {/* Conditional rendering for the loader */}
            {isLoading && <div className="loader-overlay"><Spinner animation="border" role="status"> </Spinner></div>}
            <main className="set_gallery_bg">
                <section className="profile_elements pt_60 pb_60">
                    <div className="container">
                        <div className="profile_card">
                            <h4 className="prof_title">UPDATE REGION</h4>
                            <form onSubmit={handleFormUpdate} onKeyDown={(e) => { if (e.key === 'Enter') e.preventDefault()}}>
                                <div className="d-flex prof_from mt-2">
                                    <fieldset className="form_fields_prof">
                                        <label htmlFor="">Continent<span className="text-danger">*</span></label>
                                        <Select
                                            className="select"
                                            isClearable
                                            options={allContinents?.map((data) => {
                                                return {
                                                    label: data.name,
                                                    value: data.id,
                                                };
                                            })}
                                            value={[allContinents?.map((data) => {
                                                return {
                                                    label: data.name,
                                                    value: data.id,
                                                };
                                            }).find(option => option.value === selectedContinent)]}
                                            // value={[findById(createOptionsArray(allContinents, 'name', 'id'), selectedContinent)]}
                                            onChange={(e) => setSelectedContinent(e.value)}
                                            placeholder="Select Continents"
                                        />
                                        {error && !selectedContinent && (
                                            <span className="requiredInput" >
                                                Required*
                                            </span>
                                        )}
                                    </fieldset>
                                    <fieldset className="form_fields_prof">
                                        <label htmlFor="">Region Name<span className="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            value={regionName || ''}
                                            onChange={(e) => setRegionName(e.target.value)}
                                            name="name"
                                            placeholder="Enter Region Name"
                                            maxLength="50"
                                        />
                                        {error && !regionName && (
                                            <span className="requiredInput" >
                                                Required*
                                            </span>
                                        )}
                                    </fieldset>
                                </div>
                                <div className="upload_btn justify-content-center">
                                    <button type="submit" className="loadMore_action">Update</button>
                                </div>
                            </form>
                        </div>

                    </div>
                </section>
            </main>
        </>
    );
}

export default UpdateRegion;