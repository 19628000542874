import { getAllLocationConstants, getAreaSpotsConstants, getContinentRegionsConstants, getRegionAreasConstants } from "../constants/common.constants";

/************************** Common Reducer *******************************/
export const loaderReducer = (state =  { isLoading: false }, action) => {
    switch (action.type) {
      case 'SHOW_LOADER':
        return { ...state, isLoading: true };
      case 'HIDE_LOADER':
        return { ...state, isLoading: false };
      default:
        return state;
    }
};

export const getAllLocationReducer = (state = { getAllLocation : [] }, action) => {
    switch (action.type) {
        case getAllLocationConstants.GET_GETALLLOCATION_SUCCESS:
            return { getAllLocation: action.payload };
        case getAllLocationConstants.GET_GETALLLOCATION_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
};

export const getContinentRegionsReducer = (state = { getContinentRegions : [] }, action) => {
    switch (action.type) {
        case getContinentRegionsConstants.GET_GETCONTINENTREGIONS_SUCCESS:
            return { getContinentRegions: action.payload };
        case getContinentRegionsConstants.GET_GETCONTINENTREGIONS_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
};

export const getRegionAreasReducer = (state = { getRegionAreas : [] }, action) => {
    switch (action.type) {
        case getRegionAreasConstants.GET_GETREGIONAREAS_SUCCESS:
            return { getRegionAreas: action.payload };
        case getRegionAreasConstants.GET_GETREGIONAREAS_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
};

export const getAreaSpotsReducer = (state = { getAreaSpots : [] }, action) => {
    switch (action.type) {
        case getAreaSpotsConstants.GET_GETAREASPOTS_SUCCESS:
            return { getAreaSpots: action.payload };
        case getAreaSpotsConstants.GET_GETAREASPOTS_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
};