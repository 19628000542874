// Title.js
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const Title = ({ title, children }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return <>{children}</>;
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Title;