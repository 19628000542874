import React from "react";
import about2 from '../../../assets/images/bg-way-img.jpeg';

const TermsAndConditions = () => {

    return(
        <>
            <main className="set_gallery_bg">
                <section className="sec_way search_section">
                    <div className="container">
                        <h3 className="text-center pb_50">Terms And Conditions</h3>
                        <div className="row justify-content-center">
                            <div className="col-md-10">
                                <div className="sub_details">
                                    <p>The Photographer who is the media content provider allows the buyer a non-exclusive perpetual person use license to download and copy the images purchased with the following restrictions..</p>
                                    <p>The license for the purchased photo(s) are for PERSONAL use only.  Personal use means it can be used in a non commercial way for use on personal social media accounts, personal websites, and/or making image prints for personal use only.  The photographs purchased cannot be resold in any manner or receive any form of compensation.  The photographs cannot be resold, relicensed, sublicensed or used for any advertising.   The photograph ownership and rights now and in the future remain exclusively with the content provider.  Find My Photo LLC nor the photographer will be liable for any third party claims or incidental, consequential or other damages arising out of the license or buyer’s use of the photograph.</p>
                                    <p>By creating a profile and uploading your photographs, you give Findmysurfpic.com/Find My Photo LLC the rights to post your photos for social media use such as Instagram, Facebook, Tiktok...
                                        All images will be watermarked by Findmysurfpic.com.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="sec_waves">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <h2 className="waves_title">CAPTURE THOSE EPIC MEMORIES SO THEY CAN LIVE ON FOREVER...</h2>
                            </div>
                            <div className="waves_bgImg">
                                <img src={about2} alt=""/>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}

export default TermsAndConditions;