import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserList } from "../../../actions/adminAction";
import { Link, useNavigate } from "react-router-dom";
import { clearAuthMsg, resendEmailVerification } from "../../../actions/authAction";
import Swal from "sweetalert2";
import { GiCancel } from "react-icons/gi";
import { toast } from "react-toastify";
import Modal from "react-modal";
import ChangePasswordByAdmin from "../../auth/password/changePaasswordByAdmin";
import defaultProfile from "../../../assets/images/default-profile.png"
import { FaEdit, FaEye, FaLock } from "react-icons/fa";
import { MdOutgoingMail, MdDomainVerification } from "react-icons/md";
import { Spinner } from "react-bootstrap";
import DataTable from 'datatables.net-dt';

const UserList = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [userId, setUserId] = useState('');
    const passwordProps =  {"from" : "passwordProps","id" : userId}
    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
          right: "auto",
          bottom: "auto",
          padding: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
        },
    };
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [usersList, setUsersList] = useState([]);

    useEffect(() => {
        dispatch({ type: 'SHOW_LOADER' });
        dispatch(getUserList());
    }, [dispatch])

    // Selects data related to the loader action from the Redux store state.
    const isLoading = useSelector(state => state.loader.isLoading);
    // Selects data related to the userList action from the Redux store state.
    const { data } = useSelector((state) => state.userList.userList)
    useEffect(() => {
        if (data && data.status) {
            dispatch({ type: 'HIDE_LOADER' });
            setUsersList(data?.data);
        }
    }, [data, navigate, dispatch]);


    // function for open or close modal
    const modalFunnction =(type, id)=>{
        if(type === 'open') {
            setUserId(id);
            setModalIsOpen(true);
        }else {
            setModalIsOpen(false);
        }
        dispatch(clearAuthMsg());
    }

    // create password return from props
    const handleReturnedPasswordValue = () => {
        setModalIsOpen(false); // Close the popup
    };

    const handelVerificationEmail = (userId) => {
        Swal.fire({
            title: "Are you sure?",
            text:  "you want to resend verification email to this user",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Send",
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch({ type: 'SHOW_LOADER' });
                dispatch(resendEmailVerification(userId));
            }
        });
    }

    // Selects data related to the resendVerification action from the Redux store state.
    const { resendVerification } = useSelector((state) => state.resendVerification)
    useEffect(() => {
        if (resendVerification?.data && resendVerification?.data?.status) {
            dispatch({ type: 'HIDE_LOADER' });
            toast.success(resendVerification?.data?.message[0],{theme: "dark",});
            dispatch(clearAuthMsg());
        }
    }, [resendVerification, navigate, dispatch]);

    // impelement datatable from jquery
    useEffect(() => {
        if(usersList && usersList.length > 0){
            new DataTable('#myTable', {
                responsive: true,
                bDestroy: true
            });
        }
    }, [usersList])

    return (
        <>
            {/* Conditional rendering for the loader */}
            {isLoading && <div className="loader-overlay"><Spinner animation="border" role="status"> </Spinner></div>}
            <main className="set_gallery_bg">
                <section className="profile_elements pt_60 pb_60">
                    <div className="container">
                        <div className="profile_card" style={{ maxWidth: "none" }}>
                            <h4 className="prof_title">User's</h4>
                            <div className="table_userDetails">
                                <table id="myTable" className="table table-striped " style={{ width: "100%" }}>
                                    {(usersList?.length > 0) ? (
                                        <>
                                        <thead>
                                            <tr>
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                                <th>Email</th>
                                                <th>Mob no.</th>
                                                <th>Address</th>
                                                <th>Country</th>
                                                <th>Pin</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {usersList?.map((data) => (
                                                <tr key={data.id}>
                                                    <td>
                                                        <div className="d-flex form_profile_outer">
                                                            <div className="form_profile">
                                                                <img src={(data?.picture) ? (data?.picture) : defaultProfile} className="form_profile_icon" alt="" />
                                                            </div>
                                                            <p className="userName pb-0">{(data?.first_name)? data.first_name.charAt(0).toUpperCase() + data.first_name.slice(1) : '-'}</p>
                                                        </div>
                                                    </td>
                                                    <td> {(data?.last_name) ? (data?.last_name) : '-'} </td>
                                                    <td> {(data?.email)     ? (data?.email)     : '-'} </td>
                                                    <td> {(data?.mobile_number) ? (data?.mobile_number) : '-'} </td>
                                                    <td> {(data?.address)   ? (data?.address)   : '-'} </td>
                                                    <td> {(data?.country)   ? (data?.country)   : '-'} </td>
                                                    <td> {(data?.pin)       ? (data?.pin)       : '-'} </td>
                                                    <td>
                                                        <div className="d-flex edit_propsTable">
                                                            <Link to={`/admin/user/sequence/${data.id}`} title="Images View"> <FaEye/> </Link>
                                                            <Link to={`/admin/user/update/${data.id}`} title="Edit User"> <FaEdit/> </Link>
                                                            <Link onClick={() => modalFunnction('open',`${data.id}`)} title="Change Password"> <FaLock/> </Link>
                                                            {(!data?.email_verified_at) ?
                                                                <Link onClick={(e) => handelVerificationEmail(data.id)} title="Resend Verification Email"> 
                                                                    <MdOutgoingMail size={22} color="blue"/> 
                                                                </Link>
                                                            :
                                                                <Link title="Verified"> 
                                                                    <MdDomainVerification size={20} color="green"/>
                                                                </Link>
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                        </>
                                    ): (
                                        <tbody>
                                            <tr>
                                                <td colSpan={10} align={'center'} >User Not Found!</td>
                                            </tr>
                                        </tbody>
                                    )}
                                </table>
                            </div>
                        </div>

                    </div>
                </section>


            </main>

            {/* Modal to change password */}
            <Modal isOpen={modalIsOpen === true} onRequestClose={modalFunnction} style={customStyles} >
                <span className="close-modal float-end pe-4 pt-4 text-danger" style={{cursor:'pointer'}} onClick={modalFunnction}>< GiCancel size={20}/></span>
                <ChangePasswordByAdmin {...passwordProps} onReturn={handleReturnedPasswordValue} />
            </Modal>
        </>
    );
}

export default UserList;