import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { FaEdit, FaTrash} from "react-icons/fa";
import { Spinner } from "react-bootstrap";
import { clearAdminMsg, continentStatus, deleteContinent, getContinentList } from "../../../../actions/adminAction";
import { toast } from "react-toastify";
import DataTable from 'datatables.net-dt';
import $ from 'jquery';
import 'datatables.net-responsive-dt';
import { FaPlus } from "react-icons/fa";

const ContinentList = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [continentList, setContinentList] = useState([]);

    useEffect(() => {
        dispatch({ type: 'SHOW_LOADER' });
        dispatch(getContinentList());
    }, [dispatch])

    // Selects data related to the loader action from the Redux store state.
    const isLoading = useSelector(state => state.loader.isLoading);
    // Selects data related to the userList action from the Redux store state.
    const { data } = useSelector((state) => state.continentList.continentList)
    useEffect(() => {
        if (data && data.status) {
            dispatch({ type: 'HIDE_LOADER' });
            setContinentList(data?.data?.list);
        }
    }, [data, navigate, dispatch]);


    // Confirm the status with a confirmation dialog
    const handelStatus = (continentId, status) =>{
        Swal.fire({
            title: "Are you sure?",
            text: status === 0 ? "You want to disable this continent" : "You want enable this continent",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: status === 0 ? "Disable!" : "Enable!",
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch({ type: 'SHOW_LOADER' }); // Set loader to true
                dispatch(continentStatus({
                    id : continentId,
                    status : status
                }));
            }
        });
    }

    const handelDelete = (continentId) => {
        Swal.fire({
            title: "Are you sure?",
            text:  "you want to `DELETE` this continent",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Delete",
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch({ type: 'SHOW_LOADER' });
                dispatch(deleteContinent({
                    id : continentId,
                }));
            }
        });
    }

    // Selects data related to the deleteContinent action from the Redux store state.
    const  { deleteResponse }  = useSelector((state) => state.deleteContinent.deleteContinent)
    useEffect(() => {
        if (deleteResponse && deleteResponse?.status) {
            toast.success(deleteResponse?.message[0],{theme: "dark",});
            if ($.fn.dataTable.isDataTable('#myTable')) {
                $('#myTable').DataTable().destroy();
            }
            dispatch({ type: 'HIDE_LOADER' });
            dispatch(clearAdminMsg());
            dispatch(getContinentList());
        }
    }, [deleteResponse, navigate, dispatch]);

    // Selects data related to the continentStatus action from the Redux store state.
    const  { statusResponse }  = useSelector((state) => state.continentStatus.continentStatus)
    useEffect(() => {
        if (statusResponse && statusResponse?.status) {
            toast.success(statusResponse?.message[0],{theme: "dark",});
            dispatch(clearAdminMsg());
            dispatch(getContinentList());
        }
    }, [statusResponse, dispatch]);
    
    // impelement datatable from jquery
    useEffect(() => {
        if(continentList && continentList.length > 0){
            new DataTable('#myTable', {
                responsive: true,
                bDestroy: true
            });
        }
    }, [continentList])

    return (
        <>
            {/* Conditional rendering for the loader */}
            {isLoading && <div className="loader-overlay"><Spinner animation="border" role="status"> </Spinner></div>}
            <main className="set_gallery_bg">
                <section className="profile_elements pt_60 pb_60">
                    <div className="container">
                        <div className="profile_card" style={{ maxWidth: "none" }}>
                            <div className="d-flex justify-content-between">
                                <h4 className="prof_title">Continent's</h4>
                                <Link to="/admin/continent/create">
                                    <button type="button" className="btn btn-purple btn-sm text-white"><FaPlus/>&nbsp;Create</button>
                                </Link>
                            </div>
                            <div className="table_userDetails">
                                <table id="myTable" className="table table-striped table-responsive" style={{ width: "100%" }}>
                                    {(continentList?.length > 0) ? (
                                        <>
                                        <thead>
                                            <tr>
                                                <th>S.no</th>
                                                <th>Continent Name</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {continentList?.map((data, index) => (
                                                <tr key={index}>
                                                    <td> {index + 1} </td>
                                                    <td> {(data?.name) ? (data?.name) : '-'} </td>
                                                    <td> 
                                                        <div className="form-check form-switch">
                                                            <input
                                                                className="form-check-input"
                                                                onChange={() => handelStatus(data.id, data.status === 0 ? 1 : 0)} // Add onChange handler
                                                                type="checkbox"
                                                                role="switch"
                                                                id="flexSwitchCheckDefault"
                                                                checked={data.status === 1}
                                                            />        
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Switch to enable/disable</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex edit_propsTable">
                                                            <Link to={`/admin/continent/update/${data.id}`} title="Edit"> <FaEdit/> </Link>
                                                            <Link onClick={() => handelDelete(data.id)} title="Delete"><FaTrash color="red"/> </Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                        </>
                                    ): (
                                        <tbody>
                                            <tr>
                                                <td colSpan={4} align={'center'} >Continent Not Found!</td>
                                            </tr>
                                        </tbody>
                                    )}
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}

export default ContinentList;