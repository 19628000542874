import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "../components/auth/login/login";
import Title from "../components/pages/about/title.js";
import { UserPrivateRoute, AdminPrivateRoute, AuthRoute } from "./privateRoute.js";
import SignUp from "../components/auth/register/signup";
import AdminDashboard from "../components/admin/dashboard/adminDashboard";
import UserDashboard from "../components/photographer/dashboard/userDashboard";
import ProfileUpdate from "../components/photographer/profileUpdate";
import ForgotPassword from "../components/auth/password/forgotPassword.js";
import ResetPassword from "../components/auth/password/resetPassword.js";
import EmailVerification from "../components/auth/emailVerification/emailVerification.js";
import ChangePassword from "../components/auth/password/changePassword.js";
import UserList from "../components/admin/user/userList.js";
import UserProfileUpdate from "../components/admin/user/userProfileUpdate.js";
import SequenceList from "../components/admin/sequence/sequenceList.js";
import ViewSequence from "../components/admin/sequence/viewSequence.js";
import UserSequenceList from "../components/photographer/sequence/userSequenceList.js";
import CreateSequence from "../components/photographer/sequence/createSequence.js";
import Home from "../components/pages/home.js";
import MainSection from "../components/pages/includes/mainSection.js";
import UpdateSequence from "../components/photographer/sequence/updateSequence.js";
import Section from "../components/includes/section.js";
import AboutUs from "../components/pages/about/aboutUs.js";
import ViewUserSequence from "../components/photographer/sequence/viewUserSequence.js";
import UpdateSequenceByAdmin from "../components/admin/sequence/updateSequenceByAdmin.js";
import SequenceByUser from "../components/admin/sequence/sequenceByUser.js";
import SetDefalutPrice from "../components/admin/setDefaultPrice.js";
import ContinentList from "../components/admin/locations/continents/continentList.js";
import CreateContinent from "../components/admin/locations/continents/createContinent.js";
import UpdateContinent from "../components/admin/locations/continents/updateContinent.js";
import RegionList from "../components/admin/locations/regions/regionList.js";
import CreateRegion from "../components/admin/locations/regions/createRegion.js";
import UpdateRegion from "../components/admin/locations/regions/updateRegion.js";
import AreaList from "../components/admin/locations/areas/areaList.js";
import CreateArea from "../components/admin/locations/areas/createArea.js";
import UpdateArea from "../components/admin/locations/areas/updateArea.js";
import CreateSpot from "../components/admin/locations/spots/createSpot.js";
import UpdateSpot from "../components/admin/locations/spots/updateSpot.js";
import SpotList from "../components/admin/locations/spots/spotList.js";
import Gallery from "../components/pages/gallery.js";
import ContactUs from "../components/pages/about/contactUs.js";
import ContactList from "../components/admin/contactList.js";
import SequencePaymentCheckout from "../components/pages/payment/sequencePayment.js";
import ImagePaymentCheckout from "../components/pages/payment/imagePayment.js";
import PaymentRetriev from "../components/pages/payment/paymentRetriev.js";
import ImagesDownload from "../components/pages/payment/imagesDownload.js";
import PaymentFail from "../components/pages/payment/paymentFail.js";
import UserPayouts from "../components/photographer/payouts/userPayouts.js";
import AllPaymentPayouts from "../components/admin/allPaymentPayouts.js";
import HowIsWorks from "../components/pages/about/howItWorks.js";
import TermsAndConditions from "../components/pages/about/termsAndCondition.js";


const Routers = () => {
    return (
        <Routes>
            {/* Define a Authroute to check login */}
            <Route element={<AuthRoute />}>
                <Route path="" element={<MainSection />} >
                    <Route path="*" element={<Title title="Home"><Navigate to='/home' /></Title>}></Route>
                    <Route path="/" element={<Title title="Home"><Home /></Title>}></Route>
                    <Route path="/login" element={<Login />}></Route>
                    <Route path="/signup" element={<SignUp />}></Route>
                    <Route path="/forgot-password" element={<ForgotPassword />}></Route>
                    <Route path="/reset-password/:token/:username" element={<ResetPassword />}></Route>
                    <Route path="/email-verification/:token/:username" element={<EmailVerification />}></Route>

                    <Route path="/home" element={<Title title="Home"><Home /></Title>}></Route>
                    <Route path="/sequence-gallery/:key" element={<Title title="Gallery"><Gallery /></Title>}></Route>
                    <Route path="/sequence-payment-checkout/:key" element={<Title title="Images Payment Checkout"><SequencePaymentCheckout /></Title>}></Route>
                    <Route path="/image-payment-checkout/:key/:imageId" element={<Title title="Image Payment Checkout"><ImagePaymentCheckout /></Title>}></Route>
                    <Route path="/payment/checkout/success" element={<Title title="Payment Retriev"><PaymentRetriev /></Title>}></Route>
                    <Route path="/payment/checkout/fail" element={<Title title="Payment Fail"><PaymentFail /></Title>}></Route>
                    <Route path="/images/download/:session_id" element={<Title title="Images Download"><ImagesDownload /></Title>}></Route>
                    
                    <Route path="/terms-and-conditions" element={<Title title="Terms And Conditions"><TermsAndConditions /></Title>}></Route>
                    <Route path="/works" element={<Title title="How It Works"><HowIsWorks /></Title>}></Route>
                    <Route path="/about-us" element={<Title title="About Us"><AboutUs /></Title>}></Route>
                    <Route path="/contact-us" element={<Title title="Contact Us"><ContactUs /></Title>}></Route>
                </Route>
            </Route>
            
            {/* Define a Admin PrivateRoute component to handle authentication */}
            <Route element={<AdminPrivateRoute />}>
                <Route path="/">
                    <Route path="" element={<Section />} >
                        {/****************** Route for Admin modules ****************/}
                        <Route exact path="/admin/dashboard" element={<Title title="Admin Dashboard"><AdminDashboard /></Title>} />
                        <Route exact path="/admin/change-password" element={<Title title="Change Password"><ChangePassword /></Title>} />
                        <Route exact path="/admin/set-default-price" element={<Title title="Defalut Price"><SetDefalutPrice /></Title>} />
                        <Route exact path="/admin/contact-list" element={<Title title="Contact List"><ContactList /></Title>} />
                        <Route exact path="/admin/users" element={<Title title="User List"><UserList /></Title>} />
                        <Route exact path="/admin/user/update/:id" element={<Title title="Profile Update"><UserProfileUpdate /></Title>} />
                        <Route exact path="/admin/user/change-password/:id" element={<Title title="Change Password"><ChangePassword /></Title>} />

                        <Route exact path="/admin/sequences" element={<Title title="Images List"><SequenceList /></Title>} />
                        <Route exact path="/admin/user/sequence/:id" element={<Title title="Images By User"><SequenceByUser /></Title>} />
                        <Route exact path="/admin/sequence/view/:id" element={<Title title="View Images"><ViewSequence /></Title>} />
                        <Route exact path="/admin/sequence/update/:id" element={<Title title="Update Images"><UpdateSequenceByAdmin /></Title>} />
                        
                        {/****************** Route for Payment Payouts modules ****************/}
                        <Route exact path="/admin/payment/payouts" element={<Title title="Payment Payouts"><AllPaymentPayouts /></Title>} />
                        
                        {/****************** Route for Locations modules ****************/}
                        <Route exact path="/admin/continents" element={<Title title="Continent List"><ContinentList /></Title>} />
                        <Route exact path="/admin/continent/create" element={<Title title="Create Continent"><CreateContinent /></Title>} />
                        <Route exact path="/admin/continent/update/:id" element={<Title title="Update Continent"><UpdateContinent /></Title>} />

                        <Route exact path="/admin/regions" element={<Title title="Region List"><RegionList /></Title>} />
                        <Route exact path="/admin/region/create" element={<Title title="Create Region"><CreateRegion /></Title>} />
                        <Route exact path="/admin/region/update/:id" element={<Title title="Update Region"><UpdateRegion /></Title>} />

                        <Route exact path="/admin/areas" element={<Title title="Area List"><AreaList /></Title>} />
                        <Route exact path="/admin/area/create" element={<Title title="Create Area"><CreateArea /></Title>} />
                        <Route exact path="/admin/area/update/:id" element={<Title title="Update Area"><UpdateArea /></Title>} />

                        <Route exact path="/admin/spots" element={<Title title="Spot List"><SpotList /></Title>} />
                        <Route exact path="/admin/spot/create" element={<Title title="Create Spot"><CreateSpot /></Title>} />
                        <Route exact path="/admin/spot/update/:id" element={<Title title="Update Spot"><UpdateSpot /></Title>} />

                        <Route exact path="/admin/about-us" element={<Title title="About Us"><AboutUs /></Title>} />
                        <Route exact path="/admin/contact-us" element={<Title title="Contact Us"><ContactUs /></Title>} />
                    </Route>
                </Route>
            </Route>

            {/* Define a User PrivateRoute component to handle authentication */}
            <Route element={<UserPrivateRoute />}>
                <Route path="/">
                    <Route path="" element={<Section />} >
                        {/****************** Route for User modules ****************/}
                        <Route exact path="/user/dashboard" element={<Title title="User Dashboard"><UserDashboard /></Title>} />
                        <Route exact path="/user/profile" element={<Title title="Profile Update"><ProfileUpdate /></Title>} />
                        <Route exact path="/user/change-password" element={<Title title="Change Password"><ChangePassword /></Title>} />
                        
                        {/****************** Route for sequence modules ****************/}
                        <Route exact path="/user/sequences" element={<Title title="Images List"><UserSequenceList /></Title>} />
                        <Route exact path="/user/sequence/view/:id" element={<Title title="View Images"><ViewUserSequence /></Title>} />
                        <Route exact path="/user/sequence/create" element={<Title title="Create Images"><CreateSequence /></Title>} />
                        <Route exact path="/user/sequence/update/:id" element={<Title title="Update Images"><UpdateSequence /></Title>} />

                        {/****************** Route for Payment Payouts modules ****************/}
                        <Route exact path="/user/payouts" element={<Title title="Payouts"><UserPayouts /></Title>} />

                        <Route exact path="/user/works" element={<Title title="How It Works"><HowIsWorks /></Title>} />
                        <Route exact path="/user/about-us" element={<Title title="About Us"><AboutUs /></Title>} />
                        <Route exact path="/user/contact-us" element={<Title title="Contact Us"><ContactUs /></Title>} />
                    </Route>
                </Route>
            </Route>
        </Routes>      
  );
};
export default Routers;