import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { createSequence, clearSequenceMsg } from "../../../actions/sequenceAction";
import { FaTimes } from "react-icons/fa"
import file from "../../../assets/images/Upload-icon.png";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { convertToBase64 } from "../../common/utils";
import { getAllLocation, getAreaSpotsAction, getContinentRegionsAction, getRegionAreasAction } from "../../../actions/commonAction";
import Select from "react-select";

const CreateSequence = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const [error, setError] = useState(false);
    // const [sequenceName, setSequenceName] = useState("");
    // const [description, setDescription] = useState("");
    // const [location, setLocation] = useState("");
    const [date, setDate] = useState("");
    const [type, setType] = useState("");
    const [selectedImages, setSelectedImages] = useState([]);
    const [imagesCount,setImagesCount] = useState(0);

    const [allContinents, setAllContinents] = useState([]);
    const [allRegions, setAllRegions] = useState([]);
    const [allAreas, setAllAreas] = useState([]);
    const [allSpots, setAllSpots] = useState([]);

    const [selectedContinent, setSelectedContinent] = useState("");
    const [selectedRegion, setSelectedRegion] = useState("");
    const [selectedArea, setSelectedArea] = useState("");
    const [selectedSpot, setSelectedSpot] = useState("");


    useEffect(() => {
        dispatch({ type: 'SHOW_LOADER' }); // Set loader to true
        dispatch(getAllLocation())
    },[dispatch])

    // Selects data related to the loader action from the Redux store state.
    const isLoading = useSelector(state => state.loader.isLoading);
    // Selects data related to the createSequence action from the Redux store state.
    const { allLocation } = useSelector((state) => state.getAllLocation.getAllLocation)
    useEffect(() => {    
        if (allLocation && allLocation.status) {
            dispatch({ type: 'HIDE_LOADER' });
            setAllContinents(allLocation?.data?.continents);
            // setAllRegions(allLocation?.data?.regions);
            // setAllAreas(allLocation?.data?.areas);
            // setAllSpots(allLocation?.data?.spots);
        }
    }, [allLocation,dispatch]);

    // Handle uploaded images, convert to base64, and update selectedImages state
    const handelImages = async (e) => {
        let files = Array.prototype.slice.call(e.target.files);
        let validFiles = files.filter(file => file.size <= 25 * 1024 * 1024);
        let fileCount = validFiles.length + selectedImages.length;
    
        if (fileCount <= 10) {
            const fileArr = [...selectedImages];
            for (let file of validFiles) {
                let base64 = await convertToBase64(file);
                let base64Split = base64.split(";");
                let splitExtension = base64Split[0].split("/");
                // Check if file format is acceptable
                if (splitExtension[1].toLowerCase() === "png" || splitExtension[1].toLowerCase() === "jpg" || splitExtension[1].toLowerCase() === "jpeg") {
                    fileArr.push(base64);
                    setImagesCount(fileArr.length);
                } else {
                    toast.warn("The accepted file formats are: jpeg, png, or jpg.");
                }
            }
            if (validFiles.length !== files.length) {
                toast.warn("Some files were not added because they exceeded 25MB.");
            }
            setSelectedImages(fileArr);
        } else {
            toast.warn("You can't add more than 10 images at a time.");
        }
    };
    
    // Removes the selected image at the given index from selectedImages
    const removeSelectedImage = (index) => {
        let temp = [...selectedImages];
        temp.splice(index, 1);
        setSelectedImages(temp);
        setImagesCount(imagesCount - 1);
    };

    // Selects data related to the getContinentRegions action from the Redux store state.
    const { getContinentRegions } = useSelector((state) => state.getContinentRegions.getContinentRegions)
    useEffect(() => {
        if (getContinentRegions && getContinentRegions.status) {
            setAllRegions(getContinentRegions?.data?.list);
        }
    }, [getContinentRegions, dispatch]);
    // Selects data related to the getRegionAreas action from the Redux store state.
    const { getRegionAreas } = useSelector((state) => state.getRegionAreas.getRegionAreas)
    useEffect(() => {
        if (getRegionAreas && getRegionAreas.status) {
            setAllAreas(getRegionAreas?.data?.list);
        }
    }, [getRegionAreas, dispatch]);
    // Selects data related to the getAreaSpots action from the Redux store state.
    const { getAreaSpots } = useSelector((state) => state.getAreaSpots.getAreaSpots)
    useEffect(() => {
        if (getAreaSpots && getAreaSpots.status) {
            setAllSpots(getAreaSpots?.data?.list);
        }
    }, [getAreaSpots, dispatch]);

    // Handles the selection of locations based on the type of location (continent, region, area, or spot).
    const handleLocations = (e,type) => {
        let value = e ? e.value : "";
        // Determine the type of location and update the corresponding state
        switch (type) {
            case 'continent':
                setSelectedContinent(value);
                setSelectedRegion("");
                setSelectedArea("");
                setSelectedSpot("");
                if(value && value !== null){
                    dispatch(getContinentRegionsAction({ continent_id : value }));
                }
            break;
            case 'region':
                setSelectedRegion(value);
                setSelectedArea("");
                setSelectedSpot("");
                if(value && value !== null){
                    dispatch(getRegionAreasAction({ region_id : value }));
                }
            break;
            case 'area':
                setSelectedArea(value);
                setSelectedSpot("")
                if(value && value !== null){
                    dispatch(getAreaSpotsAction({ area_id : value }));
                }
            break;
            case 'spot':
                setSelectedSpot(value);
            break;
            default:
            // Handle unexpected location type
            console.error(`Unknown location type: ${type}`);
        }

    }

    // return array in lable value pair for each select option 
    const createOptionsArray = (dataArray, labelColumnName, valueColumnName, overPriceColumnName) => {
        return dataArray.map((data) => {
            return {
                label: data[labelColumnName],
                value: data[valueColumnName],
            };
        });
    };
    
    // return lable value index object with selected value in DB
    const findById = (optionsArray, targetId) => {
        return optionsArray.find(option => option.value === targetId);
    };

    // function to create sequence of images
    const handleSequenceCreate = async (e) => {
        e.preventDefault();
        if (!date || !selectedContinent || !selectedRegion || !selectedArea || !selectedSpot || selectedImages.length <= 0) {
            setError(true);
            return false;
        }else{
            dispatch({ type: 'SHOW_LOADER' }); // Set loader to true
            try {
                await dispatch(createSequence({
                    // name        : sequenceName,
                    date        : date,
                    // type        : type,
                    // location    : location,
                    // detail      : description,
                    continent_id: selectedContinent,
                    region_id   : selectedRegion,
                    area_id     : selectedArea,
                    spot_id     : selectedSpot,
                    images      : selectedImages,
                }))
            } catch (error) {
                dispatch({ type: 'HIDE_LOADER' });
                console.error("Error creating sequence:", error);
            }
        }
    }

    // Selects data related to the createSequence action from the Redux store state.
    const { data } = useSelector((state) => state.createSequence.createSequence)
    useEffect(() => {
        if (data && data.status) {
            toast.success(data?.message[0],{theme: "dark",});
            dispatch({ type: 'HIDE_LOADER' });
            dispatch(clearSequenceMsg())
            navigate("/user/sequences");
        }
    }, [data, navigate, dispatch]);

    return (
        <>
            {/* Conditional rendering for the loader */}
            {isLoading && <div className="loader-overlay">
                <div className="loader" style={{textAlign: "center" ,marginTop : "10px"}}>
                    <Spinner animation="border" role="status"></Spinner>
                    <p>Please Wait...</p>
                </div>
            </div>}
            <main className="set_gallery_bg">
                <section className="profile_elements pt_60 pb_60">
                    <div className="container">
                        <div className="upload_elements profile_card">
                            <h4 className="prof_title">UPLOAD AN IMAGE</h4>
                            <div className="dropupload">
                                <span><img src={file} alt=""/></span>
                                <div className="image-select">
                                    <h4>Drag & drop files or Browse</h4>
                                    {error && selectedImages.length <= 0  && (
                                        <span className="requiredInput" >
                                            Please upload at least one image*
                                        </span>
                                    )}
                                </div>
                                <input type="file" 
                                    id="images" 
                                    name="images"
                                    accept=".jpeg,.png,.jpg"
                                    onChange={(e) => handelImages(e)}
                                    multiple
                                />
                            </div>
                            <div className="upload_image mt_20">
                                <form onSubmit={handleSequenceCreate} onKeyDown={(e) => { if (e.key === 'Enter') e.preventDefault()}}>
                                    <div className="upload_imgList">
                                        <ul>
                                            {selectedImages.map((image, index) => (
                                                <li key={index}>
                                                    <div className="uploadInner">
                                                        <img src={image} alt="" className="img_uploadItem img-fluid"/>
                                                        <div className="btn_uploadData d-flex">
                                                            <Link to="" onClick={() => removeSelectedImage(index)} className="btn_trash"><FaTimes size={20} color="red" /></Link>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                    <div className="form_elements field_drops">
                                        <div className="row">
                                            {/* <div className="col-md-6">
                                                <fieldset className="form_fields_drops">
                                                    <label htmlFor="">Sequence Name<span className="text-danger">*</span></label>
                                                    <input
                                                        type="text" id="name" 
                                                        name="name"
                                                        value={sequenceName || ''}
                                                        onChange={(e) => setSequenceName(e.target.value)}
                                                        placeholder="Enter Sequence Name" 
                                                        maxLength="100"
                                                    />
                                                    {error && !sequenceName && (
                                                        <span className="requiredInput" >
                                                            Required*
                                                        </span>
                                                    )}
                                                </fieldset>
                                            </div> */}
                                            {false && (<div className="col-md-6">
                                                <label htmlFor="">Type<span className="text-danger">*</span></label>
                                                <fieldset className="form_fields_drops icon_drop">
                                                    <Select
                                                        className="select"
                                                        isClearable
                                                        options={[
                                                            { label: 'Single photo upload', value: 1 },
                                                            { label: 'Sequence of one wave', value: 2 }
                                                        ]}
                                                        onChange={(e) => setType(e.value)}
                                                        placeholder="Select Type"
                                                    />
                                                    {error && !type && (
                                                        <span className="requiredInput" >
                                                            Required*
                                                        </span>
                                                    )}
                                                </fieldset>
                                            </div>)}
                                            <div className="col-md-6">
                                                <fieldset className="form_fields_drops">
                                                    <label htmlFor="">Date<span className="text-danger">*</span></label>
                                                    <input
                                                        type="date" id="date" 
                                                        name="date"
                                                        value={date || ''}
                                                        onChange={(e) => setDate(e.target.value)}
                                                        placeholder="Select Date"
                                                    />
                                                    {error && !date && (
                                                        <span className="requiredInput" >
                                                            Required*
                                                        </span>
                                                    )}
                                                </fieldset>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="">Continent<span className="text-danger">*</span></label>
                                                <fieldset className="form_fields_drops icon_drop">
                                                    <Select
                                                        className="select"
                                                        isClearable
                                                        options={allContinents?.map((data) => {
                                                            return {
                                                                label: data.name,
                                                                value: data.id,
                                                            };
                                                        })}
                                                        onChange={(e) => handleLocations(e, 'continent')}
                                                        placeholder="Select Continents"
                                                    />
                                                    {error && !selectedContinent && (
                                                        <span className="requiredInput" >
                                                            Required*
                                                        </span>
                                                    )}
                                                </fieldset>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="">Region<span className="text-danger">*</span></label>
                                                <fieldset className="form_fields_drops icon_drop">
                                                    <Select
                                                        className="select"
                                                        isClearable
                                                        options={allRegions?.map((data) => {
                                                            return {
                                                                label: data.name,
                                                                value: data.id,
                                                            };
                                                        })}
                                                        value={[findById(createOptionsArray(allRegions, 'name', 'id'), selectedRegion)]}
                                                        onChange={(e) => handleLocations(e, 'region')}
                                                        placeholder="Select Region"
                                                    />
                                                    {error && !selectedRegion && (
                                                        <span className="requiredInput" >
                                                            Required*
                                                        </span>
                                                    )}
                                                </fieldset>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="">Area<span className="text-danger">*</span></label>
                                                <fieldset className="form_fields_drops icon_drop">
                                                    <Select
                                                        className="select"
                                                        isClearable
                                                        options={allAreas?.map((data) => {
                                                            return {
                                                                label: data.name,
                                                                value: data.id,
                                                            };
                                                        })}
                                                        value={[findById(createOptionsArray(allAreas, 'name', 'id'), selectedArea)]}
                                                        onChange={(e) => handleLocations(e, 'area')}
                                                        placeholder="Select Area"
                                                    />
                                                    {error && !selectedArea && (
                                                        <span className="requiredInput" >
                                                            Required*
                                                        </span>
                                                    )}
                                                </fieldset>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="">Spot<span className="text-danger">*</span></label>
                                                <fieldset className="form_fields_drops icon_drop">
                                                    <Select
                                                        className="select"
                                                        isClearable
                                                        options={allSpots?.map((data) => {
                                                            return {
                                                                label: data.name,
                                                                value: data.id,
                                                            };
                                                        })}
                                                        value={[findById(createOptionsArray(allSpots, 'name', 'id'), selectedSpot)]}
                                                        onChange={(e) => handleLocations(e, 'spot')}
                                                        placeholder="Select Spot"
                                                    />
                                                    {error && !selectedSpot && (
                                                        <span className="requiredInput" >
                                                            Required*
                                                        </span>
                                                    )}
                                                </fieldset>
                                            </div>
                                        </div>
                                        {/* <div className="row">
                                            <div className="col-md-6">
                                                <fieldset className="form_fields_drops">
                                                    <label htmlFor="">Location</label>
                                                    <input
                                                        type="text" id="location" 
                                                        name="location"
                                                        value={location || ''}
                                                        onChange={(e) => setLocation(e.target.value)}
                                                        placeholder="Enter Location" 
                                                    />
                                                </fieldset>
                                            </div>
                                        </div> */}
                                        {/* <div className="form_fields_drops set_fulls">
                                            <label htmlFor="">Description<span className="text-danger">*</span></label>
                                            <textarea className="form-control"
                                                type="text" id="exampleFormControlTextarea1" 
                                                name="description"
                                                value={description || ''}
                                                onChange={(e) => setDescription(e.target.value)}
                                                placeholder="Write description..." 
                                                maxLength="4000"
                                                rows="3"
                                            ></textarea>
                                            {error && !description && (
                                                <span className="requiredInput" >
                                                    Required*
                                                </span>
                                            )}
                                        </div> */}
                                        <div className="upload_btn d-flex justify-content-center">
                                            <button type="submit" className="loadMore_action">Create</button>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}

export default CreateSequence;


