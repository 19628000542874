import React, { useEffect } from "react";
import img7 from '../../../assets/images/7.jpeg';
import img8 from '../../../assets/images/8.jpg';
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

const AboutUs = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({ type: 'CLEAR_SEARCHSEQUENCE_MESSAGE' });
    },[dispatch])
    
    return(
        <>
            <main className="set_gallery_bg">
                <section className="sec_way search_section">
                    <div className="container">
                        <h3 className="text-center pb_50">Who we are?</h3>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="image_surf">
                                    <img src={img7} alt="" className="img-fluid"/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="sub_details">
                                    <h5>What is Find My Surfpic?</h5>
                                    <p>Remember that day when the waves were going off and you got the ride of your life! You look at the beach and see a few cameras pointed at you, but you would likely never see that picture up until now. Welcome to <Link style={{color:"#4b4bff"}}to="https://findmysurfpic.com">findmysurfpic.com</Link> This is a site where everyone from amateur photographers to pros can share their images based on date, location, and even surf spot for surfers to find.</p>
                                    <p>Find My Surf Pic is dedicated to preserve our ocean's.  For each photograph sold, Find My Surf Pic will make a donation to save our ocean! We want future generations to be able to enjoy the ultimate playground! </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="sec_waves">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                            <Link to="/home"><h2 className="waves_title">SEARCH FOR PHOTOS NOW!</h2></Link>
                            </div>
                            <div className="waves_bgImg">
                                <img src={img8} alt=""/>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}

export default AboutUs;