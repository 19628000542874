import React, { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom";
import  bgImg  from "../../assets/images/home1.png";
import img1 from "../../assets/images/1.jpeg";
import { FaCartPlus, FaEye, FaSearch } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import defaultImg from "./../../assets/images/defalut.jpg";
import { useDispatch, useSelector } from "react-redux";
import { getAllLocation, getAreaSpotsAction, getContinentRegionsAction, getRegionAreasAction } from "../../actions/commonAction";
import { Spinner } from "react-bootstrap";
import Select from "react-select";
import { searchSequence } from "../../actions/homeAction";
import { toast } from "react-toastify";
import { PiImageLight } from "react-icons/pi";
import RandomSequence from "./rendomSequence";
import { TbBoxMultiple } from "react-icons/tb";
// import { previewImgage } from "../common/utils";
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import moment from "moment";

const Home = () => {

    const dispatch = useDispatch();

    const [allContinents, setAllContinents] = useState([]);
    const [allRegions, setAllRegions] = useState([]);
    const [allAreas, setAllAreas] = useState([]);
    const [allSpots, setAllSpots] = useState([]);

    const [selectedContinent, setSelectedContinent] = useState("");
    const [selectedRegion, setSelectedRegion] = useState("");
    const [selectedArea, setSelectedArea] = useState("");
    const [selectedSpot, setSelectedSpot] = useState("");
    const [date, setDate] = useState("");

    const [sequenceData, setSequenceData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const currentPageRef = useRef(currentPage);

    useEffect(() => {
        dispatch({ type: 'SHOW_LOADER' }); // Set loader to true
        dispatch(getAllLocation())
    }, [dispatch])
    
    const searchParams = {
        continent_id: selectedContinent,
        region_id: selectedRegion,
        area_id: selectedArea,
        spot_id: selectedSpot,
        date: date,
    };
    const hasSearchParams = Object.values(searchParams).some(param => param);
    useEffect(() => {
        dispatch(searchSequence(currentPage,(hasSearchParams) ? searchParams : ''))
        // Update the ref whenever currentPage changes
        currentPageRef.current = currentPage;
    }, [dispatch, currentPage])
    
    // Selects data related to the loader action from the Redux store state.
    const isLoading = useSelector(state => state.loader.isLoading);
    // Selects data related to the createSequence action from the Redux store state.
    const { allLocation } = useSelector((state) => state.getAllLocation.getAllLocation)
    useEffect(() => {    
        if (allLocation && allLocation.status) {
            // dispatch({ type: 'HIDE_LOADER' });
            setAllContinents(allLocation?.data?.continents);
            setAllRegions(allLocation?.data?.regions);
            setAllAreas(allLocation?.data?.areas);
            setAllSpots(allLocation?.data?.spots);
        }
    }, [allLocation,dispatch]);

    // Selects data related to the searchSequence action from the Redux store state.
    const { searchData } = useSelector((state) => state.searchSequence.searchSequence)
    useEffect(() => {    
        if (searchData && searchData.status) {
            dispatch({ type: 'HIDE_LOADER' });
            // setSequenceData(searchData?.data?.list?.data);
            setSequenceData(prevSequenceList => {
                const updatedList = currentPageRef.current === 1 ? searchData?.data?.list?.data : [...prevSequenceList, ...searchData?.data?.list?.data];
                return updatedList;
            });
            setTotal(searchData?.data?.list?.total)
        }
    }, [searchData,dispatch]);

    // Selects data related to the getContinentRegions action from the Redux store state.
    const { getContinentRegions } = useSelector((state) => state.getContinentRegions.getContinentRegions)
    useEffect(() => {
        if (getContinentRegions && getContinentRegions.status) {
            setAllRegions(getContinentRegions?.data?.list);
        }
    }, [getContinentRegions, dispatch]);
    // Selects data related to the getRegionAreas action from the Redux store state.
    const { getRegionAreas } = useSelector((state) => state.getRegionAreas.getRegionAreas)
    useEffect(() => {
        if (getRegionAreas && getRegionAreas.status) {
            setAllAreas(getRegionAreas?.data?.list);
        }
    }, [getRegionAreas, dispatch]);
    // Selects data related to the getAreaSpots action from the Redux store state.
    const { getAreaSpots } = useSelector((state) => state.getAreaSpots.getAreaSpots)
    useEffect(() => {
        if (getAreaSpots && getAreaSpots.status) {
            setAllSpots(getAreaSpots?.data?.list);
        }
    }, [getAreaSpots, dispatch]);
    
    // Handles the selection of locations based on the type of location (continent, region, area, or spot).
    const handleLocations = (e,type) => {
        let value = e ? e.value : "";
        // Determine the type of location and update the corresponding state
        switch (type) {
            case 'continent':
                setSelectedContinent(value);
                setSelectedRegion("");
                setSelectedArea("");
                setSelectedSpot("");
                if(value && value !== null){
                    dispatch(getContinentRegionsAction({ continent_id : value }));
                }
            break;
            case 'region':
                setSelectedRegion(value);
                setSelectedArea("");
                setSelectedSpot("");
                if(value && value !== null){
                    dispatch(getRegionAreasAction({ region_id : value }));
                }
            break;
            case 'area':
                setSelectedArea(value);
                setSelectedSpot("")
                if(value && value !== null){
                    dispatch(getAreaSpotsAction({ area_id : value }));
                }
            break;
            case 'spot':
                setSelectedSpot(value);
            break;
            default:
            // Handle unexpected location type
            console.error(`Unknown location type: ${type}`);
        }

    }

    // return array in lable value pair for each select option 
    const createOptionsArray = (dataArray, labelColumnName, valueColumnName, overPriceColumnName) => {
        return dataArray.map((data) => {
            return {
                label: data[labelColumnName],
                value: data[valueColumnName],
            };
        });
    };
    
    // return lable value index object with selected value in DB
    const findById = (optionsArray, targetId) => {
        return optionsArray.find(option => option.value === targetId);
    };

    // fuction to search sequence according to selected location
    const handleSearchSequence = () => {
        try {
            setCurrentPage(1);
            setSequenceData([]);
            const searchParams = {
                continent_id: selectedContinent,
                region_id: selectedRegion,
                area_id: selectedArea,
                spot_id: selectedSpot,
                date: date,
            };
            // const hasSearchParams = Object.values(searchParams).some(param => param);
            dispatch({ type: 'SHOW_LOADER' }); // Set loader to true
            dispatch(searchSequence(currentPage,searchParams));
            // if (hasSearchParams) {
            // } else {
            //     toast.warning("Please choose at least one info to search!", { theme: "dark" });
            // }
        } catch (error) {
            console.error("Error during search sequence:", error);
            toast.error("An error occurred while searching. Please try again later.", { theme: "dark" });
        }
    };


    // Handle "Load More" button click
    const handleLoadMore = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    return (
        <>
            {/* Conditional rendering for the loader */}
            {isLoading && <div className="loader-overlay"><Spinner animation="border" role="status"> </Spinner></div>}
            <main className="set_gallery_bg">
                <section className="search_section">
                    <div className="container">
                        <div className="find_data">
                            <h3>Search for Photos Here</h3>
                            <form action="#">
                                <div className="form_elements set_end">
                                    <fieldset className=" form_fields icon_drop">
                                        <label htmlFor="">Continent<span className="text-danger">*</span></label>
                                        <Select
                                            className="select"
                                            isClearable
                                            options={allContinents?.map((data) => {
                                                return {
                                                    label: data.name,
                                                    value: data.id,
                                                };
                                            })}
                                            value={[findById(createOptionsArray(allContinents, 'name', 'id'), selectedContinent)]}
                                            onChange={(e) => handleLocations(e, 'continent')}
                                            placeholder="Select Continents"
                                        />
                                    </fieldset>
                                    <fieldset className=" form_fields icon_drop">
                                        <label htmlFor="">Region<span className="text-danger">*</span></label>
                                        <Select
                                            className="select"
                                            isClearable
                                            options={allRegions?.map((data) => {
                                                return {
                                                    label: data.name,
                                                    value: data.id,
                                                };
                                            })}
                                            value={[findById(createOptionsArray(allRegions, 'name', 'id'), selectedRegion)]}
                                            onChange={(e) => handleLocations(e, 'region')}
                                            placeholder="Select Region"
                                        />
                                    </fieldset>
                                    <fieldset className=" form_fields icon_drop">
                                        <label htmlFor="">Area<span className="text-danger">*</span></label>
                                        <Select
                                            className="select"
                                            isClearable
                                            options={allAreas?.map((data) => {
                                                return {
                                                    label: data.name,
                                                    value: data.id,
                                                };
                                            })}
                                            value={[findById(createOptionsArray(allAreas, 'name', 'id'), selectedArea)]}
                                            onChange={(e) => handleLocations(e, 'area')}
                                            placeholder="Select Area"
                                        />
                                    </fieldset>
                                    <fieldset className=" form_fields icon_drop">
                                        <label htmlFor="">Spot<span className="text-danger">*</span></label>
                                        <Select
                                            className="select"
                                            isClearable
                                            options={allSpots?.map((data) => {
                                                return {
                                                    label: data.name,
                                                    value: data.id,
                                                };
                                            })}
                                            value={[findById(createOptionsArray(allSpots, 'name', 'id'), selectedSpot)]}
                                            onChange={(e) => handleLocations(e, 'spot')}
                                            placeholder="Select Spot"
                                        />
                                    </fieldset>
                                    <fieldset className=" form_fields icon_drop">
                                        <label htmlFor="">Date<span className="text-danger">*</span></label>
                                        <input
                                            type="date" id="date" 
                                            name="date"
                                            value={date || ''}
                                            onChange={(e) => setDate(e.target.value)}
                                            placeholder="Select Date" 
                                        />
                                    </fieldset>
                                    <Link onClick={() => handleSearchSequence()} className="btn btn_default searchHome_btn"><FaSearch size={20}/>
                                    Find
                                    </Link>
                                </div>
                            </form>
                            <div className="image_wrapField">
                                <div className="titel_line">
                                    <p>
                                        <span className="locationSet">
                                            <IoLocationSharp size={25} color="red" />
                                        </span>
                                       {(selectedContinent || selectedRegion || selectedArea || selectedSpot) ? 
                                           ((selectedContinent ? (allContinents.find(conti => conti.id === selectedContinent)?.name || selectedContinent) + ', ' : '') +
                                           (selectedRegion ? (allRegions.find(region => region.id === selectedRegion)?.name || selectedRegion) + ', ' : '') +
                                           (selectedArea ? (allAreas.find(area => area.id === selectedArea)?.name || selectedArea) + ', ' : '') +
                                           (selectedSpot ? (allSpots.find(spot => spot.id === selectedSpot)?.name || selectedSpot): ''))          
                                        : allContinents[0]?.name}
                                        {(date) ?
                                            <> 
                                                <span className="dotBlock"></span>
                                                <span>{date}</span>
                                            </>
                                        : ""}
                                    </p>
                                </div>
                                <div className="item_log_pic">
                                    <ul className="item_searchvalue">
                                    <PhotoProvider>
                                    {(sequenceData?.length > 0) ? (
                                        sequenceData?.map((data, index) => (
                                            <PhotoView key={index} src={(data.sequence_image) ? data.sequence_image : defaultImg}>
                                            <li key={index} className="item_flexInner">
                                                <div className="deskDettails">
                                                    <img src={(data.sequence_image) ? data.sequence_image : defaultImg} alt="" className="img-fluid"/>
                                                    <div className="img_deskDetails">
                                                        <div className="img_deskInner">
                                                            <p>{(data.area) ? (data.area)+',' : ''} {(data.spot) ? (data.spot) : ''} </p>
                                                            <div className="gallery_btns d-flex justify-content-between">
                                                                <div className="gallery_aut">
                                                                    <p className="text_small  text-white"> <span className="sub_title_aut">Published </span>: {(data?.date) ? moment(data?.date).format('MMM DD,YYYY') : '-'}</p>
                                                                </div>
                                                                <span className="icon_plus  mr-5" >
                                                                    {(data?.type !== 1) ?
                                                                        <>
                                                                            <Link to={`/sequence-payment-checkout/${data?.sequence_key}`} title="Payment"><FaCartPlus className="fab_color" size={22}/></Link>
                                                                            <Link to={`/sequence-gallery/${data?.sequence_key}`} title="View"><FaEye className="fab_color" size={25}/></Link>
                                                                        </>
                                                                    :
                                                                        <>
                                                                            <Link to={`/image-payment-checkout/${data?.sequence_key}/${data?.id}`} title="Payment"><FaCartPlus className="fab_color" size={22}/></Link>
                                                                            <Link title="View" 
                                                                                // onClick={() => previewImgage(data?.sequence_image)}
                                                                            ><FaEye className="fab_color" size={25}/></Link>
                                                                        </>
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="multiple">
                                                        {(data?.type !== 1) ? <TbBoxMultiple size={25} color="white"/> : ''}
                                                    </div>
                                                </div>
                                            </li>
                                            </PhotoView>
                                        ))
                                    ): (
                                        <div className="d-flex no_record">
                                            <PiImageLight size={100}/>
                                            <span align={'center'} style={{fontWeight:'700',padding:'1em'}}> 
                                                No Sequence Found.
                                            </span>
                                        </div>
                                    )}
                                    </PhotoProvider>
                                    </ul>
                                    {sequenceData?.length < total && (
                                        <div className="loadMore">
                                            <Link onClick={handleLoadMore} className="loadMore_action">Load More</Link>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="cover_sec" style={{ backgroundImage: `url(${img1})`, backgroundRepeat: "no-repeat" }}>
                    <div className="container">
                        <div className="center_coverTitle">
                            <p>Find My Surf Pic Makes It Easy To Find Your Surf Pic's From All Over The World!</p>
                        </div>
                    </div>
                </section>

                <section className="home_banner">
                    <div className="container-fluid containerHeader set_bgContent">
                        <div className="content_headerInner">
                            <h1 className="main_title">Photographers</h1>
                            <p className="top_title mb-4">Create Your Free Account To Start Selling Your Pictures! </p>
                            <p className="sub_title mb_40">For each picture sold Find My Surf Pic will make a donation to help save our ocean's!</p>
                            <Link to="/signup" className="btn btn_default btn_trial">Sign up</Link>
                        </div>
                        <div className="bg_homeImg"><img src={bgImg} alt=""/></div>
                    </div>
                </section>
                <RandomSequence/>
            </main>
        </>
    );
}

export default Home;