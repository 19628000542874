import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import countries from "../../common/countries";
import { useNavigate, useParams } from "react-router-dom";
import { userProfileUpdate, clearAdminMsg, viewUserProfile } from "../../../actions/adminAction";
import { toast } from "react-toastify";
import { convertToBase64 } from "../../common/utils";
import { Spinner } from "react-bootstrap";
import profilePic from '../../../assets/images/default-profile.png'
import Select from "react-select";
import { FaPencilAlt } from "react-icons/fa";

const UserProfileUpdate = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id }   = useParams();

    const [error, setError] = useState(false);
    
    const [userData, setUserData] = useState({
        id : "", firstname : "", lastname : "", email : "", mobile_number : "", address : "", country : "", pin : "", username : "", picture : ""
    });
    const [userCountry , setUserCountry] = useState("")
    const [profilePicture, setProfilePicture] = useState(null);

    // useEffect for call Api's 
    useEffect(() => {
        dispatch(viewUserProfile(id));
    }, [dispatch,id]);

    // Selects data related to the loader action from the Redux store state.
    const isLoading = useSelector(state => state.loader.isLoading);
    // Selects data related to the viewUserProfile action from the Redux store state.
    const { data } = useSelector((state) => state.viewUserProfile.viewUserProfile)
    useEffect(() => {
        if (data && data.status) {
            setUserData({
                id: id, 
                firstname   : data?.data?.first_name,
                lastname    : data?.data?.last_name,
                email       : data?.data?.email,
                username    : data?.data?.username,
                address     : data?.data?.address, 
                country     : data?.data?.country,
                pin         : data?.data?.pin,
                mobile_number: data?.data?.mobile_number,
                picture     : data?.data?.picture
            });
            setUserCountry(data?.data?.country);
        }
    }, [data,id]);

    const handleProfileUpdateForm = (e) =>{
        setUserData({
            ...userData,
            [e.target.name] : e.target.value
        })
    }

    // Handle uploading and validating vendor profile photo asynchronously
    const handelProfilePhoto = async (e) => {
        // for vendor profile picture
        let file = e.target.files[0];
        let base64 = await convertToBase64(file);
        if (base64) {
            let base64Split = base64.split(";");
            let splitExtension = base64Split[0].split("/");
            if (splitExtension[1].toLowerCase() === "png" || splitExtension[1].toLowerCase() === "jpg" || splitExtension[1].toLowerCase() === "jpeg" ){
                setProfilePicture(base64);
            } else {
                toast.warn("The accepted file formats are: jpeg, png, or jpg.");
            }
        }
    };

    // function to update profile of user
    const handleProfileUpdate = (e) => {
        e.preventDefault();
        if(!userData.firstname || !userData.lastname || !userData.email || !userData.mobile_number || 
            !userData.address || !userCountry || !userData.pin || !userData.username){
            dispatch({ type: 'HIDE_LOADER' });
            setError(true);
            return false;
        }else{
            dispatch({ type: 'SHOW_LOADER' });
            dispatch(userProfileUpdate(id,{
                id: id,
                first_name   : userData?.firstname,
                last_name    : userData?.lastname,
                email       : userData?.email,
                username    : userData?.username,
                mobile_number : userData?.mobile_number,
                address     : userData?.address,
                country     : userCountry,
                pin         : userData?.pin,
                picture     : profilePicture
            }))
        }
    }

    // Selects data related to the profileUpdate action from the Redux store state.
    const  profileData  = useSelector((state) => state.userProfileUpdate.userProfileUpdate)
    useEffect(() => {
        if (profileData && profileData?.data?.status) {
            dispatch({ type: 'HIDE_LOADER' });
            toast.success(profileData?.data?.message[0],{theme: "dark",});
            dispatch(clearAdminMsg())
            navigate("/admin/users");
        }
    }, [profileData, navigate, dispatch]);
    
    return (
        <>
            {/* Conditional rendering for the loader */}
            {isLoading && <div className="loader-overlay"><Spinner animation="border" role="status"> </Spinner></div>}
            <main className="set_gallery_bg">
                <section className="profile_elements pt_60 pb_60">
                    <div className="container">
                        <div className="profile_card">
                            <h4 className="prof_title">EDIT USER PROFILE</h4>
                            <form onSubmit={handleProfileUpdate} onKeyDown={(e) => { if (e.key === 'Enter') e.preventDefault()}}>
                                <div className="d-flex prof_from">
                                    <div className="d-flex avatar_elements">
                                        <div className="avatar-upload">
                                            <div className="avatar-edit">
                                                <input type='file' id="imageUpload" name="profilePicture" onChange={(e) => handelProfilePhoto(e)} accept=".png, .jpg, .jpeg" />
                                                <label htmlFor="imageUpload">< FaPencilAlt/></label>
                                            </div>
                                            <div className="avatar-preview">
                                                <div id="imagePreview" style={{ backgroundImage: `url(${ profilePicture ? profilePicture :  userData?.picture ? userData?.picture : profilePic})` }}>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="d-flex profile_uploadField">
                                            <fieldset className=" form_fields_prof ">
                                                <label htmlFor="">First name<span className="text-danger">*</span></label>
                                                <input
                                                    type="text" id="firstname" 
                                                    name="firstname"
                                                    value={userData.firstname || ''}
                                                    onChange={handleProfileUpdateForm}
                                                    placeholder="Enter First Name" 
                                                    maxLength="50"
                                                    />
                                                {error && !userData?.firstname && (
                                                    <span className="requiredInput" >
                                                        Required*
                                                    </span>
                                                )}
                                            </fieldset>
                                            <fieldset className=" form_fields_prof ">
                                                <label htmlFor="">Last name<span className="text-danger">*</span></label>
                                                <input
                                                    type="text" id="lastname" 
                                                    name="lastname"
                                                    value={userData.lastname || ''}
                                                    onChange={handleProfileUpdateForm}
                                                    placeholder="Enter Last Name" 
                                                    maxLength="50"
                                                    />
                                                {error && !userData?.lastname && (
                                                    <span className="requiredInput" >
                                                        Required*
                                                    </span>
                                                )}
                                            </fieldset>
                                        </div>
                                    </div>
                                    
                                    <fieldset className="form_fields_prof">
                                        <label htmlFor="">Email<span className="text-danger">*</span></label>
                                        <input
                                            readOnly
                                            type="email"
                                            value={userData.email || ''}
                                            onChange={handleProfileUpdateForm}
                                            name="email"
                                            placeholder="Enter Email"
                                            maxLength="50"
                                            style={{opacity: "0.5"}}
                                        />
                                        {error && !userData?.email && (
                                            <span className="requiredInput" >
                                                Required*
                                            </span>
                                        )}
                                    </fieldset>
                                       
                                    <fieldset className="form_fields_prof">
                                        <label htmlFor="">Username<span className="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            value={userData.username || ''}
                                            onChange={handleProfileUpdateForm}
                                            name="username"
                                            placeholder="Enter User Name"
                                            maxLength="50"
                                        />
                                        {error && !userData?.email && (
                                            <span className="requiredInput" >
                                                Required*
                                            </span>
                                        )}
                                    </fieldset>
                                    <fieldset className=" form_fields_prof">
                                        <label htmlFor="">Mobile No<span className="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            value={userData?.mobile_number || ''}
                                            onChange={handleProfileUpdateForm}
                                            name="mobile_number"
                                            placeholder="Enter Mob No."
                                            maxLength="15"
                                            // Validate input against regex pattern
                                            onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^0-9\-()+]/g, '');
                                            }}
                                        />
                                        {error && !userData?.mobile_number && (
                                            <span className="requiredInput" >
                                                Required*
                                            </span>
                                        )}
                                    </fieldset>
                                    <fieldset className=" form_fields_prof">
                                        <label>Address<span className="text-danger">*</span></label>
                                        <input
                                            type="text" id="address" name="address"
                                            value={userData?.address || ''}
                                            onChange={handleProfileUpdateForm}
                                            placeholder="Enter Address"
                                            maxLength="255"
                                        />
                                        {error && !userData?.address && (
                                            <span className="requiredInput" >
                                                Required*
                                            </span>
                                        )}
                                    </fieldset>
                                    <fieldset className=" form_fields_prof">
                                        <label>Zip Code<span className="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            placeholder="Enter Zip Code"
                                            name="pin"
                                            maxLength="6"
                                            value={userData?.pin || ''}
                                            onChange={handleProfileUpdateForm}
                                            // Validate input against regex pattern
                                            onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                            }}
                                        />
                                        {error && !userData?.pin && (
                                            <span className="requiredInput" >
                                                Required*
                                            </span>
                                        )}
                                    </fieldset>
                                    <fieldset className=" form_fields_prof">
                                        <label>Country<span className="text-danger">*</span></label>
                                        <Select
                                            className="select"
                                            isClearable
                                            options={countries?.map((data) => {
                                                return {
                                                    label: data.name,
                                                    value: data.name,
                                                };
                                            })}
                                            value={userCountry ? { label: userCountry, value: userCountry } : null}
                                            onChange={(e) => setUserCountry(e ? e.value : null)}
                                            placeholder="Select Country"
                                        />
                                        {error && !userCountry && (
                                            <span className="requiredInput" >
                                                Required*
                                            </span>
                                        )}
                                    </fieldset>
                                </div>
                                <div className="upload_btn d-flex justify-content-center">
                                    <button type="submit" className="loadMore_action">Save</button>
                                </div>
                            </form>
                        </div>

                    </div>
                </section>
            </main>
        </>
    );
}

export default UserProfileUpdate;


