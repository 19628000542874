
/******************* Constants For Admin ******************/
export const userListConstants = {
    GET_USERLIST_SUCCESS: 'GET_USERLIST_SUCCESS',
    GET_USERLIST_FAILURE: 'GET_USERLIST_FAILURE'
}

export const contactListConstants = {
    GET_CONTACTLIST_SUCCESS: 'GET_CONTACTLIST_SUCCESS',
    GET_CONTACTLIST_FAILURE: 'GET_CONTACTLIST_FAILURE'
}

export const contactStatusConstants = {
    GET_CONTACTSTATUS_SUCCESS: 'GET_CONTACTSTATUS_SUCCESS',
    GET_CONTACTSTATUS_FAILURE: 'GET_CONTACTSTATUS_FAILURE'
}

export const allPaymentsPayoutConstants = {
    GET_ALLPAYMENTSPAYOUT_SUCCESS: 'GET_ALLPAYMENTSPAYOUT_SUCCESS',
    GET_ALLPAYMENTSPAYOUT_FAILURE: 'GET_ALLPAYMENTSPAYOUT_FAILURE'
}

export const viewUserProfileConstants = {
    GET_VIEWUSERPROFILE_SUCCESS: 'GET_VIEWUSERPROFILE_SUCCESS',
    GET_VIEWUSERPROFILE_FAILURE: 'GET_VIEWUSERPROFILE_FAILURE'
}

export const userProfileUpdateConstants = {
    GET_USERPROFILEUPDATE_SUCCESS: 'GET_USERPROFILEUPDATE_SUCCESS',
    GET_USERPROFILEUPDATE_FAILURE: 'GET_USERPROFILEUPDATE_FAILURE'
}

export const sequenceByUserConstants = {
    GET_SEQUENCEBYUSER_SUCCESS: 'GET_SEQUENCEBYUSER_SUCCESS',
    GET_SEQUENCEBYUSER_FAILURE: 'GET_SEQUENCEBYUSER_FAILURE'
}

export const sequenceListConstants = {
    GET_SEQUENCELIST_SUCCESS: 'GET_SEQUENCELIST_SUCCESS',
    GET_SEQUENCELIST_FAILURE: 'GET_SEQUENCELIST_FAILURE'
}

export const sequenceApproveConstants = {
    GET_SEQUENCEAPPROVE_SUCCESS: 'GET_SEQUENCEAPPROVE_SUCCESS',
    GET_SEQUENCEAPPROVE_FAILURE: 'GET_SEQUENCEAPPROVE_FAILURE'
}

export const viewSequenceConstants = {
    GET_VIEWSEQUENCE_SUCCESS: 'GET_VIEWSEQUENCE_SUCCESS',
    GET_VIEWSEQUENCE_FAILURE: 'GET_VIEWSEQUENCE_FAILURE'
}

export const updateSequenceByAdminConstants = {
    GET_UPDATESEQUENCEBYADMIN_SUCCESS: 'GET_UPDATESEQUENCEBYADMIN_SUCCESS',
    GET_UPDATESEQUENCEBYADMIN_FAILURE: 'GET_UPDATESEQUENCEBYADMIN_FAILURE'
}

export const getDefaultPriceConstants = {
    GET_GETDEFAULTPRICE_SUCCESS: 'GET_GETDEFAULTPRICE_SUCCESS',
    GET_GETDEFAULTPRICE_FAILURE: 'GET_GETDEFAULTPRICE_FAILURE'
}

export const setDefaultPriceConstants = {
    GET_SETDEFAULTPRICE_SUCCESS: 'GET_SETDEFAULTPRICE_SUCCESS',
    GET_SETDEFAULTPRICE_FAILURE: 'GET_SETDEFAULTPRICE_FAILURE'
}

// For continent locations
export const continentListConstants = {
    GET_CONTINENTLIST_SUCCESS: 'GET_CONTINENTLIST_SUCCESS',
    GET_CONTINENTLIST_FAILURE: 'GET_CONTINENTLIST_FAILURE'
}

export const createContinentConstants = {
    GET_CREATECONTINENT_SUCCESS: 'GET_CREATECONTINENT_SUCCESS',
    GET_CREATECONTINENT_FAILURE: 'GET_CREATECONTINENT_FAILURE'
}

export const continentStatusConstants = {
    GET_CONTINENTSTATUS_SUCCESS: 'GET_CONTINENTSTATUS_SUCCESS',
    GET_CONTINENTSTATUS_FAILURE: 'GET_CONTINENTSTATUS_FAILURE'
}

export const editContinentConstants = {
    GET_EDITCONTINENT_SUCCESS: 'GET_EDITCONTINENT_SUCCESS',
    GET_EDITCONTINENT_FAILURE: 'GET_EDITCONTINENT_FAILURE'
}

export const updateContinentConstants = {
    GET_UPDATECONTINENT_SUCCESS: 'GET_UPDATECONTINENT_SUCCESS',
    GET_UPDATECONTINENT_FAILURE: 'GET_UPDATECONTINENT_FAILURE'
}

export const deleteContinentConstants = {
    GET_DELETECONTINENT_SUCCESS: 'GET_DELETECONTINENT_SUCCESS',
    GET_DELETECONTINENT_FAILURE: 'GET_DELETECONTINENT_FAILURE'
}


// For region locations
export const regionListConstants = {
    GET_REGIONLIST_SUCCESS: 'GET_REGIONLIST_SUCCESS',
    GET_REGIONLIST_FAILURE: 'GET_REGIONLIST_FAILURE'
}

export const createRegionConstants = {
    GET_CREATEREGION_SUCCESS: 'GET_CREATEREGION_SUCCESS',
    GET_CREATEREGION_FAILURE: 'GET_CREATEREGION_FAILURE'
}

export const regionStatusConstants = {
    GET_REGIONSTATUS_SUCCESS: 'GET_REGIONSTATUS_SUCCESS',
    GET_REGIONSTATUS_FAILURE: 'GET_REGIONSTATUS_FAILURE'
}

export const editRegionConstants = {
    GET_EDITREGION_SUCCESS: 'GET_EDITREGION_SUCCESS',
    GET_EDITREGION_FAILURE: 'GET_EDITREGION_FAILURE'
}

export const updateRegionConstants = {
    GET_UPDATEREGION_SUCCESS: 'GET_UPDATEREGION_SUCCESS',
    GET_UPDATEREGION_FAILURE: 'GET_UPDATEREGION_FAILURE'
}

export const deleteRegionConstants = {
    GET_DELETEREGION_SUCCESS: 'GET_DELETEREGION_SUCCESS',
    GET_DELETEREGION_FAILURE: 'GET_DELETEREGION_FAILURE'
}

// For area locations
export const areaListConstants = {
    GET_AREALIST_SUCCESS: 'GET_AREALIST_SUCCESS',
    GET_AREALIST_FAILURE: 'GET_AREALIST_FAILURE'
}

export const createAreaConstants = {
    GET_CREATEAREA_SUCCESS: 'GET_CREATEAREA_SUCCESS',
    GET_CREATEAREA_FAILURE: 'GET_CREATEAREA_FAILURE'
}

export const areaStatusConstants = {
    GET_AREASTATUS_SUCCESS: 'GET_AREASTATUS_SUCCESS',
    GET_AREASTATUS_FAILURE: 'GET_AREASTATUS_FAILURE'
}

export const editAreaConstants = {
    GET_EDITAREA_SUCCESS: 'GET_EDITAREA_SUCCESS',
    GET_EDITAREA_FAILURE: 'GET_EDITAREA_FAILURE'
}

export const updateAreaConstants = {
    GET_UPDATEAREA_SUCCESS: 'GET_UPDATEAREA_SUCCESS',
    GET_UPDATEAREA_FAILURE: 'GET_UPDATEAREA_FAILURE'
}

export const deleteAreaConstants = {
    GET_DELETEAREA_SUCCESS: 'GET_DELETEAREA_SUCCESS',
    GET_DELETEAREA_FAILURE: 'GET_DELETEAREA_FAILURE'
}

// For apot locations
export const spotListConstants = {
    GET_SPOTLIST_SUCCESS: 'GET_SPOTLIST_SUCCESS',
    GET_SPOTLIST_FAILURE: 'GET_SPOTLIST_FAILURE'
}

export const createSpotConstants = {
    GET_CREATESPOT_SUCCESS: 'GET_CREATESPOT_SUCCESS',
    GET_CREATESPOT_FAILURE: 'GET_CREATESPOT_FAILURE'
}

export const spotStatusConstants = {
    GET_SPOTSTATUS_SUCCESS: 'GET_SPOTSTATUS_SUCCESS',
    GET_SPOTSTATUS_FAILURE: 'GET_SPOTSTATUS_FAILURE'
}

export const editSpotConstants = {
    GET_EDITSPOT_SUCCESS: 'GET_EDITSPOT_SUCCESS',
    GET_EDITSPOT_FAILURE: 'GET_EDITSPOT_FAILURE'
}

export const updateSpotConstants = {
    GET_UPDATESPOT_SUCCESS: 'GET_UPDATESPOT_SUCCESS',
    GET_UPDATESPOT_FAILURE: 'GET_UPDATESPOT_FAILURE'
}

export const deleteSpotConstants = {
    GET_DELETESPOT_SUCCESS: 'GET_DELETESPOT_SUCCESS',
    GET_DELETESPOT_FAILURE: 'GET_DELETESPOT_FAILURE'
}

export const clearAdminMessage = {
    CLEAR_ADMIN_MESSAGE: 'CLEAR_ADMIN_MESSAGE',
}