import React from "react";

const AdminDashboard = () => {

    return (
        <>
            <h1>Welcome To Admin Dashboard</h1>
        </>
    );
}

export default AdminDashboard;