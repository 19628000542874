import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { clearAdminMsg, editArea, updateArea } from "../../../../actions/adminAction";
import { toast } from "react-toastify";
import Select from "react-select";
import { getAllLocation } from "../../../../actions/commonAction";
import { Spinner } from "react-bootstrap";

const UpdateArea = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [error, setError] = useState(false);
    const [areaName, setAreaName] = useState();
    const [selectedRegion, setSelectedRegion] = useState("");
    const [allRegions, setAllRegions] = useState([]);

    useEffect(()=>{
        dispatch({ type: 'SHOW_LOADER' }); // Set loader to true
        dispatch(editArea({ id : id}))
        dispatch(getAllLocation())
    },[dispatch,id])

    // Selects data related to the loader action from the Redux store state.
    const isLoading = useSelector(state => state.loader.isLoading);
    // Selects data related to the createSequence action from the Redux store state.
    const { allLocation } = useSelector((state) => state.getAllLocation.getAllLocation)
    useEffect(() => {    
        if (allLocation && allLocation.status) {
            dispatch({ type: 'HIDE_LOADER' });
            setAllRegions(allLocation?.data?.regions);
        }
    }, [allLocation,dispatch]);

    // Selects data related to the editArea action from the Redux store state.
    const  { areaData }  = useSelector((state) => state.editArea.editArea)
    useEffect(() => {
        if (areaData && areaData?.status) {
            dispatch({ type: 'HIDE_LOADER' });
            setAreaName(areaData?.data?.list?.name)
            setSelectedRegion(areaData?.data?.list?.region_id)
        }
    }, [areaData, navigate, dispatch]);

    const handleFormUpdate = async (e) =>{
        e.preventDefault();
        if(!areaName){
            dispatch({ type: 'HIDE_LOADER' });
            setError(true);
            return false;
        }else{
            try {
                dispatch({ type: 'SHOW_LOADER' });
                await dispatch(updateArea({
                    id   : id,
                    name : areaName,
                    region_id : selectedRegion
                }))
            } catch (error) {
                dispatch({ type: 'HIDE_LOADER' });
                console.error("Error updating profile:", error);
            }
        }
    }

    // Selects data related to the updateContitent action from the Redux store state.
    const  { data }  = useSelector((state) => state.updateArea.updateArea)
    useEffect(() => {
        if (data && data?.status) {
            dispatch({ type: 'HIDE_LOADER' });
            toast.success(data?.message[0],{theme: "dark",});
            dispatch(clearAdminMsg())
            navigate("/admin/areas");
        }
    }, [data, navigate, dispatch]);

    return(
        <>
        {/* Conditional rendering for the loader */}
            {isLoading && <div className="loader-overlay"><Spinner animation="border" role="status"> </Spinner></div>}
            <main className="set_gallery_bg">
                <section className="profile_elements pt_60 pb_60">
                    <div className="container">
                        <div className="profile_card">
                            <h4 className="prof_title">UPDATE AREA</h4>
                            <form onSubmit={handleFormUpdate} onKeyDown={(e) => { if (e.key === 'Enter') e.preventDefault()}}>
                                <div className="d-flex prof_from mt-2">
                                    <fieldset className="form_fields_prof">
                                        <label htmlFor="">Region<span className="text-danger">*</span></label>
                                        <Select
                                            className="select"
                                            isClearable
                                            options={allRegions?.map((data) => {
                                                return {
                                                    label: data.name,
                                                    value: data.id,
                                                };
                                            })}
                                            value={[allRegions?.map((data) => {
                                                return {
                                                    label: data.name,
                                                    value: data.id,
                                                };
                                            }).find(option => option.value === selectedRegion)]}
                                            // value={[findById(createOptionsArray(allRegions, 'name', 'id'), selectedRegion)]}
                                            onChange={(e) => setSelectedRegion(e.value)}
                                            placeholder="Select Regions"
                                        />
                                        {error && !selectedRegion && (
                                            <span className="requiredInput" >
                                                Required*
                                            </span>
                                        )}
                                    </fieldset>
                                    <fieldset className="form_fields_prof">
                                        <label htmlFor="">Area Name<span className="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            value={areaName || ''}
                                            onChange={(e) => setAreaName(e.target.value)}
                                            name="name"
                                            placeholder="Enter Area Name"
                                            maxLength="50"
                                        />
                                        {error && !areaName && (
                                            <span className="requiredInput" >
                                                Required*
                                            </span>
                                        )}
                                    </fieldset>
                                </div>
                                <div className="upload_btn justify-content-center">
                                    <button type="submit" className="loadMore_action">Update</button>
                                </div>
                            </form>
                        </div>

                    </div>
                </section>
            </main>
        </>
    );
}

export default UpdateArea;