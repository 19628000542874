import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearAdminMsg, createContinent } from "../../../../actions/adminAction";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

const CreateContinent = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [error, setError] = useState(false);
    const [continentName, setContinentName] = useState();

    // Selects data related to the loader action from the Redux store state.
    const isLoading = useSelector(state => state.loader.isLoading);
    
    const handleFormSubmit = async (e) =>{
        e.preventDefault();
        if(!continentName){
            dispatch({ type: 'HIDE_LOADER' });
            setError(true);
            return false;
        }else{
            try {
                dispatch({ type: 'SHOW_LOADER' });
                await dispatch(createContinent({
                    name   : continentName,
                }))
            } catch (error) {
                dispatch({ type: 'HIDE_LOADER' });
                console.error("Error updating profile:", error);
            }
        }
    }

    // Selects data related to the createContitent action from the Redux store state.
    const  { data }  = useSelector((state) => state.createContinent.createContinent)
    useEffect(() => {
        if (data && data?.status) {
            dispatch({ type: 'HIDE_LOADER' });
            toast.success(data?.message[0],{theme: "dark",});
            dispatch(clearAdminMsg())
            navigate("/admin/continents");
        }
    }, [data, navigate, dispatch]);

    return(
        <>
            {/* Conditional rendering for the loader */}
            {isLoading && <div className="loader-overlay"><Spinner animation="border" role="status"> </Spinner></div>}
            <main className="set_gallery_bg">
                <section className="profile_elements pt_60 pb_60">
                    <div className="container">
                        <div className="profile_card">
                            <h4 className="prof_title">CREATE CONTINENT</h4>
                            <form onSubmit={handleFormSubmit} onKeyDown={(e) => { if (e.key === 'Enter') e.preventDefault()}}>
                                <div className="d-flex prof_from mt-2">
                                    <fieldset className="form_fields_prof">
                                        <label htmlFor="">Continent Name<span className="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            value={continentName || ''}
                                            onChange={(e) => setContinentName(e.target.value)}
                                            name="name"
                                            placeholder="Enter Continent Name"
                                            maxLength="50"
                                        />
                                        {error && !continentName && (
                                            <span className="requiredInput" >
                                                Required*
                                            </span>
                                        )}
                                    </fieldset>
                                </div>
                                <div className="upload_btn justify-content-center">
                                    <button type="submit" className="loadMore_action">Create</button>
                                </div>
                            </form>
                        </div>

                    </div>
                </section>
            </main>
        </>
    );
}

export default CreateContinent;