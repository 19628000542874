import React from "react";

const UserDashboard = () => {

    return (
        <>
            <h1>Welcome to user Dashboard</h1>
        </>
    );
}

export default UserDashboard;