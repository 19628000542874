import axios from "../services/axios";
import { userListConstants, viewUserProfileConstants, userProfileUpdateConstants, sequenceListConstants, sequenceApproveConstants, viewSequenceConstants, clearAdminMessage, updateSequenceByAdminConstants, sequenceByUserConstants, setDefaultPriceConstants, getDefaultPriceConstants, continentListConstants, createContinentConstants, deleteContinentConstants, continentStatusConstants, editContinentConstants, updateContinentConstants, regionListConstants, createRegionConstants, deleteRegionConstants, regionStatusConstants, editRegionConstants, updateRegionConstants, updateAreaConstants, editAreaConstants, areaStatusConstants, deleteAreaConstants, createAreaConstants, areaListConstants, spotListConstants, createSpotConstants, deleteSpotConstants, spotStatusConstants, editSpotConstants, updateSpotConstants, contactListConstants, contactStatusConstants, allPaymentsPayoutConstants } from "../constants/admin.constants";

// Actions for users
export const getUserList = () => {
    return (dispatch) => {
      axios.get("/user/list").then((response) => {
          if (response.data.hasOwnProperty("errors")) {
            alert(response.data.message);
          } else {
            dispatch({ type: userListConstants.GET_USERLIST_SUCCESS, payload: { data: response.data },});
          }
        })
        .catch((err) => {
          dispatch({ type: 'HIDE_LOADER' });
          console.log(err);
        });
    };
};

export const viewUserProfile = (id) => {
    return (dispatch) => {
      axios.post(`/me/${id}`).then((response) => {
          if (response.data.hasOwnProperty("errors")) {
            alert(response.data.message);
          } else {
            dispatch({ type: viewUserProfileConstants.GET_VIEWUSERPROFILE_SUCCESS, payload: { data: response.data },});
            // redirect();
          }
        })
        .catch((err) => {
          dispatch({ type: 'HIDE_LOADER' });
          console.log(err);
        });
    };
};

export const userProfileUpdate = (id,formData) => {
    return (dispatch) => {
      axios.put(`/profile/${id}`,formData).then((response) => {
          if (response.data.hasOwnProperty("errors")) {
            alert(response.data.message);
          } else {
            dispatch({ type: userProfileUpdateConstants.GET_USERPROFILEUPDATE_SUCCESS, payload: { data: response.data },});
          }
        })
        .catch((err) => {
          dispatch({ type: 'HIDE_LOADER' });
          console.log(err);
        });
    };
};

//Action for users contact 
export const getContactList = () => {
  return (dispatch) => {
    axios.get("/contact-us").then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: contactListConstants.GET_CONTACTLIST_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const userContactStatus = (formData) => {
  return (dispatch) => {
    axios.post("/contact-us/status",formData).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: contactStatusConstants.GET_CONTACTSTATUS_SUCCESS, payload: { contactStatus : response.data },});
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

//Action for all payouts 
export const allPaymentsPayout = () => {
  return (dispatch) => {
    axios.get('/payments/payout/list').then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: allPaymentsPayoutConstants.GET_ALLPAYMENTSPAYOUT_SUCCESS, payload: { data: response.data },});
          // redirect();
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

// Actions for sequence
export const getSequenceByUser = (id,page) => {
  return (dispatch) => {
    axios.get(`/sequence/list-all/${id}?page=${page}`).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: sequenceByUserConstants.GET_SEQUENCEBYUSER_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const getSequenceList = (page) => {
  return (dispatch) => {
    axios.get(`/sequence/list-all?page=${page}`).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: sequenceListConstants.GET_SEQUENCELIST_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const updateSequenceApprove = (formData) => {
  return (dispatch) => {
    axios.post(`/sequence/approve`,formData).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: sequenceApproveConstants.GET_SEQUENCEAPPROVE_SUCCESS, payload: { data: response.data },});
          // redirect();
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const viewSequence = (id) => {
  return (dispatch) => {
    axios.get(`/sequence/list-image-all/${id}`).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: viewSequenceConstants.GET_VIEWSEQUENCE_SUCCESS, payload: { sequenceData: response.data },});
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};


export const updateSequenceByAdmin = (formData) => {
  return (dispatch) => {
    axios.put("/sequence/update", formData).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: updateSequenceByAdminConstants.GET_UPDATESEQUENCEBYADMIN_SUCCESS, payload: { data: response.data },});
          // redirect();
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const getDefaultPrice = () => {
  return (dispatch) => {
    axios.get("/sequence/price").then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: getDefaultPriceConstants.GET_GETDEFAULTPRICE_SUCCESS, payload: { priceData: response.data },});
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const setDefaultPrice = (formData) => {
  return (dispatch) => {
    axios.post("sequence/price",formData).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: setDefaultPriceConstants.GET_SETDEFAULTPRICE_SUCCESS, payload: { data: response.data },});
          // redirect();
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

// For Continent loctions
export const getContinentList = () => {
  return (dispatch) => {
    axios.get("/continent/list").then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: continentListConstants.GET_CONTINENTLIST_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const createContinent = (formData) => {
  return (dispatch) => {
    axios.post("/continent/store",formData).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: createContinentConstants.GET_CREATECONTINENT_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const deleteContinent = (formData) => {
  return (dispatch) => {
    // Assuming formData is an object with key-value pairs
    const queryString = Object.keys(formData).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(formData[key])}`).join('&');
    axios.delete(`/continent/destroy?${queryString}`).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: deleteContinentConstants.GET_DELETECONTINENT_SUCCESS, payload: { deleteResponse: response.data },});
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const continentStatus = (formData) => {
  return (dispatch) => {
    axios.post("/continent/status",formData).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: continentStatusConstants.GET_CONTINENTSTATUS_SUCCESS, payload: { statusResponse: response.data },});
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const editContinent = (formData) => {
  return (dispatch) => {
    axios.post("/continent/edit",formData).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: editContinentConstants.GET_EDITCONTINENT_SUCCESS, payload: { continentData : response.data },});
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const updateContinent = (formData) => {
  return (dispatch) => {
    axios.put("/continent/update",formData).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: updateContinentConstants.GET_UPDATECONTINENT_SUCCESS, payload: { data : response.data },});
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

// For Region loctions
export const getRegionList = () => {
  return (dispatch) => {
    axios.get("/region/list").then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: regionListConstants.GET_REGIONLIST_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const createRegion = (formData) => {
  return (dispatch) => {
    axios.post("/region/store",formData).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: createRegionConstants.GET_CREATEREGION_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const deleteRegion = (formData) => {
  return (dispatch) => {
    // Assuming formData is an object with key-value pairs
    const queryString = Object.keys(formData).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(formData[key])}`).join('&');
    axios.delete(`/region/destroy?${queryString}`).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: deleteRegionConstants.GET_DELETEREGION_SUCCESS, payload: { deleteResponse: response.data },});
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const regionStatus = (formData) => {
  return (dispatch) => {
    axios.post("/region/status",formData).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: regionStatusConstants.GET_REGIONSTATUS_SUCCESS, payload: { statusResponse: response.data },});
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const editRegion = (formData) => {
  return (dispatch) => {
    axios.post("/region/edit",formData).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: editRegionConstants.GET_EDITREGION_SUCCESS, payload: { regionData : response.data },});
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const updateRegion = (formData) => {
  return (dispatch) => {
    axios.put("/region/update",formData).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: updateRegionConstants.GET_UPDATEREGION_SUCCESS, payload: { data : response.data },});
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

// For Area loctions
export const getAreaList = () => {
  return (dispatch) => {
    axios.get("/area/list").then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: areaListConstants.GET_AREALIST_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const createArea = (formData) => {
  return (dispatch) => {
    axios.post("/area/store",formData).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: createAreaConstants.GET_CREATEAREA_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const deleteArea = (formData) => {
  return (dispatch) => {
    // Assuming formData is an object with key-value pairs
    const queryString = Object.keys(formData).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(formData[key])}`).join('&');
    axios.delete(`/area/destroy?${queryString}`).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: deleteAreaConstants.GET_DELETEAREA_SUCCESS, payload: { deleteResponse: response.data },});
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const areaStatus = (formData) => {
  return (dispatch) => {
    axios.post("/area/status",formData).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: areaStatusConstants.GET_AREASTATUS_SUCCESS, payload: { statusResponse: response.data },});
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const editArea = (formData) => {
  return (dispatch) => {
    axios.post("/area/edit",formData).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: editAreaConstants.GET_EDITAREA_SUCCESS, payload: { areaData : response.data },});
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const updateArea = (formData) => {
  return (dispatch) => {
    axios.put("/area/update",formData).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: updateAreaConstants.GET_UPDATEAREA_SUCCESS, payload: { data : response.data },});
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

// For Spot loctions
export const getSpotList = () => {
  return (dispatch) => {
    axios.get("/spot/list").then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: spotListConstants.GET_SPOTLIST_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const createSpot = (formData) => {
  return (dispatch) => {
    axios.post("/spot/store",formData).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: createSpotConstants.GET_CREATESPOT_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const deleteSpot = (formData) => {
  return (dispatch) => {
    // Assuming formData is an object with key-value pairs
    const queryString = Object.keys(formData).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(formData[key])}`).join('&');
    axios.delete(`/spot/destroy?${queryString}`).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: deleteSpotConstants.GET_DELETESPOT_SUCCESS, payload: { deleteResponse: response.data },});
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const spotStatus = (formData) => {
  return (dispatch) => {
    axios.post("/spot/status",formData).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: spotStatusConstants.GET_SPOTSTATUS_SUCCESS, payload: { statusResponse: response.data },});
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const editSpot = (formData) => {
  return (dispatch) => {
    axios.post("/spot/edit",formData).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: editSpotConstants.GET_EDITSPOT_SUCCESS, payload: { spotData : response.data },});
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const updateSpot = (formData) => {
  return (dispatch) => {
    axios.put("/spot/update",formData).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: updateSpotConstants.GET_UPDATESPOT_SUCCESS, payload: { data : response.data },});
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const clearAdminMsg = () => async (dispatch) => {
  try {
      dispatch({ type: clearAdminMessage.CLEAR_ADMIN_MESSAGE, payload: [] })
  } catch (err) {
      console.log(`Error while calling login api ${err}`);
  }
}


