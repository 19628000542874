import { getAllLocationConstants, getContinentRegionsConstants, getRegionAreasConstants, getAreaSpotsConstants } from "../constants/common.constants";
import axios from "../services/axios";

// Common Actions
export const getAllLocation = () => {
    return (dispatch) => {
      axios.get("/get/all/locations").then((response) => {
          if (response.data.hasOwnProperty("errors")) {
            alert(response.data.message);
          } else {
            dispatch({ type: getAllLocationConstants.GET_GETALLLOCATION_SUCCESS, payload: { allLocation : response.data },});
          }
        })
        .catch((err) => {
          dispatch({ type: 'HIDE_LOADER' });
          console.log(err);
        });
    };
};

// get all the regions behalf of continent
export const getContinentRegionsAction = (formData) => {
  return (dispatch) => {
    axios.post("/get/continent/regions",formData).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: getContinentRegionsConstants.GET_GETCONTINENTREGIONS_SUCCESS, payload: { getContinentRegions : response.data },});
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

// get all the areas behalf of region
export const getRegionAreasAction = (formData) => {
  return (dispatch) => {
    axios.post("/get/region/areas",formData).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: getRegionAreasConstants.GET_GETREGIONAREAS_SUCCESS, payload: { getRegionAreas : response.data },});
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

// get all the spots behalf of area
export const getAreaSpotsAction = (formData) => {
  return (dispatch) => {
    axios.post("/get/area/spots",formData).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: getAreaSpotsConstants.GET_GETAREASPOTS_SUCCESS, payload: { getAreaSpots : response.data },});
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};