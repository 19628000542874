import React, { useEffect, useState } from "react";
import contact1 from '../../../assets/images/contactUsFinal.png';
import contact2 from '../../../assets/images/contact1.png';
import { Link } from "react-router-dom";
import { contactUsAction } from "../../../actions/homeAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const ContactUs = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: 'CLEAR_SEARCHSEQUENCE_MESSAGE' });
    },[dispatch])
    
    const [contactDetail, setContactDetails ] = useState({
        name: '',email: '', details: ''
    })
    const [error, setError] = useState(false);

    const handelContactdetails = (e) => {
        setContactDetails({
            ...contactDetail,
            [e.target.name]: e.target.value
        })
    } 

    //function to submit contact details
    const handleContact = async (e) => {
        e.preventDefault();
        if( !contactDetail?.name || !contactDetail?.email || !contactDetail?.details ){
            setError(true);
            return false;
        }else{
            dispatch({ type: 'SHOW_LOADER' }); // Set loader to true
            try {
                await dispatch(contactUsAction({
                    name        : contactDetail?.name,
                    email        : contactDetail?.email,
                    detail      : contactDetail?.details,
                }))
            } catch (error) {
                dispatch({ type: 'HIDE_LOADER' });
                console.error("Error creating sequence:", error);
            }
        }
    }

    // Selects data related to the contactUs action from the Redux store state.
    const { data } = useSelector((state) => state.contactUs.contactUs)
    useEffect(() => {
        if (data && data.status) {
            toast.success(data?.message[0],{theme: "dark",});
            dispatch({ type: 'HIDE_LOADER' });
            setContactDetails("");
        }
    }, [data, dispatch]);

    return(
        <>
            <main className="set_gallery_bg">
                <section className="sec_waves sec_contactUs">
                    <div className="container-fluid containerHeader">
                        <div className="row">
                            <div className="col-md-4">
                                <h2 className="waves_title">Contact Us</h2>
                                <p className="contact_subTitle">We are here to help you!</p>
                            </div>
                            <div className="waves_bgImg">
                                <img src={contact1} alt=""/>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="sec_way search_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="image_surf">
                                    <img src={contact2} alt="" className="img-fluid"/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form_contact">
                                    <form onSubmit={handleContact}>
                                        <div className="mb-3 contact_info">
                                            <label htmlFor="nameSet" className="form-label">Full Name</label>
                                            <input className="form-control" type="text" id="nameSet" name="name"
                                                value={contactDetail?.name || ''}
                                                onChange={handelContactdetails}
                                                placeholder="Enter Your Name"
                                                maxLength="50"
                                            />
                                            {error && !contactDetail?.name && (
                                                <span className="requiredInput" >
                                                    Required*
                                                </span>
                                            )}
                                        </div>
                                        <div className="mb-3 contact_info">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                                            <input className="form-control" aria-describedby="emailHelp"
                                                type="email" id="exampleInputEmail1" name="email"
                                                value={contactDetail?.email || ''}
                                                onChange={handelContactdetails}
                                                placeholder="Enter Your Email"
                                                maxLength="50"
                                            />
                                            {error && !contactDetail?.email && (
                                                <span className="requiredInput" >
                                                    Required*
                                                </span>
                                            )}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlTextarea1">Message</label>
                                            <textarea className="" id="exampleFormControlTextarea1"
                                            rows="1" 
                                            name="details"
                                            value={contactDetail?.details || ''}
                                            onChange={handelContactdetails} 
                                            placeholder="Additional Information..."
                                        >
                                        </textarea>
                                        </div>
                                        <button type="submit" className="loadMore_action w-100">Send</button>

                                    </form>
                                </div>
                                <div className="call_Elements mt_40">
                                    <p className="contact_subTitle custom_support">Customer Support </p>
                                    <div className="form_calllist">
                                        <ul className="d-flex ">
                                            <li>
                                                <Link to="mailto:findmysurfpic@gmail.com" target="_blank">
                                                    <span>
                                                        <svg width="28" height="28"
                                                            viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M4.66683 4.66699H23.3335C24.6168 4.66699 25.6668 5.71699 25.6668 7.00033V21.0003C25.6668 22.2837 24.6168 23.3337 23.3335 23.3337H4.66683C3.3835 23.3337 2.3335 22.2837 2.3335 21.0003V7.00033C2.3335 5.71699 3.3835 4.66699 4.66683 4.66699Z"
                                                                fill="#FFD119" />
                                                            <path d="M25.6668 7.00033L14.0002 15.167L2.3335 7.00033"
                                                                fill="#FFD119" />
                                                            <path
                                                                d="M25.6668 7.00033C25.6668 5.71699 24.6168 4.66699 23.3335 4.66699H4.66683C3.3835 4.66699 2.3335 5.71699 2.3335 7.00033M25.6668 7.00033V21.0003C25.6668 22.2837 24.6168 23.3337 23.3335 23.3337H4.66683C3.3835 23.3337 2.3335 22.2837 2.3335 21.0003V7.00033M25.6668 7.00033L14.0002 15.167L2.3335 7.00033"
                                                                stroke="#101828" strokeWidth="2" strokeLinecap="round"
                                                                strokeLinejoin="round" />
                                                        </svg>
                                                    </span>findmysurfpic@gmail.com
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
        </>
    );
}

export default ContactUs;