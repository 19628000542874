import { userListConstants, viewUserProfileConstants, userProfileUpdateConstants, sequenceListConstants, sequenceApproveConstants, viewSequenceConstants, clearAdminMessage, updateSequenceByAdminConstants, sequenceByUserConstants, getDefaultPriceConstants, setDefaultPriceConstants, continentListConstants, deleteContinentConstants, createContinentConstants, continentStatusConstants, editContinentConstants, updateContinentConstants, regionListConstants, createRegionConstants, regionStatusConstants, deleteRegionConstants, editRegionConstants, updateRegionConstants, areaListConstants, createAreaConstants, areaStatusConstants, deleteAreaConstants, editAreaConstants, updateAreaConstants, updateSpotConstants, editSpotConstants, deleteSpotConstants, spotStatusConstants, createSpotConstants, spotListConstants, contactListConstants, contactStatusConstants, allPaymentsPayoutConstants } from "../constants/admin.constants";

/************************** Reducer For Admin/User *******************************/
export const userListReducer = (state = { userList : [] }, action) => {
    switch (action.type) {
        case userListConstants.GET_USERLIST_SUCCESS:
            return { userList: action.payload };
        case userListConstants.GET_USERLIST_FAILURE:
            return { error: action.payload };
        // case clearAdminMessage.CLEAR_ADMIN_MESSAGE:
        //     return { userList: [], error: "" }
        default:
            return state;
    }
};

export const viewUserProfileReducer = (state = { viewUserProfile : [] }, action) => {
    switch (action.type) {
        case viewUserProfileConstants.GET_VIEWUSERPROFILE_SUCCESS:
            return { viewUserProfile: action.payload };
        case viewUserProfileConstants.GET_VIEWUSERPROFILE_FAILURE:
            return { error: action.payload };
        // case clearAdminMessage.CLEAR_ADMIN_MESSAGE:
        //     return { viewUserProfile: [], error: "" }
        default:
            return state;
    }
};

export const userProfileUpdateReducer = (state = { userProfileUpdate : [] }, action) => {
    switch (action.type) {
        case userProfileUpdateConstants.GET_USERPROFILEUPDATE_SUCCESS:
            return { userProfileUpdate: action.payload };
        case userProfileUpdateConstants.GET_USERPROFILEUPDATE_FAILURE:
            return { error: action.payload };
        case clearAdminMessage.CLEAR_ADMIN_MESSAGE:
            return { userProfileUpdate: [], error: "" }
        default:
            return state;
    }
};

export const contactListReducer = (state = { contactList : [] }, action) => {
    switch (action.type) {
        case contactListConstants.GET_CONTACTLIST_SUCCESS:
            return { contactList: action.payload };
        case contactListConstants.GET_CONTACTLIST_FAILURE:
            return { error: action.payload };
        // case clearAdminMessage.CLEAR_ADMIN_MESSAGE:
        //     return { userList: [], error: "" }
        default:
            return state;
    }
};

export const contactStatusReducer = (state = { contactStatus : [] }, action) => {
    switch (action.type) {
        case contactStatusConstants.GET_CONTACTSTATUS_SUCCESS:
            return { contactStatus: action.payload };
        case contactStatusConstants.GET_CONTACTSTATUS_FAILURE:
            return { error: action.payload };
        case clearAdminMessage.CLEAR_ADMIN_MESSAGE:
            return { contactStatus: [], error: "" }
        default:
            return state;
    }
};

export const allPaymentsPayoutReducer = (state = { allPaymentsPayout : [] }, action) => {
    switch (action.type) {
        case allPaymentsPayoutConstants.GET_ALLPAYMENTSPAYOUT_SUCCESS:
            return { allPaymentsPayout: action.payload };
        case allPaymentsPayoutConstants.GET_ALLPAYMENTSPAYOUT_FAILURE:
            return { error: action.payload };
        case clearAdminMessage.CLEAR_ADMIN_MESSAGE:
            return { allPaymentsPayout: [], error: "" }
        default:
            return state;
    }
};

/************************** Reducer For Admin/Sequence *******************************/
export const sequenceByUserReducer = (state = { sequenceByUser : [] }, action) => {
    switch (action.type) {
        case viewSequenceConstants.GET_VIEWSEQUENCE_SUCCESS:
            return { sequenceByUser: [] };
        case sequenceByUserConstants.GET_SEQUENCEBYUSER_SUCCESS:
            return { sequenceByUser: action.payload };
        case sequenceByUserConstants.GET_SEQUENCEBYUSER_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
};

export const sequenceListReducer = (state = { sequenceList : [] }, action) => {
    switch (action.type) {
        case viewSequenceConstants.GET_VIEWSEQUENCE_SUCCESS:
            return { sequenceList: [] };
        case sequenceListConstants.GET_SEQUENCELIST_SUCCESS:
            return { sequenceList: action.payload };
        case sequenceListConstants.GET_SEQUENCELIST_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
};

export const sequenceApproveReducer = (state = { sequenceApprove : [] }, action) => {
    switch (action.type) {
        case sequenceApproveConstants.GET_SEQUENCEAPPROVE_SUCCESS:
            return { sequenceApprove: action.payload };
        case sequenceApproveConstants.GET_SEQUENCEAPPROVE_FAILURE:
            return { error: action.payload };
        case clearAdminMessage.CLEAR_ADMIN_MESSAGE:
            return { sequenceApprove: [], error: "" }
        default:
            return state;
    }
};

export const viewSequenceReducer = (state = { viewSequence : [] }, action) => {
    switch (action.type) {
        case sequenceByUserConstants.GET_SEQUENCEBYUSER_SUCCESS:
            return { viewSequence: [] };
        case sequenceListConstants.GET_SEQUENCELIST_SUCCESS:
            return { viewSequence: [] };
        case viewSequenceConstants.GET_VIEWSEQUENCE_SUCCESS:
            return { viewSequence: action.payload };
        case viewSequenceConstants.GET_VIEWSEQUENCE_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
};

export const updateSequenceByAdminReducer = (state = { updateSequenceByAdmin : [] }, action) => {
    switch (action.type) {
        case updateSequenceByAdminConstants.GET_UPDATESEQUENCEBYADMIN_SUCCESS:
            return { updateSequenceByAdmin: action.payload };
        case updateSequenceByAdminConstants.GET_UPDATESEQUENCEBYADMIN_FAILURE:
            return { error: action.payload };
        case clearAdminMessage.CLEAR_ADMIN_MESSAGE:
            return { updateSequenceByAdmin: [], error: "" }
        default:
            return state;
    }
};

export const getDefaultPriceReducer = (state = { getDefaultPrice : [] }, action) => {
    switch (action.type) {
        case getDefaultPriceConstants.GET_GETDEFAULTPRICE_SUCCESS:
            return { getDefaultPrice: action.payload };
        case getDefaultPriceConstants.GET_GETDEFAULTPRICE_FAILURE:
            return { error: action.payload };
        case clearAdminMessage.CLEAR_ADMIN_MESSAGE:
            return { getDefaultPrice: [], error: "" }
        default:
            return state;
    }
};

export const setDefaultPriceReducer = (state = { setDefaultPrice : [] }, action) => {
    switch (action.type) {
        case setDefaultPriceConstants.GET_SETDEFAULTPRICE_SUCCESS:
            return { setDefaultPrice: action.payload };
        case setDefaultPriceConstants.GET_SETDEFAULTPRICE_FAILURE:
            return { error: action.payload };
        case clearAdminMessage.CLEAR_ADMIN_MESSAGE:
            return { setDefaultPrice: [], error: "" }
        default:
            return state;
    }
};

// For Continent Location
export const continentListReducer = (state = { continentList : [] }, action) => {
    switch (action.type) {
        case continentListConstants.GET_CONTINENTLIST_SUCCESS:
            return { continentList: action.payload };
        case continentListConstants.GET_CONTINENTLIST_FAILURE:
            return { error: action.payload };
        case clearAdminMessage.CLEAR_ADMIN_MESSAGE:
            return { continentList: [], error: "" }
        default:
            return state;
    }
};

export const createContinentReducer = (state = { createContinent : [] }, action) => {
    switch (action.type) {
        case createContinentConstants.GET_CREATECONTINENT_SUCCESS:
            return { createContinent: action.payload };
        case createContinentConstants.GET_CREATECONTINENT_FAILURE:
            return { error: action.payload };
        case clearAdminMessage.CLEAR_ADMIN_MESSAGE:
            return { createContinent: [], error: "" }
        default:
            return state;
    }
};

export const continentStatusReducer = (state = { continentStatus : [] }, action) => {
    switch (action.type) {
        case continentStatusConstants.GET_CONTINENTSTATUS_SUCCESS:
            return { continentStatus: action.payload };
        case continentStatusConstants.GET_CONTINENTSTATUS_FAILURE:
            return { error: action.payload };
        case clearAdminMessage.CLEAR_ADMIN_MESSAGE:
            return { continentStatus: [], error: "" }
        default:
            return state;
    }
};

export const deleteContinentReducer = (state = { deleteContinent : [] }, action) => {
    switch (action.type) {
        case deleteContinentConstants.GET_DELETECONTINENT_SUCCESS:
            return { deleteContinent: action.payload };
        case deleteContinentConstants.GET_DELETECONTINENT_FAILURE:
            return { error: action.payload };
        case clearAdminMessage.CLEAR_ADMIN_MESSAGE:
            return { deleteContinent: [], error: "" }
        default:
            return state;
    }
};

export const editContinentReducer = (state = { editContinent : [] }, action) => {
    switch (action.type) {
        case editContinentConstants.GET_EDITCONTINENT_SUCCESS:
            return { editContinent: action.payload };
        case editContinentConstants.GET_EDITCONTINENT_FAILURE:
            return { error: action.payload };
        case clearAdminMessage.CLEAR_ADMIN_MESSAGE:
            return { editContinent: [], error: "" }
        default:
            return state;
    }
};

export const updateContinentReducer = (state = { updateContinent : [] }, action) => {
    switch (action.type) {
        case updateContinentConstants.GET_UPDATECONTINENT_SUCCESS:
            return { updateContinent: action.payload };
        case updateContinentConstants.GET_UPDATECONTINENT_FAILURE:
            return { error: action.payload };
        case clearAdminMessage.CLEAR_ADMIN_MESSAGE:
            return { updateContinent: [], error: "" }
        default:
            return state;
    }
};

// For Region Location
export const regionListReducer = (state = { regionList : [] }, action) => {
    switch (action.type) {
        case regionListConstants.GET_REGIONLIST_SUCCESS:
            return { regionList: action.payload };
        case regionListConstants.GET_REGIONLIST_FAILURE:
            return { error: action.payload };
        case clearAdminMessage.CLEAR_ADMIN_MESSAGE:
            return { regionList: [], error: "" }
        default:
            return state;
    }
};

export const createRegionReducer = (state = { createRegion : [] }, action) => {
    switch (action.type) {
        case createRegionConstants.GET_CREATEREGION_SUCCESS:
            return { createRegion: action.payload };
        case createRegionConstants.GET_CREATEREGION_FAILURE:
            return { error: action.payload };
        case clearAdminMessage.CLEAR_ADMIN_MESSAGE:
            return { createRegion: [], error: "" }
        default:
            return state;
    }
};

export const regionStatusReducer = (state = { regionStatus : [] }, action) => {
    switch (action.type) {
        case regionStatusConstants.GET_REGIONSTATUS_SUCCESS:
            return { regionStatus: action.payload };
        case regionStatusConstants.GET_REGIONSTATUS_FAILURE:
            return { error: action.payload };
        case clearAdminMessage.CLEAR_ADMIN_MESSAGE:
            return { regionStatus: [], error: "" }
        default:
            return state;
    }
};

export const deleteRegionReducer = (state = { deleteRegion : [] }, action) => {
    switch (action.type) {
        case deleteRegionConstants.GET_DELETEREGION_SUCCESS:
            return { deleteRegion: action.payload };
        case deleteRegionConstants.GET_DELETEREGION_FAILURE:
            return { error: action.payload };
        case clearAdminMessage.CLEAR_ADMIN_MESSAGE:
            return { deleteRegion: [], error: "" }
        default:
            return state;
    }
};

export const editRegionReducer = (state = { editRegion : [] }, action) => {
    switch (action.type) {
        case editRegionConstants.GET_EDITREGION_SUCCESS:
            return { editRegion: action.payload };
        case editRegionConstants.GET_EDITREGION_FAILURE:
            return { error: action.payload };
        case clearAdminMessage.CLEAR_ADMIN_MESSAGE:
            return { editRegion: [], error: "" }
        default:
            return state;
    }
};

export const updateRegionReducer = (state = { updateRegion : [] }, action) => {
    switch (action.type) {
        case updateRegionConstants.GET_UPDATEREGION_SUCCESS:
            return { updateRegion: action.payload };
        case updateRegionConstants.GET_UPDATEREGION_FAILURE:
            return { error: action.payload };
        case clearAdminMessage.CLEAR_ADMIN_MESSAGE:
            return { updateRegion: [], error: "" }
        default:
            return state;
    }
};

// For Area Location
export const areaListReducer = (state = { areaList : [] }, action) => {
    switch (action.type) {
        case areaListConstants.GET_AREALIST_SUCCESS:
            return { areaList: action.payload };
        case areaListConstants.GET_AREALIST_FAILURE:
            return { error: action.payload };
        case clearAdminMessage.CLEAR_ADMIN_MESSAGE:
            return { areaList: [], error: "" }
        default:
            return state;
    }
};

export const createAreaReducer = (state = { createArea : [] }, action) => {
    switch (action.type) {
        case createAreaConstants.GET_CREATEAREA_SUCCESS:
            return { createArea: action.payload };
        case createAreaConstants.GET_CREATEAREA_FAILURE:
            return { error: action.payload };
        case clearAdminMessage.CLEAR_ADMIN_MESSAGE:
            return { createArea: [], error: "" }
        default:
            return state;
    }
};

export const areaStatusReducer = (state = { areaStatus : [] }, action) => {
    switch (action.type) {
        case areaStatusConstants.GET_AREASTATUS_SUCCESS:
            return { areaStatus: action.payload };
        case areaStatusConstants.GET_AREASTATUS_FAILURE:
            return { error: action.payload };
        case clearAdminMessage.CLEAR_ADMIN_MESSAGE:
            return { areaStatus: [], error: "" }
        default:
            return state;
    }
};

export const deleteAreaReducer = (state = { deleteArea : [] }, action) => {
    switch (action.type) {
        case deleteAreaConstants.GET_DELETEAREA_SUCCESS:
            return { deleteArea: action.payload };
        case deleteAreaConstants.GET_DELETEAREA_FAILURE:
            return { error: action.payload };
        case clearAdminMessage.CLEAR_ADMIN_MESSAGE:
            return { deleteArea: [], error: "" }
        default:
            return state;
    }
};

export const editAreaReducer = (state = { editArea : [] }, action) => {
    switch (action.type) {
        case editAreaConstants.GET_EDITAREA_SUCCESS:
            return { editArea: action.payload };
        case editAreaConstants.GET_EDITAREA_FAILURE:
            return { error: action.payload };
        case clearAdminMessage.CLEAR_ADMIN_MESSAGE:
            return { editArea: [], error: "" }
        default:
            return state;
    }
};

export const updateAreaReducer = (state = { updateArea : [] }, action) => {
    switch (action.type) {
        case updateAreaConstants.GET_UPDATEAREA_SUCCESS:
            return { updateArea: action.payload };
        case updateAreaConstants.GET_UPDATEAREA_FAILURE:
            return { error: action.payload };
        case clearAdminMessage.CLEAR_ADMIN_MESSAGE:
            return { updateArea: [], error: "" }
        default:
            return state;
    }
};

// For Spot Location
export const spotListReducer = (state = { spotList : [] }, action) => {
    switch (action.type) {
        case spotListConstants.GET_SPOTLIST_SUCCESS:
            return { spotList: action.payload };
        case spotListConstants.GET_SPOTLIST_FAILURE:
            return { error: action.payload };
        case clearAdminMessage.CLEAR_ADMIN_MESSAGE:
            return { spotList: [], error: "" }
        default:
            return state;
    }
};

export const createSpotReducer = (state = { createSpot : [] }, action) => {
    switch (action.type) {
        case createSpotConstants.GET_CREATESPOT_SUCCESS:
            return { createSpot: action.payload };
        case createSpotConstants.GET_CREATESPOT_FAILURE:
            return { error: action.payload };
        case clearAdminMessage.CLEAR_ADMIN_MESSAGE:
            return { createSpot: [], error: "" }
        default:
            return state;
    }
};

export const spotStatusReducer = (state = { spotStatus : [] }, action) => {
    switch (action.type) {
        case spotStatusConstants.GET_SPOTSTATUS_SUCCESS:
            return { spotStatus: action.payload };
        case spotStatusConstants.GET_SPOTSTATUS_FAILURE:
            return { error: action.payload };
        case clearAdminMessage.CLEAR_ADMIN_MESSAGE:
            return { spotStatus: [], error: "" }
        default:
            return state;
    }
};

export const deleteSpotReducer = (state = { deleteSpot : [] }, action) => {
    switch (action.type) {
        case deleteSpotConstants.GET_DELETESPOT_SUCCESS:
            return { deleteSpot: action.payload };
        case deleteSpotConstants.GET_DELETESPOT_FAILURE:
            return { error: action.payload };
        case clearAdminMessage.CLEAR_ADMIN_MESSAGE:
            return { deleteSpot: [], error: "" }
        default:
            return state;
    }
};

export const editSpotReducer = (state = { editSpot : [] }, action) => {
    switch (action.type) {
        case editSpotConstants.GET_EDITSPOT_SUCCESS:
            return { editSpot: action.payload };
        case editSpotConstants.GET_EDITSPOT_FAILURE:
            return { error: action.payload };
        case clearAdminMessage.CLEAR_ADMIN_MESSAGE:
            return { editSpot: [], error: "" }
        default:
            return state;
    }
};

export const updateSpotReducer = (state = { updateSpot : [] }, action) => {
    switch (action.type) {
        case updateSpotConstants.GET_UPDATESPOT_SUCCESS:
            return { updateSpot: action.payload };
        case updateSpotConstants.GET_UPDATESPOT_FAILURE:
            return { error: action.payload };
        case clearAdminMessage.CLEAR_ADMIN_MESSAGE:
            return { updateSpot: [], error: "" }
        default:
            return state;
    }
};