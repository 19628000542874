import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import DataTable from 'datatables.net-dt';
import moment from "moment";
import defaultImg from "../../assets/images/defalut.jpg"
import { FaCheckCircle } from "react-icons/fa";
import { MdOutlineWatchLater, MdCancel } from "react-icons/md";
import { allPaymentsPayout } from "../../actions/adminAction";


const AllPaymentPayouts = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [payoutList, setPayoutList] = useState([]);

    useEffect(() => {
        dispatch({ type: 'SHOW_LOADER' });
        dispatch(allPaymentsPayout());
    }, [dispatch])

    // Selects data related to the loader action from the Redux store state.
    const isLoading = useSelector(state => state.loader.isLoading);
    // Selects data related to the allPaymentsPayout action from the Redux store state.
    const { data } = useSelector((state) => state.allPaymentsPayout.allPaymentsPayout)
    useEffect(() => {
        if (data && data.status) {
            dispatch({ type: 'HIDE_LOADER' });
            setPayoutList(data?.data?.list?.data);
        }
    }, [data, navigate, dispatch]);

    // impelement datatable from jquery
    useEffect(() => {
        if(payoutList && payoutList.length > 0){
            new DataTable('#myTable', {
                responsive: true,
                bDestroy: true
            });
        }
    }, [payoutList])

    return (
        <>
            {/* Conditional rendering for the loader */}
            {isLoading && <div className="loader-overlay"><Spinner animation="border" role="status"> </Spinner></div>}
            <main className="set_gallery_bg">
                <section className="profile_elements pt_60 pb_60">
                    <div className="container">
                        <div className="profile_card" style={{ maxWidth: "none" }}>
                            <h4 className="prof_title">Payment Payout's</h4>
                            <div className="table_userDetails">
                                <table id="myTable" className="table table-striped " style={{ width: "100%" }}>
                                    {(payoutList?.length > 0) ? (
                                        <>
                                            <thead>
                                                <tr>
                                                    <th className="text-nowrap">Gallary</th>
                                                    <th>Customer</th>
                                                    <th>Photographer</th>
                                                    <th className="text-nowrap">Price</th>
                                                    <th className="text-nowrap">Commission</th>
                                                    <th className="text-nowrap">Payment Date</th>
                                                    <th className="text-nowrap">Received Date</th>
                                                    <th className="text-nowrap">Payout Status</th>
                                                    <th className="text-nowrap">Payment Status</th>
                                                    <th className="text-nowrap">Customer Email</th>
                                                    <th className="text-nowrap">Photographer Email</th>
                                                    <th className="text-nowrap">Photographer Strip Account</th>
                                                    <th className="text-nowrap">Paymebnt Intent</th>
                                                    <th className="text-nowrap">Checkout Id</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {payoutList?.map((data,index) => (
                                                    <tr key={index} >
                                                        <td>
                                                            <div className="d-flex form_profile_outer">
                                                                <div className="form_profile">
                                                                    <img src={(data?.sequence?.sequence_image) ? (data?.sequence?.sequence_image) : defaultImg} className="form_profile_icon" alt="" />
                                                                </div>
                                                                {/* <p className="userName pb-0">{(data?.sequence?.name) ? data?.sequence?.name.charAt(0).toUpperCase() + data?.sequence?.name.slice(1) : '-'}</p> */}
                                                            </div>
                                                        </td>
                                                        <td> {(data?.name) ? (data?.name) : '-'} </td>
                                                        <td> {(data?.payout?.user) ? <Link to={`/admin/user/update/${data?.payout?.user?.id}`} style={{textDecoration: "underline", color : "#3f62ef"}}>{data?.payout?.user?.username}</Link> : '-'} </td>
                                                        <td> {(data?.price) ? '$'+(data?.price) : '-'}  </td>
                                                        <td> {(data?.payout?.comission) ? '$'+(data?.payout?.comission) : '-'} </td>
                                                        <td> {(data?.created_at) ? moment(data?.created_at).format('DD-MMM-YYYY') : '-'}  </td>
                                                        <td> {(data?.payout?.date) ? moment(data?.payout?.date).format('DD-MMM-YYYY') : '-'}  </td>
                                                        <td>
                                                            {(data?.payout) ? 
                                                            <>
                                                                {data?.payout?.status === 1 && ( <span className="text-success align-items-center d-flex gap-1"><FaCheckCircle/>Success</span> )}
                                                                {data?.payout?.status === 2 && ( <span className="text-danger align-items-center d-flex gap-1"><MdCancel/>Failed</span> )}
                                                                {(data?.payout?.status === 0 || data?.payout?.status === 3) && ( <span className="text-warning align-items-center d-flex gap-1"><MdOutlineWatchLater/>Pending</span> )}
                                                            </>: <span className="text-warning align-items-center d-flex gap-1"><MdOutlineWatchLater/>Pending</span>}       
                                                        </td>
                                                        <td>
                                                            {data?.status === 1 && ( <span className="text-success align-items-center d-flex gap-1"><FaCheckCircle/>Success</span> )}
                                                            {data?.status === 2 && ( <span className="text-danger align-items-center d-flex gap-1"><MdCancel/>Failed</span> )}
                                                            {data?.status === 0 && ( <span className="text-warning align-items-center d-flex gap-1"><MdOutlineWatchLater/>Pending</span> )}
                                                        </td>
                                                        <td> {(data?.email) ? (data?.email) : '-'} </td>
                                                        <td> {(data?.payout?.user) ? (data?.payout?.user?.email) : '-'} </td>
                                                        <td> <span className="break_word"> {(data?.payout?.user) ? (data?.payout?.user?.stripe_account_id) : '-'} </span> </td>
                                                        <td> <span className="break_word"> {(data?.payment_intent_id) ? (data?.payment_intent_id) : '-'} </span> </td>
                                                        <td> <span className="break_word">  {(data?.checkout_id) ? (data?.checkout_id) : '-'}</span>  </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </>
                                    ): (
                                        <tbody>
                                            <tr>
                                                <td  align={'center'} >No payouts yet!</td>
                                            </tr>
                                        </tbody>
                                    )}
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}

export default AllPaymentPayouts;