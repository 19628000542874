import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { clearAuthMsg, resetPassword } from "../../../actions/authAction";
import backgroundlogin from '../../../assets/images/5.jpg';
import Container from 'react-bootstrap/Container';
import { Form, Button, FormGroup } from 'react-bootstrap';
import logo from '../../../assets/images/FMSPLogo2trans.png';
import { FaEye, FaEyeSlash, FaRegUser} from "react-icons/fa";
import { MdLockOutline } from "react-icons/md";
import { TbArrowBack } from "react-icons/tb";

const ResetPassword = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { token, username } = useParams();

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showNewPassword, setShowNewPassword] = useState("");
    const [showConfirmPassword, setShowConfirmPassword] = useState("");
    const [error, setError] = useState(false);


    useEffect(() => {
        document.querySelectorAll('[data-background]').forEach(element => {
            const backgroundImage = element.getAttribute('data-background');
            if (backgroundImage) {
                element.style.backgroundImage = `url(${backgroundImage})`;
            }
        });
        document.title = 'Reset Password';
    }, []);

    // hide or show password
    const togglePasswordVisibility = (type) => {
        if(type === 'new')
        setShowNewPassword(!showNewPassword);
        if(type === 'current')
        setShowConfirmPassword(!showConfirmPassword);
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!username || !password || !confirmPassword) {
            setError(true);
            return false;
        } else if (password !== confirmPassword) { 
            toast.warn("Password should be same"); 
            dispatch(clearAuthMsg());
            return false;
        } else {
            dispatch(resetPassword({
                token    : token,
                username : username,
                password : password,
                password_confirmation : confirmPassword,
            }));
        }
    };

    // Selects data related to the resetPassword action from the Redux store state.
    const { data } = useSelector((state) => state.resetPassword.resetPassword)
    useEffect(() => {
        if (data && data.status) {
            toast.success(data?.message[0],{theme: "dark",});
            dispatch(clearAuthMsg());
            navigate("/login");
        }
    }, [data, navigate, dispatch]);
    
    return (
        <>
            {/* <h1>Reset Password</h1>
            <form onSubmit={onSubmit={handleSubmit}} className="loginForm">
                <label>User name</label>
                <input type="text" value={username} placeholder="Username" readOnly/>
                {error && !username && (
                    <span style={{ color: "red" }} >
                        Required*
                    </span>
                )}
                <label>New Password</label>
                <input type="password" value={password} placeholder="Password" onChange={(e) => setPassword(e.target.value)}/>
                {error && !password && (
                    <span style={{ color: "red" }} >
                        Required*
                    </span>
                )}
                <label>Confirm Password</label>
                <input type="password" value={confirmPassword} placeholder="Confirm Password" onChange={(e) => setConfirmPassword(e.target.value)} />
                {error && !confirmPassword && (
                    <span style={{ color: "red" }} > Required * </span>
                )}
                <button type="submit" > Reset Password </button>
            </form> */}

            <section className='bg_center' data-background={backgroundlogin}>
                <Container>
                    <div className=" set_center">
                        <div className="center_login">
                            <div className='btnBackHome'>
                                <Link className='backHome' to="/home"><TbArrowBack /></Link>
                            </div>
                            <div className="logoImg">
                                <img src={logo} alt="" />
                            </div>
                            <div className="cret_details">
                                <h4>Reset Password</h4>
                                <Form onSubmit={handleSubmit} onKeyDown={(e) => { if (e.key === 'Enter') e.preventDefault()}}>
                                    <FormGroup className="form_fields">
                                        <span className='form_icon'>
                                            <FaRegUser size={20}/>
                                        </span>
                                        <Form.Control 
                                            type="text"
                                            value={username}
                                            placeholder="Enter username/email"
                                            readOnly
                                        />
                                        {error && !username && (
                                            <span className="requiredInput">
                                                Required*
                                            </span>
                                        )}
                                    </FormGroup>
                                    <FormGroup className="form_fields">
                                        <span className='form_icon'>
                                            <MdLockOutline size={20}/>
                                        </span>
                                        <Form.Control 
                                            type={showNewPassword ? 'text' : 'password'}
                                            value={password}
                                            placeholder="New Password"
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        {error && !password && (
                                            <span className="requiredInput">
                                                Required*
                                            </span>
                                        )}
                                        <span className='onclickEye' onClick={() => {togglePasswordVisibility('new')}}>
                                            {showNewPassword ? ( <FaEye size={20}/> ) : ( <FaEyeSlash size={20}/> )}
                                        </span>
                                    </FormGroup>
                                    <FormGroup className="form_fields">
                                        <span className='form_icon'>
                                            <MdLockOutline size={20}/>
                                        </span>
                                        <Form.Control 
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            value={confirmPassword} 
                                            placeholder="Confirm Password" 
                                            onChange={(e) => setConfirmPassword(e.target.value)} 
                                        />
                                        {error && !confirmPassword && (
                                            <span className="requiredInput" >
                                                Required*
                                            </span>
                                        )}
                                        <span className='onclickEye' onClick={() => {togglePasswordVisibility('current')}}>
                                            {showConfirmPassword ? ( <FaEye size={20}/> ) : ( <FaEyeSlash size={20}/> )}
                                        </span>
                                    </FormGroup>

                                    <Button type="submit" className="btn_submit btn_signLogin">Reset Password</Button>
                                </Form>
                                <div className="form_go mt_40">
                                    <div className="actionSignup mb_24">
                                        <p>Alerady Have An Account? <Link to="/login">Sign In</Link></p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default ResetPassword;
