
/******************* Constants For Authenctication ******************/
export const loginConstants = {
    GET_LOGIN_REQUEST: 'GET_LOGIN_REQUEST',
    GET_LOGIN_SUCCESS: 'GET_LOGIN_SUCCESS',
    GET_LOGIN_FAILURE: 'GET_LOGIN_FAILURE'
}
  
export const myDataConstants = {
    GET_MYDATA_SUCCESS: 'GET_MYDATA_SUCCESS',
    GET_MYDATA_FAILURE: 'GET_MYDATA_FAILURE'
}

export const logoutConstants = {
    GET_LOGOUT_SUCCESS: 'GET_LOGOUT_SUCCESS',
    GET_LOGOUT_FAILURE: 'GET_LOGOUT_FAILURE'
}
  
export const signupConstants = {
    GET_SIGNUP_SUCCESS: 'GET_SIGNUP_SUCCESS',
    GET_SIGNUP_FAILURE: 'GET_SIGNUP_FAILURE'
}
  
export const forgotPasswordConstants = {
    GET_FORGOTPASSWORD_SUCCESS: 'GET_FORGOTPASSWORD_SUCCESS',
    GET_FORGOTPASSWORD_FAILURE: 'GET_FORGOTPASSWORD_FAILURE',
}
  
export const resetPasswordConstants = {
    GET_RESETPASSWORD_SUCCESS: 'GET_RESETPASSWORD_SUCCESS',
    GET_RESETPASSWORD_FAILURE: 'GET_RESETPASSWORD_FAILURE',
}

export const changePasswordConstants = {
    GET_CHANGEPASSWORD_SUCCESS: 'GET_CHANGEPASSWORD_SUCCESS',
    GET_CHANGEPASSWORD_FAILURE: 'GET_CHANGEPASSWORD_FAILURE',
}

export const emailVerificationConstants = {
    GET_EMAILVERIFICATION_SUCCESS: 'GET_EMAILVERIFICATION_SUCCESS',
    GET_EMAILVERIFICATION_FAILURE: 'GET_EMAILVERIFICATION_FAILURE',
}

export const resendEmailVerificationConstants = {
    GET_RESENDEMAILVERIFICATION_SUCCESS: 'GET_RESENDEMAILVERIFICATION_SUCCESS',
    GET_RESENDEMAILVERIFICATION_FAILURE: 'GET_RESENDEMAILVERIFICATION_FAILURE',
}

export const clearAuthMessage = {
    CLEAR_AUTH_MESSAGE: 'CLEAR_AUTH_MESSAGE',
}