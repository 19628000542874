import axios from "../services/axios";
import { viewProfileConstants, profileUpdateConstants, clearUserMessage, getUserPayoutsConstants, payoutDetailsLinkConstants } from "../constants/user.constants";

export const viewProfile = () => {
    return (dispatch) => {
      axios.post('/me').then((response) => {
          if (response.data.hasOwnProperty("errors")) {
            alert(response.data.message);
          } else {
            dispatch({ type: viewProfileConstants.GET_VIEWPROFILE_SUCCESS, payload: { data: response.data },});
            // redirect();
          }
        })
        .catch((err) => {
          dispatch({ type: 'HIDE_LOADER' });
          console.log(err);
        });
    };
};

export const profileUdpate = (formData, redirect) => {
  return (dispatch) => {
    axios.put("/profile", formData).then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: profileUpdateConstants.GET_PROFILEUPDATE_SUCCESS, payload: { data: response.data },});
          // redirect();
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const getUserPayouts = () => {
  return (dispatch) => {
    axios.get('/payout/list').then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: getUserPayoutsConstants.GET_USERPAYOUTS_SUCCESS, payload: { data: response.data },});
          // redirect();
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const payoutDetailsLink = () => {
  return (dispatch) => {
    axios.get('/payout/link').then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          dispatch({ type: payoutDetailsLinkConstants.GET_PAYOUTDETAILS_SUCCESS, payload: { payout : response.data },});
          // redirect();
        }
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADER' });
        console.log(err);
      });
  };
};

export const clearUserMsg = () => async (dispatch) => {
  try {
      dispatch({ type: clearUserMessage.CLEAR_USER_MESSAGE, payload: [] })
  } catch (err) {
      console.log(`Error while calling login api ${err}`);
  }
}
