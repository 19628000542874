import Axios from "axios";
import { toast } from 'react-toastify';
require("dotenv").config();

const axios = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + 'api',
});

// Request interceptor
axios.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("token");
    if (accessToken) {
      if (config.headers) config.headers.Authorization = 'Bearer '+accessToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      const data = error.response.data;
      if(data?.key && data?.key === 'Unauthorized'){
        // Clear local storage and redirect to login page
        localStorage.removeItem("token");
        localStorage.removeItem("userData");
        window.location.href = "/login";
      }else if (Array.isArray(data?.message) && data?.message.length > 0) {
        data.message.forEach(msg => {
          toast.error(msg, { theme: "dark" });
        });
      } else {
        toast.error(data?.message, { theme: "dark" });
      }
    }
    return Promise.reject(error);
  }
);

export default axios;