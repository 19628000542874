// Function to convert a file to base64 encoding asynchronously using FileReader API
export const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};


// Opens an image link in a new tab for previewing.
export const previewImgage = (imageLink) => {
    const newWindow = window.open('', '_blank');
    if (newWindow) {
        newWindow.document.write(`
        <html>
            <head>
                <title>Image Preview</title>
            </head>
            <body>
                <img src="${imageLink}" alt="Image Preview" style="width:100%;height:100%;object-fit: contain;background-color: black;"/>
            </body>
        </html>`);
        newWindow.document.close();
    } else {
        alert('Please allow popups for this website');
    }
}
