import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearSequenceMsg, deleteImage, imageStatus, viewUserSequence } from "../../../actions/sequenceAction";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import defaultImg from './../../../assets/images/defalut.jpg'
import { FaTrashAlt, FaLock, FaLockOpen, FaChevronLeft, FaEye} from "react-icons/fa";
import { Spinner } from "react-bootstrap";
import { PiImageLight } from "react-icons/pi";
// import { previewImgage } from "../../common/utils";
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

const ViewUserSequence = () =>{
    
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [sequenceDetails, setSequenceDetails] = React.useState([]);

    useEffect(()=>{
        dispatch({ type: 'SHOW_LOADER' }); // Set loader to true
        dispatch(viewUserSequence(id));
    },[dispatch,id])

    // Selects data related to the loader action from the Redux store state.
    const isLoading = useSelector(state => state.loader.isLoading);

    // Confirm the status with a confirmation dialog
    const handelStatus = (imageId, status) =>{
        Swal.fire({
            title: "Are you sure?",
            text: status === 0 ? "You want to inactive this image" : "You want active this image",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: status === 0 ? "Inactive!" : "Active!",
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(imageStatus({
                    image_id : imageId,
                    status : status
                }));
            }
        });
    }

    // Confirm the deletion with a confirmation dialog
    const handelImageDelete = (imageId) =>{
        Swal.fire({
            title: "Are you sure?",
            text: "You want to 'DELETE' this image",
            icon: "error",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: 'Confirm',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch({ type: 'SHOW_LOADER' });
                dispatch(deleteImage({
                    id : imageId,
                }));
            }
        });
    }

    // Selects data related to the viewUserSequence action from the Redux store state.
    const { deleteResponse } = useSelector((state) => state.deleteImage.deleteImage)
    useEffect(()=>{
        if(deleteResponse && deleteResponse?.status){
            dispatch({ type: 'HIDE_LOADER' });
            toast.success(deleteResponse?.message[0],{theme: "dark",});
            dispatch(clearSequenceMsg());
            dispatch(viewUserSequence(id));
        }
    },[deleteResponse,dispatch,id])

    // Selects data related to the imageStatus action from the Redux store state.
    const  { response }  = useSelector((state) => state.imageStatus.imageStatus)
    useEffect(() => {
        if (response && response?.status) {
            toast.success(response?.message[0],{theme: "dark",});
            dispatch(clearSequenceMsg());
            dispatch(viewUserSequence(id));
        }
    }, [response, dispatch, id]);

    // Selects data related to the viewUserSequence action from the Redux store state.
    const { sequenceData } = useSelector((state) => state.viewUserSequence.viewUserSequence)
    useEffect(()=>{
        if(sequenceData && sequenceData?.status){
            dispatch({ type: 'HIDE_LOADER' });
            setSequenceDetails(sequenceData?.data?.list);
        }
    },[sequenceData,dispatch])

    return(
        <>
            {/* Conditional rendering for the loader */}
            {isLoading && <div className="loader-overlay"><Spinner animation="border" role="status"> </Spinner></div>}
            <main className="set_gallery_bg">
                <section className="search_section">
                    <div className="container">
                        <div className="find_data form_gallery_data">
                            <span className="goBackbtn" title="go back" onClick={() => navigate(-1)}><FaChevronLeft /></span>
                            <h3 className="gallery_innerTitle">Gallery</h3>
                            <div className="image_wrapField ">
                                <h6 className="image_galleryTiitle mb_40" style={{ fontFamily: "fantasy" }}>{sequenceDetails ? ( <>{sequenceDetails?.area}, {sequenceDetails?.spot}, {sequenceDetails?.region} Picture Colloction </>) : ''}</h6>
                                <div className="item_log_pic">
                                    <ul className="item_searchvalue">
                                    <PhotoProvider>
                                    {(sequenceDetails?.images?.length > 0) ? (
                                        sequenceDetails?.images?.map((data, index) => (
                                            <li key={index} className="item_flexInner">
                                                <div className="desk_card_outer">
                                                    {/* <PhotoView key={index} src={(data.watermark) ? data.watermark : defaultImg}> */}
                                                        <img src={(data?.watermark) ? data?.watermark : defaultImg} alt="" className="card_images_admin img-fluid"/>
                                                    {/* </PhotoView> */}
                                                    <div className="img_desk_card">
                                                        {/* <p>New York picture collection</p> */}
                                                        <div className="public_items d-flex">
                                                            <div className="cart_eyeElements">
                                                              
                                                            </div>
                                                            <div className="public_trash d-flex">
                                                                <Link to="" className="btn_edit_icons eye_btn" title="Status" onClick={() => handelStatus(data.id, data.status === 0 ? 1 : 0)}>
                                                                    <div className="btn_edit_block d-flex">
                                                                        {(data.status === 1)  ?
                                                                            <>
                                                                                <div className="icon_outer">
                                                                                    <FaLockOpen size={18} />
                                                                                </div>
                                                                                <span className="title_valueEye">Inactive</span>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <div className="icon_outer">
                                                                                    <FaLock size={18}/>
                                                                                </div>
                                                                                <span className="title_valueEye">Aative</span>
                                                                            </>
                                                                        }
                                                                        <div className="icon_outer_drop">
                                                                            <FaChevronLeft/>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                                {/* <Link className="btn_edit eye_btn" title="Status">
                                                                    <div className="btn_onhover d-flex" onClick={() => handelStatus(data.id, data.status === 0 ? 1 : 0)}>
                                                                        {(data.status === 1) ?
                                                                            <>
                                                                                <div className="icon_outer">
                                                                                    <FaLockOpen size={18} />
                                                                                </div>
                                                                                <span className="title_valueEye">Inactive</span>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <div className="icon_outer">
                                                                                    <FaLock size={18} />
                                                                                </div>
                                                                                <span className="title_valueEye">Active</span>
                                                                            </>
                                                                        }
                                                                        <div className="icon_outer_drop">
                                                                            <FaChevronLeft />
                                                                        </div>
                                                                    </div>
                                                                </Link> */}
                                                                <PhotoView key={index} src={(data.watermark) ? data.watermark : defaultImg}>
                                                                    <Link title="View" className="btn_edit editor_btn" 
                                                                        // onClick={() => previewImgage(data?.watermark)}
                                                                    >
                                                                        <FaEye size={20}/>
                                                                    </Link>
                                                                </PhotoView>
                                                                <Link onClick={()=>handelImageDelete(data.id)} title="Delete" className="btn_edit trash_btn">
                                                                    <FaTrashAlt />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="desk_cardHoverItems">
                                                        <div className="desk_containerINner">
                                                            <p className="questioLIne">Aer you sure you want to
                                                                delete this picture?</p>
                                                            <div className="d-flex action_cal_del">
                                                                <button href="#">Cancel</button>
                                                                <button href="#" className="btn_del">Delete</button>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </li>
                                          
                                        ))
                                    ): (
                                        <div className="d-flex no_record">
                                            <PiImageLight size={100}/>
                                            <span align={'center'} style={{fontWeight:'700',padding:'1em'}}> 
                                                No Record Found. 
                                            </span>
                                        </div>
                                    )}
                                    </PhotoProvider>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}

export default ViewUserSequence;