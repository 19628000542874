import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearAuthMsg } from "../../actions/authAction";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import DataTable from 'datatables.net-dt';
import { getContactList, userContactStatus } from "../../actions/adminAction";
import moment from "moment";

const ContactList = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const [contactsList, setContactsList] = useState([]);

    useEffect(() => {
        dispatch({ type: 'SHOW_LOADER' });
        dispatch(getContactList());
    }, [dispatch])

    // Selects data related to the loader action from the Redux store state.
    const isLoading = useSelector(state => state.loader.isLoading);
    // Selects data related to the contactList action from the Redux store state.
    const { data } = useSelector((state) => state.contactList.contactList)
    useEffect(() => {
        if (data && data?.status) {
            dispatch({ type: 'HIDE_LOADER' });
            setContactsList(data?.data?.data);
        }
    }, [data, navigate, dispatch]);

    // Confirm the status with a confirmation dialog
    const handelStatus = (Id, status) =>{
        Swal.fire({
            title: "Are you sure?",
            text: status === 0 ? "You want to 'UNREAD' that contact" : "You want to 'READ' that contact",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: status === 0 ? "Unread!" : "Read!",
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch({ type: 'SHOW_LOADER' }); // Set loader to true
                dispatch(userContactStatus({
                    id : Id,
                    status : status
                }));
            }
        });
    }

    // Selects data related to the contactStatus action from the Redux store state.
    const { contactStatus } = useSelector((state) => state.contactStatus.contactStatus)
    useEffect(() => {
        if (contactStatus && contactStatus?.status) {
            dispatch({ type: 'HIDE_LOADER' });
            toast.success(contactStatus?.message[0],{theme: "dark",});
            dispatch(clearAuthMsg());
            dispatch(getContactList());
        }
    }, [contactStatus, navigate, dispatch]);

    // impelement datatable from jquery
    useEffect(() => {
        if(contactsList && contactsList.length > 0){
            new DataTable('#myTable', {
                responsive: true,
                bDestroy: true
            });
        }
    }, [contactsList])

    return (
        <>
            {/* Conditional rendering for the loader */}
            {isLoading && <div className="loader-overlay"><Spinner animation="border" role="status"> </Spinner></div>}
            <main className="set_gallery_bg">
                <section className="profile_elements pt_60 pb_60">
                    <div className="container">
                        <div className="profile_card" style={{ maxWidth: "none" }}>
                            <h4 className="prof_title">Contact's</h4>
                            <div className="table_userDetails">
                                <table id="myTable" className="table table-striped " style={{ width: "100%" }}>
                                    {(contactsList?.length > 0) ? (
                                        <>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Date</th>
                                                <th>Message</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {contactsList?.map((data) => (
                                                <tr key={data.id}>
                                                    <td> {(data?.name) ? (data?.name) : '-'} </td>
                                                    <td> {(data?.email) ? (data?.email)     : '-'} </td>
                                                    <td> {(data?.created_at) ? moment(data?.created_at).format('DD-MMM-YYYY') : '-'} </td>
                                                    <td> {(data?.detail) ? (data?.detail) : '-'} </td>
                                                    <td>
                                                        <div className="form-check form-switch">
                                                            <input
                                                                className="form-check-input"
                                                                onChange={() => handelStatus(data.id, data.status === 0 ? 1 : 0)} // Add onChange handler
                                                                type="checkbox"
                                                                role="switch"
                                                                id="flexSwitchCheckDefault"
                                                                checked={data.status === 1}
                                                            />        
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Switch to read/unread</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                        </>
                                    ): (
                                        <tbody>
                                            <tr>
                                                <td colSpan={5} align={'center'} >User Not Found!</td>
                                            </tr>
                                        </tbody>
                                    )}
                                </table>
                            </div>
                        </div>

                    </div>
                </section>
            </main>
        </>
    );
}

export default ContactList;