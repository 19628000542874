import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import backgroundlogin from '../../../assets/images/4.jpg';
import { forgotPassword, clearAuthMsg } from "../../../actions/authAction";
import Container from 'react-bootstrap/Container';
import { Form, Button, FormGroup } from 'react-bootstrap';
import logo from '../../../assets/images/FMSPLogo2trans.png';
import { AiOutlineMail } from "react-icons/ai";
import { TbArrowBack } from "react-icons/tb";


const ForgotPassword = () => {
    const [username, setUsername] = useState("");
    const [error, setError] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        document.querySelectorAll('[data-background]').forEach(element => {
            const backgroundImage = element.getAttribute('data-background');
            if (backgroundImage) {
                element.style.backgroundImage = `url(${backgroundImage})`;
            }
        });
        document.title = 'Forgot Password';
    }, []);

    const handleSubmit = () => {
        if (!username) { 
            setError(true);
            return false;
        }else{
            dispatch(forgotPassword({ username: username }));
        }
    };

    // Selects data related to the forgotPassword action from the Redux store state.
    const { data } = useSelector((state) => state.forgotPassword.forgotPassword)
    useEffect(() => {
        if (data && data.status) {
            toast.success(data?.message[0],{theme: "dark",});
            dispatch(clearAuthMsg());
            navigate("/login");
        }
    }, [data, navigate, dispatch]);

    return (
        <>
            <section className='bg_center' data-background={backgroundlogin}>
                <Container>
                    <div className=" set_center">
                        <div className="center_login">
                            <div className='btnBackHome'>
                                <Link className='backHome' to="/home"><TbArrowBack /></Link>
                            </div>
                            <div className="logoImg">
                                <img src={logo} alt="" />
                            </div>
                            <div className="cret_details">
                                <h4>Forgot Password</h4>
                                <Form onSubmit={(e)=>{e.preventDefault();handleSubmit()}}>
                                    <FormGroup className="form_fields">
                                        <span className='form_icon'>
                                            <AiOutlineMail size={20}/>
                                        </span>
                                        <Form.Control 
                                            type="text"
                                            placeholder="Enter username/email"
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                        />
                                        {error && !username && (
                                            <span className="requiredInput">
                                                Required*
                                            </span>
                                        )}
                                    </FormGroup>

                                    <Button type="submit" className="btn_submit btn_signLogin">Reset Password</Button>
                                </Form>
                                
                                <div className="form_go mt_40">
                                    <div className="actionSignup mb_24">
                                        <p>Alerady Have An Account? <Link to="/login">Sign In</Link></p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default ForgotPassword;
