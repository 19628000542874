import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "../../../actions/authAction";
import { clearAuthMsg } from "../../../actions/authAction";
import { useNavigate } from "react-router-dom";
import { Form, Button, FormGroup } from 'react-bootstrap';
import logo from '../../../assets/images/FMSPLogo2trans.png';
import { FaEye, FaEyeSlash} from "react-icons/fa";
import { MdLockOutline } from "react-icons/md";


const ChangePassword = (props) => {

    const id = props.id;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [showNewPassword, setShowNewPassword] = useState("");
    const [showConfirmPassword, setShowConfirmPassword] = useState("");


    // hide or show password
    const togglePasswordVisibility = (type) => {
        if(type === 'new')
        setShowNewPassword(!showNewPassword);
        if(type === 'confirm')
        setShowConfirmPassword(!showConfirmPassword);
    };

    // function for change password.
    const handleChangePassword = async (e) => {
        e.preventDefault();
        if (!currentPassword || !newPassword || !confirmPassword) {
            setError(true);
            return false;
        }else if (newPassword !== confirmPassword) { 
            toast.warn("Password should be same"); 
            dispatch(clearAuthMsg());
            return false;
        }else {
            try {
                await dispatch(changePassword(id,{
                    currentPassword : currentPassword,
                    newPassword     : newPassword,
                    newPassword_confirmation : confirmPassword
                }));
            } catch (error) {
                console.error("Error changing password:", error);
            }
        }
    }

    // Selects data related to the profileUpdate action from the Redux store state.
    const  changePasswordDtl  = useSelector((state) => state.changePassword.changePassword)
    useEffect(() => {
        if (changePasswordDtl && changePasswordDtl?.data?.status) {
            toast.success(changePasswordDtl?.data?.message[0],{theme: "dark",});
            dispatch(clearAuthMsg())
            if((props?.from) && props?.from === 'passwordProps'){
                props.onReturn(changePasswordDtl?.data);
            }
        }
    }, [changePasswordDtl, navigate, dispatch, props]);

    return (
        <>
            {/* <section className='bg_center' data-background={backgroundlogin}>
                <Container> */}
                    {/* <div className=" set_center"> */}
                        <div className="center_password">
                            {/* <div className='btnBackHome'>
                                <Link className='backHome' ><TbArrowBack /></Link>
                            </div> */}
                            <div className="logoImg">
                                <img src={logo} alt="" />
                            </div>
                            <div className="cret_details">
                                <h4>Change Password</h4>
                                <Form  onSubmit={handleChangePassword} onKeyDown={(e) => { if (e.key === 'Enter') e.preventDefault()}}>
                                    <FormGroup className="form_fields">
                                        <span className='form_icon'>
                                            <MdLockOutline size={20}/>
                                        </span>
                                        <Form.Control 
                                            type="text"
                                            name="password"
                                            value={currentPassword || ''}
                                            onChange={(e) => setCurrentPassword(e.target.value)}
                                            placeholder="Current Password"
                                        />
                                        {error && !currentPassword && (
                                            <span className="requiredInput">
                                                Required*
                                            </span>
                                        )}
                                    </FormGroup>
                                    <FormGroup className="form_fields">
                                        <span className='form_icon'>
                                            <MdLockOutline size={20}/>
                                        </span>
                                        <Form.Control 
                                            type={showNewPassword ? 'text' : 'password'}
                                            name="new_password"
                                            value={newPassword || ''}
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            placeholder="New Password"
                                        />
                                        {error && !newPassword && (
                                            <span className="requiredInput">
                                                Required*
                                            </span>
                                        )}
                                        <span className='onclickEye' onClick={() => {togglePasswordVisibility('new')}}>
                                            {showNewPassword ? ( <FaEye size={20}/> ) : ( <FaEyeSlash size={20}/> )}
                                        </span>
                                    </FormGroup>
                                    <FormGroup className="form_fields">
                                        <span className='form_icon'>
                                            <MdLockOutline size={20}/>
                                        </span>
                                        <Form.Control 
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            name="confirm_password"
                                            value={confirmPassword || ''}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            placeholder="Confirm Password" 
                                        />
                                        {error && !confirmPassword && (
                                            <span className="requiredInput" >
                                                Required*
                                            </span>
                                        )}
                                        <span className='onclickEye' onClick={() => {togglePasswordVisibility('confirm')}}>
                                            {showConfirmPassword ? ( <FaEye size={20}/> ) : ( <FaEyeSlash size={20}/> )}
                                        </span>
                                    </FormGroup>

                                    <Button type="submit" className="btn_submit btn_signLogin">Change Password</Button>
                                </Form>
                            </div>
                        </div>
                    {/* </div> */}
                {/* </Container>
            </section> */}
        </>
    )
}

export default ChangePassword;