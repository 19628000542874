import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sequenceStatus, getUserSequenceList, clearSequenceMsg, deleteSequence } from "../../../actions/sequenceAction";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { FaEdit, FaEye, FaChevronDown, FaTrashAlt, FaLock, FaLockOpen, FaChevronLeft, FaCheck} from "react-icons/fa";
import defaultImg from './../../../assets/images/defalut.jpg'
import { Spinner } from "react-bootstrap";
import { PiImageLight } from "react-icons/pi";

const UserSequenceList = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [sequenceList, setSequenceList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const currentPageRef = useRef(currentPage);


    useEffect(() => {
        dispatch({ type: 'SHOW_LOADER' }); // Set loader to true
        dispatch(getUserSequenceList(currentPage));
        // Update the ref whenever currentPage changes
        currentPageRef.current = currentPage;
    }, [dispatch, currentPage])

    // Selects data related to the loader action from the Redux store state.
    const isLoading = useSelector(state => state.loader.isLoading);
    // Selects data related to the userSequenceList action from the Redux store state.
    const { data } = useSelector((state) => state.userSequenceList.userSequenceList)
    useEffect(() => {
        if (data && data.status) {
            dispatch({ type: 'HIDE_LOADER' });
            // setSequenceList((currentPage === 1) ? data?.data?.list?.data : (prevData) => [...prevData, ...data?.data?.list?.data]);
            setSequenceList(prevSequenceList => {
                const updatedList = currentPageRef.current === 1 ? data?.data?.list?.data : [...prevSequenceList, ...data?.data?.list?.data];
                return updatedList;
            });
            setTotal(data?.data?.list?.total);
        }
    }, [data,dispatch]);

    // Confirm the status with a confirmation dialog
    const handelStatus = (sequenceId, status) =>{
        Swal.fire({
            title: "Are you sure?",
            text: status === 0 ? "You want to inactive sequence" : "You want active sequence",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: status === 0 ? "Inactive!" : "Active!",
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(sequenceStatus({
                    sequence_id : sequenceId,
                    status : status
                }));
            }
        });
    }

    // Confirm the deletion with a confirmation dialog
    const handelSequenceDelete = (sequenceId) =>{
        Swal.fire({
            title: "Are you sure?",
            text: "You want to 'DELETE' this sequence",
            icon: "error",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: 'Confirm',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch({ type: 'SHOW_LOADER' });
                dispatch(deleteSequence({
                    id : sequenceId,
                }));
            }
        });
    }

    // Selects data related to the deleteSequence action from the Redux store state.
    const  { deleteResponse }  = useSelector((state) => state.deleteSequence.deleteSequence)
    useEffect(() => {
        if (deleteResponse && deleteResponse?.status) {
            toast.success(deleteResponse?.message[0],{theme: "dark",});
            dispatch({ type: 'HIDE_LOADER' });
            dispatch(clearSequenceMsg());
            dispatch(getUserSequenceList());
        }
    }, [deleteResponse, dispatch]);

    // Selects data related to the sequenceStatus action from the Redux store state.
    const  { response }  = useSelector((state) => state.sequenceStatus.sequenceStatus)
    useEffect(() => {
        if (response && response?.status) {
            toast.success(response?.message[0],{theme: "dark",});
            dispatch(clearSequenceMsg());
            dispatch(getUserSequenceList());
        }
    }, [response, navigate, dispatch]);


    // Handle "Load More" button click
    const handleLoadMore = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    return (
        <>
            {/* Conditional rendering for the loader */}
            {isLoading && <div className="loader-overlay"><Spinner animation="border" role="status"> </Spinner></div>}
            <main className="set_gallery_bg">
                <section className="search_section">
                    <div className="container">
                        <div className="find_data form_gallery_data">
                            {/* <h3>Kristie charley</h3>
                            <div className="oneditor_outer d-flex">
                                <div className="onEditor">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                        ut
                                        labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip
                                        ex ea commodo consequat.
                                    </p>
                                    <textarea className="text_areaItem d-none">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    </textarea>
                                </div>
                                <Link to="" className="btn_edit editor_btn">
                                    <FaPencilAlt />
                                </Link>
                            </div> */}

                            <div className="image_wrapField">
                                <span className="goBackbtn" title="go back" onClick={() => navigate(-1)}><FaChevronLeft /></span>
                                <h6 className="image_galleryTiitle mb_24" style={{ fontFamily : 'fantasy'}}>Your Image's <span className="small-text">(Click the eye ball symbol to view all photos from your upload.)</span></h6>
                                <div className="item_log_pic">
                                    <ul className="item_searchvalue">
                                    {(sequenceList?.length > 0) ? (
                                        sequenceList?.map((data, index) => (
                                            <li key={index} className="item_flexInner_card">
                                                <div className="deskDettails_card">
                                                    <img src={(data?.sequence_image) ? data?.sequence_image : defaultImg} alt="" className="card_images_admin img-fluid"/>
                                                    <div className="img_deskInner_card">
                                                        <p>{data?.name ? data.name.charAt(0).toUpperCase() + data.name.slice(1) : ''}</p>
                                                    </div>
                                                    <div className="btn_elements_gallery d-flex">
                                                        <Link to={`/user/sequence/update/${data.id}`} className="btn_edit editor_btn" title="Edit">
                                                            <FaEdit />
                                                        </Link>
                                                        <Link to={`/user/sequence/view/${data.id}`} className="btn_edit heart_btn" title="View">
                                                            <FaEye size={20} />
                                                        </Link>
                                                        <Link className="btn_edit eye_btn" title="Status">
                                                            <div className="btn_onhover d-flex" onClick={() => handelStatus(data.id, data.status === 0 ? 1 : 0)}>
                                                                {(data.status === 1) ?
                                                                    <>
                                                                        <div className="icon_outer">
                                                                            <FaLockOpen size={18} />
                                                                        </div>
                                                                        <span className="title_valueEye">Inactive</span>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div className="icon_outer">
                                                                            <FaLock size={18} />
                                                                        </div>
                                                                        <span className="title_valueEye">Active</span>
                                                                    </>
                                                                }
                                                                <div className="icon_outer_drop">
                                                                    <FaChevronDown />
                                                                </div>
                                                            </div>
                                                        </Link>
                                                        <Link onClick={() => handelSequenceDelete(data.id)} className="btn_edit trash_btn" title="Delete">
                                                            <FaTrashAlt />
                                                        </Link>
                                                        {(data.is_approved === 1) &&
                                                            <Link className="btn_edit" title="Approved">
                                                                <FaCheck size={20} color="green"/>
                                                            </Link>
                                                        }
                                                    </div>
                                                </div>
                                            </li>
                                        ))
                                    ): (
                                        <div className="d-flex no_record">
                                            <PiImageLight size={100}/>
                                            <span align={'center'} style={{fontWeight:'700',padding:'1em'}}> 
                                                No Record Found. 
                                            </span>
                                        </div>
                                    )}
                                    </ul>
                                    {sequenceList?.length < total && (
                                        <div className="loadMore">
                                            <Link onClick={handleLoadMore} className="loadMore_action">Load More</Link>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}

export default UserSequenceList;