/******************* Common Constants ******************/
export const getAllLocationConstants = {
    GET_GETALLLOCATION_SUCCESS: 'GET_GETALLLOCATION_SUCCESS',
    GET_GETALLLOCATION_FAILURE: 'GET_GETALLLOCATION_FAILURE'
}

export const getContinentRegionsConstants = {
    GET_GETCONTINENTREGIONS_SUCCESS: 'GET_GETCONTINENTREGIONS_SUCCESS',
    GET_GETCONTINENTREGIONS_FAILURE: 'GET_GETCONTINENTREGIONS_FAILURE'
}

export const getRegionAreasConstants = {
    GET_GETREGIONAREAS_SUCCESS: 'GET_GETREGIONAREAS_SUCCESS',
    GET_GETREGIONAREAS_FAILURE: 'GET_GETREGIONAREAS_FAILURE'
}

export const getAreaSpotsConstants = {
    GET_GETAREASPOTS_SUCCESS: 'GET_GETAREASPOTS_SUCCESS',
    GET_GETAREASPOTS_FAILURE: 'GET_GETAREASPOTS_FAILURE'
}