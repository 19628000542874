// Sequence constant from here
export const userSequenceListConstants = {
    GET_USERSEQUENCELIST_SUCCESS: 'GET_USERSEQUENCELIST_SUCCESS',
    GET_USERSEQUENCELIST_FAILURE: 'GET_USERSEQUENCELIST_FAILURE'
}

export const sequenceStatusConstants = {
    GET_SEQUENCESTATUS_SUCCESS: 'GET_SEQUENCESTATUS_SUCCESS',
    GET_SEQUENCESTATUS_FAILURE: 'GET_SEQUENCESTATUS_FAILURE'
}

export const imageStatusConstants = {
    GET_IMAGESTATUS_SUCCESS: 'GET_IMAGESTATUS_SUCCESS',
    GET_IMAGESTATUS_FAILURE: 'GET_IMAGESTATUS_FAILURE'
}

export const viewUserSequenceConstants = {
    GET_VIEWUSERSEQUENCE_SUCCESS: 'GET_VIEWUSERSEQUENCE_SUCCESS',
    GET_VIEWUSERSEQUENCE_FAILURE: 'GET_VIEWUSERSEQUENCE_FAILURE'
}

export const createSequenceConstants = {
    GET_CREATESEQUENCE_SUCCESS: 'GET_CREATESEQUENCE_SUCCESS',
    GET_CREATESEQUENCE_FAILURE: 'GET_CREATESEQUENCE_FAILURE'
}

export const updateSequenceConstants = {
    GET_UPDATESEQUENCE_SUCCESS: 'GET_UPDATESEQUENCE_SUCCESS',
    GET_UPDATESEQUENCE_FAILURE: 'GET_UPDATESEQUENCE_FAILURE'
}

export const deleteSequenceConstants = {
    GET_DELETESEQUENCE_SUCCESS: 'GET_DELETESEQUENCE_SUCCESS',
    GET_DELETESEQUENCE_FAILURE: 'GET_DELETESEQUENCE_FAILURE'
}

export const deleteImageConstants = {
    GET_DELETEIMAGE_SUCCESS: 'GET_DELETEIMAGE_SUCCESS',
    GET_DELETEIMAGE_FAILURE: 'GET_DELETEIMAGE_FAILURE'
}

export const clearSequenceMessage = {
    CLEAR_SEQUENCE_MESSAGE: 'CLEAR_SEQUENCE_MESSAGE'
}