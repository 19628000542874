import React from "react";
import error from '../../../assets/images/payment_error.png'
import { Link } from "react-router-dom";

const PaymentFail = () =>{
    
    return(
        <>  
            <main className="set_gallery_bg">
                <section className="sec_way search_section">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">

                                <div className="sub_details">
                                    <h3 className="mb-3">Payment <span style={{ color : "red"}}>Error</span></h3>
                                    <p> We regret to inform you that your recent payment attempt was unsuccessful. It appears that there was an issue processing your payment.</p>
                                    <p> To ensure uninterrupted access to our services, please take a moment to review your payment details and try again.</p>
                                    <p> Here are a few common reasons why payments fail:
                                        <ol>
                                            <li>1. Insufficient funds in your account.</li>
                                            <li>2. Incorrect payment information provided.</li>
                                            <li>3. Payment method declined by your bank.</li>
                                            <li>4. Technical issues with the payment gateway.</li>
                                        </ol>
                                    </p>
                                </div>
                                <div className="d-flex gap-2 btn_payment">
                                    <Link to="/home" className="loadMore_action">Back To Home</Link>
                                    <Link to="/contact-us" className="loadMore_action btn_border">Contact Us</Link>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="image_surf  mt-3">
                                    <img src={error} alt="" className="img-fluid"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}
export default PaymentFail;