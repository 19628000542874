import { searchSequenceConstants, clearHomeMessage, viewGalleryConstants, sequenceCheckoutConstants, imageCheckoutConstants, contactUsConstants, paymentRetrievConstants, downloadImageConstants, imagesRetrievConstants, randomSequenceConstants, clearSearchSequence } from "../constants/home.constants";


/************************** Reducer For Home *******************************/
export const contactUsReducer = (state = { contactUs : [] }, action) => {
    switch (action.type) {
        case contactUsConstants.GET_CONTACTUS_SUCCESS:
            return { contactUs: action.payload };
        case contactUsConstants.GET_CONTACTUS_FAILURE:
            return { error: action.payload };
         case clearHomeMessage.CLEAR_HOME_MESSAGE:
             return { contactUs : [], error: "" }
        default:
            return state;
    }
};

export const searchSequenceReducer = (state = { searchSequence : [] }, action) => {
   switch (action.type) {
       case searchSequenceConstants.GET_SEARCHSEQUENCE_SUCCESS:
           return { searchSequence: action.payload };
       case searchSequenceConstants.GET_SEARCHSEQUENCE_FAILURE:
           return { error: action.payload };
        case clearHomeMessage.CLEAR_HOME_MESSAGE:
            return { searchSequence : [], error: "" }
        case clearSearchSequence.CLEAR_SEARCHSEQUENCE_MESSAGE:
            return { searchSequence : [], error: "" }
       default:
           return state;
   }
};

export const randomSequenceReducer = (state = { randomSequence : [] }, action) => {
    switch (action.type) {
        case randomSequenceConstants.GET_RANDOMSEQUENCE_SUCCESS:
            return { randomSequence: action.payload };
        case randomSequenceConstants.GET_RANDOMSEQUENCE_FAILURE:
            return { error: action.payload };
         case clearHomeMessage.CLEAR_HOME_MESSAGE:
             return { randomSequence : [], error: "" }
        default:
            return state;
    }
};

export const viewGalleryReducer = (state = { viewGallery : [] }, action) => {
    switch (action.type) {
        case viewGalleryConstants.GET_VIEWGALLERY_SUCCESS:
            return { viewGallery: action.payload };
        case viewGalleryConstants.GET_VIEWGALLERY_FAILURE:
            return { error: action.payload };
         case clearHomeMessage.CLEAR_HOME_MESSAGE:
             return { viewGallery : [], error: "" }
        default:
            return state;
    }
};

export const sequenceCheckoutReducer = (state = { sequenceCheckout : [] }, action) => {
    switch (action.type) {
        case sequenceCheckoutConstants.GET_SEQUENCECHECKOUT_SUCCESS:
            return { sequenceCheckout: action.payload };
        case sequenceCheckoutConstants.GET_SEQUENCECHECKOUT_FAILURE:
            return { error: action.payload };
         case clearHomeMessage.CLEAR_HOME_MESSAGE:
             return { sequenceCheckout : [], error: "" }
        default:
            return state;
    }
};

export const imageCheckoutReducer = (state = { imageCheckout : [] }, action) => {
    switch (action.type) {
        case imageCheckoutConstants.GET_IMAGECHECKOUT_SUCCESS:
            return { imageCheckout: action.payload };
        case imageCheckoutConstants.GET_IMAGECHECKOUT_FAILURE:
            return { error: action.payload };
         case clearHomeMessage.CLEAR_HOME_MESSAGE:
             return { imageCheckout : [], error: "" }
        default:
            return state;
    }
};

export const paymentRetrievReducer = (state = { paymentRetriev : [] }, action) => {
    switch (action.type) {
        case paymentRetrievConstants.GET_PAYMENTRETRIEV_SUCCESS:
            return { paymentRetriev: action.payload };
        case paymentRetrievConstants.GET_PAYMENTRETRIEV_FAILURE:
            return { error: action.payload };
         case clearHomeMessage.CLEAR_HOME_MESSAGE:
             return { paymentRetriev : [], error: "" }
        default:
            return state;
    }
};

export const imagesRetrievReducer = (state = { imagesRetriev : [] }, action) => {
    switch (action.type) {
        case imagesRetrievConstants.GET_IMAGESRETRIEV_SUCCESS:
            return { imagesRetriev: action.payload };
        case imagesRetrievConstants.GET_IMAGESRETRIEV_FAILURE:
            return { error: action.payload };
         case clearHomeMessage.CLEAR_HOME_MESSAGE:
             return { imagesRetriev : [], error: "" }
        default:
            return state;
    }
};

export const downloadImageReducer = (state = { downloadImage : [] }, action) => {
    switch (action.type) {
        case downloadImageConstants.GET_DOWNLOADIMAGE_SUCCESS:
            return { downloadImage: action.payload };
        case downloadImageConstants.GET_DOWNLOADIMAGE_FAILURE:
            return { error: action.payload };
         case clearHomeMessage.CLEAR_HOME_MESSAGE:
             return { downloadImage : [], error: "" }
        default:
            return state;
    }
};