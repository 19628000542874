import { loginConstants, signupConstants, myDataConstants, forgotPasswordConstants, resetPasswordConstants, emailVerificationConstants, resendEmailVerificationConstants,
     changePasswordConstants, clearAuthMessage} from "../constants/auth.constants";


/************************** Reducer For Authentication *******************************/
export const myDataReducer = (state = { myData: [] }, action) => {
    switch (action.type) {
        case myDataConstants.GET_MYDATA_SUCCESS:
            return { myData: action.payload };
        case myDataConstants.GET_MYDATA_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
};

export const loginReducer = (state = { authuser: [] }, action) => {
    switch (action.type) {
        case loginConstants.GET_LOGIN_SUCCESS:
            return { authuser: action.payload };
        case loginConstants.GET_LOGIN_FAILURE:
            return { error: action.payload };
        case clearAuthMessage.CLEAR_AUTH_MESSAGE:
            return { authuser: [], error: "" }
        default:
            return state;
    }
};

export const registerReducer = (state = { signup: [] }, action) => {
    switch (action.type) {
        case signupConstants.GET_SIGNUP_SUCCESS:
            return { signup: action.payload };
        case signupConstants.GET_SIGNUP_FAILURE:
            return { error: action.payload };
        case clearAuthMessage.CLEAR_AUTH_MESSAGE:
            return { signup: [], error: "" }
        default:
            return state;
    }
};

export const forgotPasswordReducer = (state = { forgotPassword: [] }, action) => {
    switch (action.type) {
        case forgotPasswordConstants.GET_FORGOTPASSWORD_SUCCESS:
            return { forgotPassword : action.payload };
        case forgotPasswordConstants.GET_FORGOTPASSWORD_FAILURE:
            return { error: action.payload };
        case clearAuthMessage.CLEAR_AUTH_MESSAGE:
            return { forgotPassword : [], error: "" }
        default:
            return state;
    }
};

export const resetPasswordReducer = (state = { resetPassword: [] }, action) => {
    switch (action.type) {
        case resetPasswordConstants.GET_RESETPASSWORD_SUCCESS:
            return { resetPassword: action.payload };
        case resetPasswordConstants.GET_RESETPASSWORD_FAILURE:
            return { error: action.payload };
        case clearAuthMessage.CLEAR_AUTH_MESSAGE:
            return { resetPassword: [], error: "" }
        default:
            return state;
    }
};

export const changePasswordReducer = (state = { changePassword: [] }, action) => {
    switch (action.type) {
        case changePasswordConstants.GET_CHANGEPASSWORD_SUCCESS:
            return { changePassword: action.payload };
        case changePasswordConstants.GET_CHANGEPASSWORD_FAILURE:
            return { error: action.payload };
        case clearAuthMessage.CLEAR_AUTH_MESSAGE:
            return { changePassword: [], error: "" }
        default:
            return state;
    }
};

export const emailVerificationReducer = (state = { verification: [] }, action) => {
    switch (action.type) {
        case emailVerificationConstants.GET_EMAILVERIFICATION_SUCCESS:
            return { verification: action.payload };
        case emailVerificationConstants.GET_EMAILVERIFICATION_FAILURE:
            return { error: action.payload };
        case clearAuthMessage.CLEAR_AUTH_MESSAGE:
            return { verification: [], error: "" }
        default:
            return state;
    }
};

export const resendEmailVerificationReducer = (state = { resendVerification: [] }, action) => {
    switch (action.type) {
        case resendEmailVerificationConstants.GET_RESENDEMAILVERIFICATION_SUCCESS:
            return { resendVerification: action.payload };
        case resendEmailVerificationConstants.GET_RESENDEMAILVERIFICATION_FAILURE:
            return { error: action.payload };
        case clearAuthMessage.CLEAR_AUTH_MESSAGE:
            return { resendVerification: [], error: "" }
        default:
            return state;
    }
};